import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from "../../store/store";
import { Alert, Backdrop, Box, Button, CircularProgress, Container, Dialog, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { IValidValue } from "../../Api/types";
import { getValidValues } from "../../Api/ValidValues";
import DecimalInput from "../DecimalInput";
import { useNavigate } from "react-router-dom";
import { updateMeasurementT2DetailThunk, setIsFulfilled } from "../../Slices/T2/measurementT2Slice";
import BackdropComponent from "../BackdropComponent";
import ErrorBackdropComponent from "../ErrorBackdropComponent";


interface IProps {
    isDialogOpen: boolean;
    setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function MeasurementT2({isDialogOpen, setIsDialogOpen}: IProps) {
    const { measurementInfo, isLoading, isRejected, isFulfilled, empId, contractType} = useSelector((state: RootState) => ({
        measurementInfo: state.measurementT2.detailInfo,
        isLoading: state.measurementT2.isLoading,
        isRejected: state.measurementT2.isRejected,
        isFulfilled: state.measurementT2.isFulfilled,
        empId: state.user.empId,
        contractType: state.user.contracttype
    }))
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const dispatchThunk = useDispatch<AppDispatch>();
    const dispatch = useDispatch();
    const [bottomMarginContainer, setBottomMarginContainer] = useState<string>();
    const fixedContainerRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>(null);

    //#region  Variables 
    const modelocgpCn = 'MODELOCGP';
    //#endregion

    const [validValues, setValidValues] = useState<IValidValue[]>([]);

    const [modeloCgpValue, setModeloCgpValue] = useState<string | null>(measurementInfo?.modelocgp ?? null);
    const handleModeloCgpSelection = (event: SelectChangeEvent) => {
        const value = event.target.value;
        if(value != null) {
            setModeloCgpValue(value);
        } else {
            setError("Seleccioneu un valor");
        }
    }
    const [iCgpValue, setICgpValue] = useState<number | null>(measurementInfo?.icgp ?? null);
    const [lectura1Value, setLectura1Value] = useState<number | null>(measurementInfo?.lecturA1 ?? null);
    const handleLectura1Change = (value: number) => {
        setLectura1Value(value);
    }
    const [lectura2Value, setLectura2Value] = useState<number | null>(measurementInfo?.lecturA2 ?? null);
    const handleLectura2Change = (value: number) => {
        setLectura2Value(value);
    }
    const [contadorValue, setContadorValue] = useState<string | null>(measurementInfo?.contador ?? null);

    useEffect(() => {
        const tableName = 'measurement_t2';
        getValidValues(tableName)
            .then((values) => {
                setValidValues(values);
            })
            .catch((error) => {
                console.error(error);
            });            
    }, []);

    useEffect(() => {
        setModeloCgpValue(measurementInfo?.modelocgp ?? null);
        setICgpValue(measurementInfo?.icgp ?? null);
        setLectura1Value(measurementInfo?.lecturA1 ?? null);
        setLectura2Value(measurementInfo?.lecturA2 ?? null);
        setContadorValue(measurementInfo?.contador ?? null);
        if (!measurementInfo?.idCM) {
            setIsDialogOpen(false);
        }
    }, [measurementInfo]);

    useEffect(() => {
        if (isLoading) {
            setIsOpen(true);
        }
        if (isFulfilled) {
            setIsOpen(true);
            setTimeout(() => {
                dispatch(setIsFulfilled(false));
                setIsOpen(false);
                setIsDialogOpen(false);                
            }, 3000);
        }
        if (isRejected) {
            setIsOpen(true);
            setTimeout(() => {
                setIsOpen(false);
            }, 3000);
        }
        if (error) {
            setIsOpen(true);
            setTimeout(() => {
                setIsOpen(false);
                setError(null);
            }, 3000);
        }
    }, [dispatch, isFulfilled, isRejected, error])

    useEffect(() => {
        if(fixedContainerRef.current)  { 
            const fixedContainerHeight = fixedContainerRef.current.offsetHeight;
            setBottomMarginContainer(`${fixedContainerHeight + 60}px`); 
        }        
    }, [fixedContainerRef.current]);

    const handleSaveClick = async () => {
        if (measurementInfo?.idCM) {
            const updatedMeasurement = {
                idCM: measurementInfo.idCM,
                modelocgp: modeloCgpValue ?? null,
                icgp: iCgpValue ?? null,
                lecturA1: lectura1Value ?? null,
                lecturA2: lectura2Value ?? null,
                contador: contadorValue ?? null,
                isInspected: 'Y',
                empId: empId
            }
            if (updatedMeasurement && contractType) {
                await dispatchThunk(updateMeasurementT2DetailThunk({ updatedMeasurement, contractType }));
            }
        }
    }

    const handleCancelBtnClick = () => {
        setIsDialogOpen(false);

    }
    
    return(
        <Dialog open={isDialogOpen} fullScreen sx={{ height: '100vh' }}>
            <Container sx={{ marginTop: 'calc(1vw + 15px)', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', gap: 'calc(1vw + 6px)', marginBottom: bottomMarginContainer }}>
                <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, my: 2 }} noValidate autoComplete="off">
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Typography variant='subtitle1' fontWeight={'medium'}>RID</Typography>
                        <TextField id="suport-id" variant="outlined" value={measurementInfo?.idCM} contentEditable={false} sx={{ backgroundColor: 'white', marginLeft: '0 !important' }} />
                    </Box>
                    <Box sx={{ my: 1 }}>
                        <Typography variant='subtitle1' sx={{ my: 1 }} fontWeight={'medium'} >Model CGP</Typography>
                        <Select
                            labelId="cm-type-select-label"
                            id="demo-simple-select"
                            value={modeloCgpValue?.toString() || ''}
                            label="cm-type"
                            onChange={handleModeloCgpSelection}
                            sx={{
                                width: '100%',
                                display: 'flex',
                                backgroundColor: 'white',
                            }}
                        >
                            {validValues
                                .filter(value => value.columnName === modelocgpCn)
                                .map((value, index) => (
                                    <MenuItem key={index} value={value.key}>{`${value.key} - ${value.value}`}</MenuItem>
                                ))}
                        </Select>
                    </Box>
                    <Box>
                        <Typography variant='subtitle1' fontWeight={'medium'} >Intensitat fusibles escomesa</Typography>
                        <TextField id='icgp-id' type='number' value={iCgpValue} onChange={(e) => setICgpValue(parseInt(e.target.value))} sx={{ backgroundColor: 'white', marginLeft: '0 !important' }} InputProps={{ inputProps: { min: 0 } }} />
                    </Box>
                    <DecimalInput labelId='lectura1' labelText='Comptador lectura KWh' initialValue={lectura1Value} onChange={handleLectura1Change} />
                    <DecimalInput labelId='lectura2' labelText='Comptador lectura Kvar' initialValue={lectura2Value} onChange={handleLectura2Change} />
                    <Box>
                        <Typography variant='subtitle1' fontWeight={'medium'} >Nº Sèrie Comptador</Typography>
                        <TextField id='contador-id' type='text' value={contadorValue} onChange={(e) => setContadorValue(e.target.value)} sx={{ backgroundColor: 'white', marginLeft: '0 !important' }} InputProps={{ inputProps: { min: 0 } }} />
                    </Box>                    
                </Box>
                {error && (
                    <ErrorBackdropComponent error={error} isOpen={isOpen} />
                )}
                {(isLoading || isFulfilled || isRejected) && (
                    <BackdropComponent isLoading={isLoading} isFulfilled={isFulfilled} isRejected={isRejected} isOpen={isOpen} />
                )}                 
            </Container>
            <Box
                ref={fixedContainerRef}
                sx={{
                    width: '100%',
                    height: '7vh',
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    backgroundColor: 'white',
                    zIndex: 9999,
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px -2px 15px',
                    marginTop: 'calc(2vw + 10px)',
                }}>
                <Button onClick={handleCancelBtnClick} sx={{ fontSize: '1.2rem', color: '#9e9e9e', }} >Cancel·lar</Button>

                <Button onClick={handleSaveClick} sx={{ fontSize: '1.2rem', color: 'palette.primary.main', }} >Guardar</Button>
            </Box>
        </Dialog>
    )
}