import { useEffect } from 'react';
import { Drawer, Button, Container, Box, Typography } from '@mui/material';
import { fetchQCDetail } from '../../Api/Map';
import { fetchQCT2Detail } from '../../Api/T2/QcT2';
import {IQCCoordinates, IQcT1PlusDetail, IQcT2Detail} from '../../Api/types';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import CheckIcon from '@mui/icons-material/Check';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useDispatch, useSelector } from 'react-redux';
import { selectQcDetailInfo, setQcDetailInfo } from '../../Slices/T1Plus/qcT1PlusSlice';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { RootState } from '../../store/store';
import { qcDetailInfo, setQcT2DetailInfo } from '../../Slices/T2/qcT2Slice';
import { T1plus, T2 } from '../../variables';
import QcT1PlusDetailComponent from './QcT1PlusDetailComponent';
import QcT2PlusDetailComponent from './QcT2DetailComponent';


interface DetailDrawerProps {
    qcDetail: IQCCoordinates;
    open: boolean;
    onClose: () => void;
}


export default function DetailQCDrawer({ qcDetail, open, onClose }: DetailDrawerProps) {
    const dispatch = useDispatch();
    const {qcT1DetailInfo, contractType, qcT2DetailInfo} = useSelector((state: RootState) => ({
      qcT1DetailInfo: state.qc.detailInfo,
      contractType: state.user.contracttype,
      qcT2DetailInfo: state.qcT2.detailInfo
    }));
    const navigate = useNavigate();

    const openInGoogleMaps = () => {
      let url = `https://www.google.com/maps/dir/?api=1&destination=${qcDetail.latitude},${qcDetail.longitude}`;
      window.open(url, '_blank');
    };

    const toggleDrawer = () => {
        onClose(); 
      };

    useEffect(() => {
      if(contractType) {
        if(contractType === T2) {
          fetchQCDetail<IQcT2Detail>(qcDetail.id, contractType)
            .then((data) => dispatch(setQcT2DetailInfo(data)));
        } else if(contractType === T1plus) {
          fetchQCDetail<IQcT1PlusDetail>(qcDetail.id, contractType)
            .then((data) => {
            dispatch(setQcDetailInfo(data));})
        }   
      }        
    }, [])

  

    const openQcForm = () => {
        switch(contractType){
          case T2:
            navigate(`/qcT2/${qcT2DetailInfo?.rid}`);
            break;
          case T1plus: 
            navigate(`/qcT1plus/${qcT1DetailInfo?.rid}`);
            break;
        }       
    }

    let detailComponent = contractType && (
      (contractType === T1plus && qcT1DetailInfo) ? (
        <QcT1PlusDetailComponent qcDetailInfo={qcT1DetailInfo} />
      ) : (
        (contractType === T2 && qcT2DetailInfo) ? (
          <QcT2PlusDetailComponent qcDetailInfo={qcT2DetailInfo} />
        ) : <p>Sense informació</p>
      )
    );

    

  return (
    <div>
      <Drawer anchor="bottom"
        open={open}
        onClose={toggleDrawer}
        PaperProps={{
          sx: {
            borderTopRightRadius: '10px',
            borderTopLeftRadius: '10px',
          },
        }}>
        <Container
          sx={{
            height: '40vh',
            paddingX: 2,
            paddingY: 1,
          }}
        >
          <Box sx={{ height: '10%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ gap: '20px' }}>
              <Button onClick={openQcForm}><EditIcon sx={{ backgroundColor: 'palette.primary.light' }} /></Button>
              <Button onClick={openInGoogleMaps}><DirectionsCarIcon sx={{ backgroundColor: 'palette.primary.light' }} /></Button>
            </Box>
            <Button onClick={toggleDrawer}><CancelIcon /></Button>
          </Box>
          {detailComponent}
        </Container>
      </Drawer>
    </div>
  )
}