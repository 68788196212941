import { baseUrl } from "../../variables";
import { IQcT1PlusDetail } from "../types";



export const updateQcDetail = async (formData: IQcT1PlusDetail, contractType: string): Promise<IQcT1PlusDetail> => {
    try {
             
      const response = await fetch(baseUrl + `/${contractType}/Qc/${formData.rid}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      });
  
      if (!response.ok) {
        throw new Error('Hi ha hagut un error');
      }
  
      const updatedQcDetail: IQcT1PlusDetail = await response.json();
      return updatedQcDetail;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

