import React, { useState, useRef, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import IconButton from '@mui/material/IconButton';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { Button } from '@mui/material';
import { deleteImage } from '../../Api/Images';

export interface IPictureT1Plus  {
  id?: number; 
  base64: string; 
  isNew?: boolean; 
  deleted?: boolean; 
  mapId: string;
}

interface IProps {
  pictures: IPictureT1Plus[];
  setPictures: React.Dispatch<React.SetStateAction<IPictureT1Plus[]>>;
  contractType: string | null;
}

//setPictures: React.Dispatch<React.SetStateAction<{ id?: number; base64: string; isNew?: boolean; deleted?: boolean; mapId: string; }[]>>;


export default function CameraComponent(props: IProps) {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleTakePicture = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];  
    if (file) {
      const base64Img = await convertToBase64(file);                        
      props.setPictures((prevPictures) => [...prevPictures, { mapId: uuid(), base64: base64Img as string, isNew: true }]);
    }
  };    

  const convertToBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleCameraClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDeletePicture = (id: string) => {
    props.setPictures((prevPictures) => {
      return prevPictures.map((picture) => {
        if (picture.mapId === id) {
          if (picture.isNew) {
            return null;
          } else if(picture.id && props.contractType) {
            deleteImage(picture.id, props.contractType);
            return null;
          }
        }
        return picture;
      }).filter((picture) => picture !== null) as IProps['pictures'];
    });
  };
  return (
    <>
    <input
        accept="image/*"
        id="icon-button-file"
        type="file"
        capture="environment"
        onChange={handleTakePicture}
        ref={fileInputRef}
        style={{ display: 'none' }}
      />
      <Button sx={{backgroundColor:'primary.main', color:'white', marginY:2}} onClick={handleCameraClick}>
        Fer fotografia <CameraAltIcon sx={{ marginLeft: "calc(1vw + 1px)"}} />
      </Button>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {props.pictures
        .filter((picture) => !picture.deleted)
        .map((picture) => (
          <div key={picture.mapId} style={{ position: 'relative' }}>
            <img
              src={picture.base64}
              alt={`Picture ${picture.id}`}
              style={{ width: '150px', height: '150px', objectFit: 'cover', margin: '5px' }}
            />
            <IconButton
              onClick={() => handleDeletePicture(picture.mapId)}
              style={{ position: 'absolute', top: '5px', right: '5px', zIndex: 1, color: 'white' }}
            >
              <RemoveCircleIcon fontSize="large" sx={{ color: '#1565c0' }} />
            </IconButton>
          </div>
        ))}
      </div>
      
    </>
  );
}
