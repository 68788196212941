import { Box, Typography } from "@mui/material"
import { ISuportT2Detail } from "../../Api/types"
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

type IProps = {
    detailInfo: ISuportT2Detail
}

export default function SuportT2DetailComponent({detailInfo} : IProps) {
    return(
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', marginX: 2, marginY: 1, '@media (min-width: 600px)': { gridTemplateColumns: '1fr 1fr', gap: '10px', } }}>
            <Box>
                <Box sx={{ marginY: '10px' }}>
                    <Typography fontWeight='fontWeightMedium' variant='subtitle1'>ID</Typography>
                    <Typography variant='body2'>{detailInfo?.rid}</Typography>
                </Box>
                <Box sx={{ marginY: '10px' }}>
                    <Typography fontWeight='fontWeightMedium' variant='subtitle1'>CM</Typography>
                    <Typography variant='body2'>{detailInfo?.cm}</Typography>
                </Box>
                <Box sx={{ marginY: '10px' }}>
                    <Typography fontWeight='fontWeightMedium' variant='subtitle1'>Línia CM</Typography>
                    <Typography variant='body2'>{detailInfo?.liniaCM}</Typography>
                </Box>
                <Box sx={{ marginY: '10px' }}>
                    <Typography fontWeight='fontWeightMedium' variant='subtitle1'>Data verificació</Typography>
                    <Typography variant='body2'>{detailInfo?.fverifi ? new Date(detailInfo?.fverifi).toLocaleDateString('es-ES') : 'Sense data'}</Typography>
                </Box>
                <Box sx={{ marginY: '10px' }}>
                    {detailInfo?.isInspected === 'Y' ? (
                        <Typography fontWeight='fontWeightMedium' sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            Revisat <CheckIcon sx={{ marginLeft: '10px', color: '#4caf50' }} />
                        </Typography>
                    ) : (
                        <Typography fontWeight='fontWeightMedium' sx={{ display: 'flex', alignItems: 'flex-end', color: 'palette.warning.light' }}>
                            No revisat <CloseIcon sx={{ color: '#d32f2f' }} />
                        </Typography>
                    )}
                </Box>
                <Box sx={{ marginY: '10px' }}>
                    <Typography fontWeight='fontWeightMedium' variant='subtitle1'>Estat suport</Typography>
                    <Typography variant='body2'>{detailInfo?.codestgen}</Typography>
                </Box>
            </Box>
            {/* Right Column */}
            <Box>
              <Box sx={{ marginY: '10px' }}>
                <Typography fontWeight='fontWeightMedium' variant='subtitle1'>Estat portella</Typography>
                <Typography variant='body2'>{detailInfo?.portellaStatus}</Typography>
              </Box>
              <Box sx={{ marginY: '10px' }}>
                <Typography fontWeight='fontWeightMedium' variant='subtitle1'>Presa terra</Typography>
                <Typography variant='body2'>{detailInfo?.codtomatie}</Typography>
              </Box>
              <Box sx={{ marginY: '10px' }}>
                <Typography fontWeight='fontWeightMedium' variant='subtitle1'>Estat unió cable terra</Typography>
                <Typography variant='body2'>{detailInfo?.estadotierra}</Typography>
              </Box>
              <Box sx={{ marginY: '10px' }}>
                <Typography fontWeight='fontWeightMedium' variant='subtitle1'>Mesura de terra</Typography>
                <Typography variant='body2'>{detailInfo?.medidatierra?.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
              </Box>              
            </Box>
        </Box>
    )
}