import { baseUrl } from "../../variables";
import { ICircuitT2Detail } from "../types";


export const fetchCircuitT2Detail = async (cmId: number, contractType: string): Promise<ICircuitT2Detail[]> => {
  try {    
    const response = await fetch(baseUrl + `/${contractType}/CircuitsT2?cmId=${cmId}`);
    if (!response.ok) {
      throw new Error("Error");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}

export const updateCircuitDetail = async (formData: ICircuitT2Detail, contractType: string): Promise<void> => {
  try {   
    const response = await fetch(baseUrl + `/${contractType}/CircuitsT2/${formData.rid}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData)
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Insert failed with status ${response.status}: ${errorText}`);
    }
  } catch (error) {
    throw error;
  }
}


export const insertNewCircuit = async (formData: ICircuitT2Detail, contractType: string): Promise<void> => {
  try {
    
    const response = await fetch(baseUrl + `/${contractType}/CircuitsT2`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    });
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Insert failed with status ${response.status}: ${errorText}`);
    }
  } catch (error) {
    throw error;
  }
}
