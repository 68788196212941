/** @jsxImportSource @emotion/react */
import { Dialog, Box, Typography, TextField, Button, Container, CircularProgress, Backdrop, Alert, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { ICircuitT2Detail, IValidValue } from "../../Api/types";
import { useState, useEffect, useRef } from "react";
import { AppDispatch } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import type { RootState } from '../../store/store';
import { setIsFulfilled, insertNewCircuitThunk } from "../../Slices/T2/circuitT2Slice";
import { useNavigate } from 'react-router-dom';
import { toggleBtnsStyle } from '../../Styles/styling-variables';
import DecimalInput from "../DecimalInput";
import ErrorBackdropComponent from "../ErrorBackdropComponent";
import BackdropComponent from "../BackdropComponent";
import { getValidValues } from "../../Api/ValidValues";

interface IProps {
    cenmando: number;   
    isDialogOpen: boolean;
    setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function NewCircuitT2 ({cenmando, isDialogOpen, setIsDialogOpen,}: IProps) {  
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const dispatchThunk = useDispatch<AppDispatch>();
    const dispatch = useDispatch();
    const [bottomMarginContainer, setBottomMarginContainer] = useState<string>();
    const fixedContainerRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const [validValues, setValidValues] = useState<IValidValue[]>([]);
    const { isLoading, isRejected, isFulfilled, empId, contractType } = useSelector((state: RootState) => ({
        isLoading: state.circuitT2.isLoading,
        isRejected: state.circuitT2.isRejected,
        isFulfilled: state.circuitT2.isFulfilled,
        empId: state.user.empId,
        contractType: state.user.contracttype
    }));

    //#region variables
    const sormonCN = "SORMON";
    const xciraferegCN = "XCIRAFEREG";
    //#endregion

    /********************* FORM VARIABLES *********************/
    const [rid, setRid] = useState<number | null>(null);
    const [circuitName, setCircuitName] = useState<string | null>(null);
    const [descriptionValue, setDescriptionValue] = useState<string | null>(null);

    const [seccionValue, setSeccionValue] = useState<number | null>(null);
    const handleSeccionChange = (value: number) => {
        setSeccionValue(value);
    }
    const [nContactorValue, setNContactorValue] = useState<number | null>(null);
    const handleNContactorChange = (value: number) => {
        setNContactorValue(value);
    }
    const [intContactorValue, setIntContactorValue] = useState<number | null>(null);
    const handleIntContactorChange = (value: number) => {
        setIntContactorValue(value);
    }
    const [intRValue, setIntRValue] = useState<number | null>(null);
    const handleIntRChange = (value: number) => {
        setIntRValue(value);
    }
    const [intSValue, setIntSValue] = useState<number | null>(null);
    const handleIntSChange = (value: number) => {
        setIntSValue(value);
    }
    const [intTValue, setIntTValue] = useState<number | null>(null);
    const handleIntTChange = (value: number) => {
        setIntTValue(value);
    }
    const [intNValue, setIntNValue] = useState<number | null>(null);
    const handleIntNChange = (value: number) => {
        setIntNValue(value);
    }
    const [intDiferencialValue, setIntDiferencialValue] = useState<number | null>(null);
    const handleIntDiferencialChange = (value: number) => {
        setIntDiferencialValue(value);
    }
    const [senDiferencialValue, setSenDiferencialValue] = useState<number | null>(null);
    const handleSenDiferencialChange = (value: number) => {
        setSenDiferencialValue(value);
    }
    const [intMagnetoValue, setIntMagnetoValue] = useState<number | null>(null);
    const handleIntMagnetoChange = (value: number) => {
        setIntMagnetoValue(value);
    }
    const [tresDifValue, setTresDifValue] = useState<number | null>(null);
    const handleTresDifChange = (value: number) => {
        setTresDifValue(value);
    }
    const [tsenDifValue, setTsenDifValue] = useState<number | null>(null);
    const handleTsenDifChange = (value: number) => {
        setTsenDifValue(value);
    }
    const [aislamientoValue, setAislamientoValue] = useState<number | null>(null);
    const handleAislamientoChange = (value: number) => {
        setAislamientoValue(value);
    }

    const [isActive, setIsActive] = useState<boolean | null>(null);
    const handleIsActive = (e: React.ChangeEvent<any>, value: boolean | null) => {
        if(value !== null) {
            setIsActive(value);
        }        
    }

    const [sormonValue, setSormonValue] = useState<number | null>(null);
    const [sormonDescription, setSormonDescription] = useState<string>();
    const handleSormonChange = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value !== null) {
            setSormonValue(parseInt(value));
            const description = getDescriptionByKey(value.toString(), sormonCN);
            setSormonDescription(description);
        }
    }
    const [xciraferegValue, setXciraferegValue] = useState<number | null>(null);
    const [xciraferegDescription, setXciraferegDescription] = useState<string>();
    const handleXciraferegChange = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value !== null) {
            setXciraferegValue(parseInt(value));
            const description = getDescriptionByKey(value.toString(), xciraferegCN);
            setXciraferegDescription(description);
        }
    }

    const [isInspected, setIsInspected] = useState<string>('N');
    const handleIsInspected = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null || value != undefined) {
            setIsInspected(value);
        } else {
            setError("Seleccioneu un valor");
        }
    }

    const handleSaveClick = async () => {
        if(rid !==null && cenmando !== -1 && circuitName && isActive !== null && sormonValue !== null && xciraferegValue !== null && isInspected ==='Y') {
              const formData = {
                  rid: rid,
                  cenmando: cenmando,
                  circuitName: circuitName,
                  descripcion: descriptionValue ?? null,
                  seccion: seccionValue ?? null,
                  ncontactor: nContactorValue ?? null,
                  intcontactor: intContactorValue ?? null,
                  intr: intRValue ?? null,
                  ints: intSValue ?? null,
                  intt: intTValue ?? null,
                  intn: intNValue ?? null,
                  intdiferencial: intDiferencialValue ?? null,
                  sendiferencial: senDiferencialValue ?? null,
                  intmagneto: intMagnetoValue ?? null,
                  tresdif: tresDifValue ?? null,
                  tsendif: tsenDifValue ?? null,
                  aislamiento: aislamientoValue ?? null,
                  circuitoActivo: isActive,
                  sormon: sormonValue,
                  xcirafereg: xciraferegValue,
                  isInspected: isInspected,
                  empId: empId,
              }
              if(formData && contractType) {
                  await dispatchThunk(insertNewCircuitThunk({newCircuit: formData, contractType}));                
              }
        } else if(rid === null) {
            setError("Introduïu l'ID del Circuit");
        }
         else if(isActive === null) {
            setError("Indiqueu si el circuit és actiu o inactiu");
        }
        else if (sormonValue === null) {
            setError("Indiqueu si la sortida monofàsica del circuit és actiu o inactiu");
        }
        else if (xciraferegValue === null) {
            setError("Indiqueu si la sortida afectada per la regulació és actiu o inactiu");
        }
         else if(isInspected === 'N') {
            setError("Indiqueu que s'ha revisat el circuit");
        }
    }
    const handleCancelBtnClick = () => {
        setIsDialogOpen(false);
        setRid(null);
        setCircuitName(null);
        setSeccionValue(null);
        setDescriptionValue(null);
        setNContactorValue(null);
        setIntContactorValue(null);
        setIntRValue(null);
        setIntSValue(null);
        setIntTValue(null);
        setIntNValue(null);
        setIntDiferencialValue(null);
        setSenDiferencialValue(null);
        setIntMagnetoValue(null);
        setTresDifValue(null);
        setTsenDifValue(null);
        setAislamientoValue(null);
        setIsActive(null);
        setSormonValue(null);
        setXciraferegValue(null);
        setIsInspected('N');
    }

    const getDescriptionByKey = (key: string, columnName: string): string => {
        const selectedValue = validValues.find(value => value.key === key && value.columnName.toUpperCase() === columnName.toUpperCase());
        return selectedValue ? selectedValue.value : '';
    }

    useEffect(() => {
        const tableName = 'circuit_t2';
        getValidValues(tableName)
            .then((values) => {
                setValidValues(values);
            })
            .catch((error) => {
                console.error(error);
            });            
    }, []);

    useEffect(() => {
        if(fixedContainerRef.current)  { 
            const fixedContainerHeight = fixedContainerRef.current.offsetHeight;
            setBottomMarginContainer(`${fixedContainerHeight + 60}px`); 
        }        
    }, [fixedContainerRef.current]);

    useEffect(() => {
        if (isLoading) {
            setIsOpen(true);
        }
        if (isFulfilled) {
            setIsOpen(true);
            setTimeout(() => {
                dispatch(setIsFulfilled(false));
                setIsOpen(false);
                setIsDialogOpen(false);
                setRid(null);
                setCircuitName(null);
                setSeccionValue(null);
                setDescriptionValue(null);
                setNContactorValue(null);
                setIntContactorValue(null);
                setIntRValue(null);
                setIntSValue(null);
                setIntTValue(null);
                setIntNValue(null);
                setIntDiferencialValue(null);
                setSenDiferencialValue(null);
                setIntMagnetoValue(null);
                setTresDifValue(null);
                setTsenDifValue(null);
                setAislamientoValue(null);
                setIsActive(null);
                setSormonValue(null);
                setXciraferegValue(null);
                setIsInspected('N');
            }, 3000);
        }
        if (isRejected) {
            setIsOpen(true);
            setTimeout(() => {
                setIsOpen(false);
            }, 3000);
        }
        if(error){
            setIsOpen(true);
            setTimeout(() => {
                setIsOpen(false);
                setError(null);
            }, 3000);
        }
    }, [dispatch, isFulfilled, isRejected, error])


    return(
        <Dialog open={isDialogOpen} fullScreen sx={{height:'100vh'}}>
            <Container sx={{ marginTop: 'calc(1vw + 15px)', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', gap: 'calc(1vw + 6px)', marginBottom: bottomMarginContainer }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'start' }} >
                    <Typography variant='subtitle1' fontWeight={'medium'} >ID Circuit</Typography>
                    <TextField id='rid' type='number' value={rid} onChange={(e) => setRid(parseInt(e.target.value))} sx={{ backgroundColor: 'white', marginLeft: '0 !important' }} inputProps={{}} />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'start' }} >
                    <Typography variant='subtitle1' fontWeight={'medium'} >CENMANDO</Typography>
                    <TextField id='cm' type='number' value={cenmando} contentEditable={false} sx={{ backgroundColor: 'white', marginLeft: '0 !important' }} />
                </Box>
                <Box sx={{display: 'flex', flexDirection:'column', justifyContent: 'space-between', alignItems:'start'}} >
                    <Typography variant='subtitle1' fontWeight={'medium'} >Nom circuit</Typography>
                    <TextField id='circuitName' type='text' value={circuitName} onChange={(e) => setCircuitName(e.target.value)} sx={{ backgroundColor: 'white', marginLeft: '0 !important' }} />
                </Box>
                <Box sx={{display: 'flex', flexDirection:'column', justifyContent: 'space-between', alignItems:'start'}} >
                    <Typography variant='subtitle1' fontWeight={'medium'} >Descripció</Typography>
                    <TextField id='circuitName' type='text' value={descriptionValue} onChange={(e) => setDescriptionValue(e.target.value) } sx={{ backgroundColor: 'white', marginLeft: '0 !important' }} />
                </Box>
                <DecimalInput labelId="seccion" labelText="Secció" initialValue={seccionValue} onChange={handleSeccionChange} />
                <DecimalInput labelId="ncontactor" labelText="Nº contactors" initialValue={nContactorValue} onChange={handleNContactorChange} />
                <DecimalInput labelId="intcontactor" labelText="Contactor int" initialValue={intContactorValue} onChange={handleIntContactorChange} />
                <DecimalInput labelId="intr" labelText="INTR" initialValue={intRValue} onChange={handleIntRChange} />
                <DecimalInput labelId="ints" labelText="INTS" initialValue={intSValue} onChange={handleIntSChange} />
                <DecimalInput labelId="intt" labelText="INTT" initialValue={intTValue} onChange={handleIntTChange} />
                <DecimalInput labelId="intn" labelText="INTN" initialValue={intNValue} onChange={handleIntNChange} />
                <DecimalInput labelId="intdiferencial" labelText="Diferencial int" initialValue={intDiferencialValue} onChange={handleIntDiferencialChange} />
                <DecimalInput labelId="sendiferencial" labelText="Diferencial sen" initialValue={senDiferencialValue} onChange={handleSenDiferencialChange} />
                <DecimalInput labelId="intmagneto" labelText="PIA int" initialValue={intMagnetoValue} onChange={handleIntMagnetoChange} />
                <DecimalInput labelId="tresdif" labelText="Diferencial Mesura t resposta" initialValue={tresDifValue} onChange={handleTresDifChange} />
                <DecimalInput labelId="tsendif" labelText="Diferencial Mesura sen" initialValue={tsenDifValue} onChange={handleTsenDifChange} />
                <DecimalInput labelId="aislamiento" labelText="Aïllament" initialValue={aislamientoValue} onChange={handleAislamientoChange} />
                <Box >
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Circuit actiu?</Typography>
                    
                        <ToggleButtonGroup
                            value={isActive}
                            exclusive
                            onChange={handleIsActive}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        >  <ToggleButton value={true} aria-label='notActive' css={toggleBtnsStyle}>
                                <Typography variant="subtitle1">{'Actiu'}</Typography>
                            </ToggleButton>
                            <ToggleButton value={false} aria-label='active' css={toggleBtnsStyle}>
                                <Typography variant="subtitle1">{'Inactiu'}</Typography>
                            </ToggleButton>
                        </ToggleButtonGroup>                    
                </Box>
                <Box >
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Check sortida monfàsica ({sormonDescription}) </Typography>
                    <ToggleButtonGroup
                        value={sormonValue}
                        exclusive
                        onChange={handleSormonChange}
                        aria-label="status"
                        size='large'
                        sx={{
                            width: '100%',
                            display: 'flex',
                        }}
                    >  {validValues
                        .filter(value => value.columnName === sormonCN)
                        .map((value, index) => (
                            <ToggleButton key={index} value={parseInt(value.key)} aria-label={value.key} css={toggleBtnsStyle}>
                                <Typography variant="subtitle1">{value.key}</Typography>
                            </ToggleButton>
                        ))
                        }
                    </ToggleButtonGroup>
                </Box>
                <Box >
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Check sortida afectada per regulació ({xciraferegDescription})</Typography>
                    <ToggleButtonGroup
                        value={xciraferegValue}
                        exclusive
                        onChange={handleXciraferegChange}
                        aria-label="status"
                        size='large'
                        sx={{
                            width: '100%',
                            display: 'flex',
                        }}
                    >  {validValues
                        .filter(value => value.columnName === xciraferegCN)
                        .map((value, index) => (
                            <ToggleButton key={index} value={parseInt(value.key)} aria-label={value.key} css={toggleBtnsStyle}>
                                <Typography variant="subtitle1">{value.key}</Typography>
                            </ToggleButton>
                        ))
                        }
                    </ToggleButtonGroup>
                </Box>
                <Box sx={{mb: 2}}>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Revisat</Typography>
                    
                        <ToggleButtonGroup
                            value={isInspected}
                            exclusive
                            onChange={handleIsInspected}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        >  <ToggleButton value='N' aria-label='notInspected' css={toggleBtnsStyle}>
                                <Typography variant="subtitle1">{'No revisat'}</Typography>
                            </ToggleButton>
                            <ToggleButton value='Y' aria-label='inspected' css={toggleBtnsStyle}>
                                <Typography variant="subtitle1">{'Revisat'}</Typography>
                            </ToggleButton>
                        </ToggleButtonGroup>                    
                </Box>
                {error && (
                    <ErrorBackdropComponent error={error} isOpen={isOpen} />
                )}
                {(isLoading || isFulfilled || isRejected) && (
                    <BackdropComponent isLoading={isLoading} isFulfilled={isFulfilled} isRejected={isRejected} isOpen={isOpen} />
                )}                
            </Container>
            <Box
                ref={fixedContainerRef}
                sx={{
                    width: '100%',
                    height: '7vh',
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    backgroundColor: 'white',
                    zIndex: 9999,
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px -2px 15px',
                    marginTop: 'calc(2vw + 10px)',
                }}>
                    <Button onClick={handleCancelBtnClick} sx={{fontSize: '1.2rem', color: '#9e9e9e', }} >Cancel·lar</Button>
                    
                    <Button onClick={handleSaveClick} sx={{fontSize: '1.2rem', color:'palette.primary.main', }} >Guardar</Button>
                </Box>
        </Dialog>
    )

}