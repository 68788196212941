/** @jsxImportSource @emotion/react */
import { Box, ToggleButton, ToggleButtonGroup, Typography, Container, Button, TextField, Alert, Backdrop, CircularProgress, Autocomplete } from '@mui/material';
import { useState, useEffect } from 'react';
import { toggleBtnsStyle } from '../../Styles/styling-variables';
import { getValidValues } from '../../Api/ValidValues';
import { IValidValue, IQcId } from '../../Api/types';
import type { RootState } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { setIsFulfilled, updateSupportDetailThunk } from '../../Slices/T1Plus/suportT1PlusSlice';
import { picturesT1PlusThunk } from '../../Slices/picturesThunk';
import { AppDispatch } from '../../store/store';
import { useNavigate } from 'react-router-dom';
import CameraComponent from '../Camera/CameraT1Plus';
import { getImages } from '../../Api/Images';
import { v4 as uuid } from 'uuid';
import { IImageT1PlusResult } from '../../Api/types';
import ErrorBackdropComponent from '../ErrorBackdropComponent';
import BackdropComponent from '../BackdropComponent';
import DateInputComponent from '../DateInputComponent';


export default function SuportsT1plus() {
    const { detailInfo, isLoading, isRejected, isFulfilled, empId, contractType } = useSelector((state: RootState) => ({
        detailInfo: state.suport.detailInfo,
        isLoading: state.suport.isLoading,
        isRejected: state.suport.isRejected,
        isFulfilled: state.suport.isFulfilled,
        empId: state.user.empId,
        contractType: state.user.contracttype
    }));
    const visual = "visual";
    const measurement = "measurement"
    const [isVisualInspection, setIsVisualInspection] = useState<string>(detailInfo?.codtomatie ? measurement: visual);
    const handleIsVisualInspection = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null || value != undefined) {
            setIsVisualInspection(value);
        }
    }
    const [pictures, setPictures] = useState<{ id?: number; base64: string; isNew?: boolean; deleted?: boolean; mapId: string; }[]>([]);
    //const [qcIdList, setQcIdList] = useState<number[]>([]);
    //const [cm, setCm] = useState<number | null>(detailInfo?.cm ?? null);
    const getDescriptionByKey = (key: string): string => {
        const selectedValue = validValues.find(value => value.key === key);
        return selectedValue ? selectedValue.value : '';
    }   

    const getDescriptionByKeyAnByColumName = (key: string, columnName: string): string => {
        const selectedValue = validValues.find(value => value.key === key && value.columnName.toUpperCase() === columnName.toUpperCase());
        return selectedValue ? selectedValue.value : '';
    }

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const dispatchThunk = useDispatch<AppDispatch>();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>(null);
    const [validValues, setValidValues] = useState<IValidValue[]>([]);
    const [medidaTierra, setMedidaTierra] = useState<number | null>(detailInfo?.medidatierra ?? null);
    const [comments, setComments] = useState<string>(detailInfo?.comments ?? '');
    const [fverT1, setFverT1] = useState<Date>(detailInfo?.fverT1 ? new Date(detailInfo.fverT1) : new Date());
    const handleFvertT1Change = (e: React.ChangeEvent<HTMLInputElement>) => {
        const dateString = e.target.value;
        const date = new Date(dateString);
        setFverT1(date);
    };
    const [isInspected, setIsInspected] = useState<string>(detailInfo?.isInspected ?? 'N');
    const handleIsInspected = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null || value != undefined) {
            setIsInspected(value);
        } else {
            setError("Seleccioneu un valor");
        }
    }

    const [rosmiman, setRosmiman] = useState<number | null>(detailInfo?.rosmiman ?? null);
    const [rosmimanDesc, setRosmimanDesc] = useState<string>(); 
    const handleRosmimanSelect = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null || value != undefined) {
            setRosmiman(parseInt(value));
            const description = getDescriptionByKey(value.toString());
            setRosmimanDesc(description);
        } else {
            setError("Seleccioneu un valor");
        }
    }
    const [portellaStatus, setPortellaStatus] = useState<string | null>(detailInfo?.portellaStatus ?? null);
    const [portellaStatusDesc, setPortellaStatusDesc] = useState<string>();
    const handlePortellaStatus = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null || value != undefined) {
            setPortellaStatus(value);
            const description = getDescriptionByKey(value);
            setPortellaStatusDesc(description);
        } else {
            setError("Seleccioneu un valor");
        }
    } 

    const [codtomatie, setCodtomatie] = useState<string | null>(detailInfo?.codtomatie ?? null);
    const [codtomatieDesc, setCodtomatieDesc] = useState<string>("");
    const handleCodtomatieSelect = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null || value != undefined) {
            setCodtomatie(value);
            const description = getDescriptionByKey(value);
            setCodtomatieDesc(description);
        }
    }

    const [estadoTierra, setEstadoTierra] = useState<number | null>(detailInfo?.estadotierra ?? null);
    const [estadoTierraDesc, setEstadoTierraDesc] = useState<string>("");
    const handleEstadoTierraSelect = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null || value != undefined) {
            setEstadoTierra(parseInt(value));
            const description = getDescriptionByKey(value.toString());
            setEstadoTierraDesc(description);
        }
    }

    const [canviestatValue, setCanviEstat] = useState<string | null>(detailInfo?.canviestat ?? null);
    const [canviestatDescription, setcanviestatDescription] = useState<string>("");
    const handleCanviEstatSelect = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null) {
            setCanviEstat(value);
            const description = getDescriptionByKey(value);
            setcanviestatDescription(description);
        }
    }

    const fetchImages = async () => {
        try {
            if(detailInfo?.id && contractType) {
                const data = await getImages(detailInfo?.id, contractType) as IImageT1PlusResult[];                
                const image = data.map((img: IImageT1PlusResult) => ({
                    id: img.id,                    
                    base64: `data:image/png;base64,${img.imageDataString}`,
                    isNew: false,
                    deleted: false,
                    mapId: uuid(),
                }))
                setPictures(image);
            }
          
        } catch (error) {
          console.error('Error fetching images:', error);
        }
      };

    useEffect(() => {
        if (!detailInfo?.id) {
            navigate('/')
        }
    }, [detailInfo?.id])

    useEffect(() => {
        const tableName = 'suport_T1plus';
        getValidValues(tableName)
            .then((values) => {
                setValidValues(values);
            })
            .catch((error) => {
                console.error(error);
            });
            fetchImages();            
    }, []);

    useEffect(() => {
        if (detailInfo && validValues) {            
            setRosmimanDesc(getDescriptionByKey(detailInfo.rosmiman?.toString() ?? ''));
            if(detailInfo.codtomatie != null) setCodtomatieDesc(getDescriptionByKey(detailInfo.codtomatie.toString()));
            if(detailInfo.estadotierra != null) setEstadoTierraDesc(getDescriptionByKey(detailInfo.estadotierra.toString()));
            setPortellaStatusDesc(getDescriptionByKey(detailInfo.portellaStatus?.toString() ?? ''));
            setcanviestatDescription(getDescriptionByKey(detailInfo.canviestat?.toString() ?? '' ));
        }
    }, [detailInfo, validValues])

    useEffect(() => {
        if (isLoading) {
            setIsOpen(true);
        }
        if (isFulfilled) {
            setIsOpen(true);
            setTimeout(() => {
                dispatch(setIsFulfilled(false));
                setIsOpen(false);
                navigate('/')
            }, 3000);
        }
        if (isRejected) {
            setIsOpen(true);
            setTimeout(() => {
                setIsOpen(false);
            }, 3000);
        }
    }, [dispatch, isFulfilled, isRejected])

    /* ERROR AND OTHER STATE HANDLING FUNCTIONS */
    const showError = (message: string) => {     
        setError(message);
        setIsOpen(true);
        setTimeout(() => {
            setIsOpen(false);
            setError(null);
        }, 3000);
    }; 

  

    const handleOnClick = async () => {
        let formData;
        if(isVisualInspection === visual) {
            if(detailInfo?.id != null && detailInfo?.cm != null && isInspected ==='Y' && portellaStatus && rosmiman && canviestatValue ) {
                formData = {
                    id: detailInfo.id,
                    cm: detailInfo.cm,
                    rosmiman: rosmiman,
                    portellaStatus: portellaStatus,
                    codtomatie: null,
                    estadotierra: null,
                    medidatierra: null,
                    fverT1: fverT1,
                    canviestat:canviestatValue,                  
                    comments: comments,
                    isInspected: isInspected,
                    empId: empId
                };
            } else if(isInspected ==='N') {
                showError("Indiqueu que s'ha revisat el suport");
                return;
            }
            else {
                showError("Empleneu tots els camps");
                return;
            }
        } else if (isVisualInspection === measurement) {
            if( detailInfo?.id != null && detailInfo?.cm != null && isInspected ==='Y' && portellaStatus && rosmiman 
                && codtomatie !== null && estadoTierra !== null && medidaTierra !== null && canviestatValue !== null) {
                formData = {
                    id: detailInfo.id,
                    cm: detailInfo.cm,
                    rosmiman: rosmiman,
                    portellaStatus: portellaStatus,
                    codtomatie: codtomatie,
                    estadotierra: estadoTierra,
                    medidatierra: medidaTierra,
                    fverT1: fverT1,
                    canviestat:canviestatValue, 
                    comments: comments,
                    isInspected: isInspected,
                    empId: empId
                };
            } else if(isInspected ==='N') {
                showError("Indiqueu que s'ha revisat el suport");
                return;
            }
            else {
                showError("Empleneu tots els camps");
                return;
            }
        }
        try {
            if(detailInfo?.id) {
                const pictureData = pictures.map((picture, index) => {
                    const date = new Date();
                    const day = date.getDate().toString().padStart(2, '0');
                    const month = (date.getMonth() + 1).toString().padStart(2, '0');
                    const year = date.getFullYear();
                    const formattedDate = `${day}${month}${year}`;
                    return {
                        id: picture.id ?? -1,
                        entityId: detailInfo.id,
                        entityType: 'Suport_T1plus',
                        base64: picture.base64,
                        imageName: `${detailInfo.id}_${formattedDate}_${index}`,
                        imageDate: new Date(),
                        isNew: picture.isNew
                    }
                });

                if(contractType) await dispatchThunk(picturesT1PlusThunk(pictureData));
            }

            if(formData && contractType) {
                await dispatchThunk(updateSupportDetailThunk({formData, contractType}));
            }          
          } catch (error) {
            showError('Hi ha hagut un error amb les imatges. Torneu-ho a intentar.');
          }       
    }   

    

    return (
        <Container>
            <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, my: 2 }} noValidate autoComplete="off">
                <Box sx={{display: 'flex', flexDirection:'column', justifyContent: 'space-between'}} >
                    <Typography variant='subtitle1' fontWeight={'medium'}>ID</Typography>
                    <TextField id="suport-id" variant="outlined" value={detailInfo?.id} contentEditable={false} sx={{backgroundColor: 'white', marginLeft:'0 !important'}} />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Typography variant='subtitle1' fontWeight={'medium'}>CM</Typography>
                    <TextField value={detailInfo?.cm} sx={{ backgroundColor: 'white', marginLeft: '0 !important' }} />
                </Box>  
                <DateInputComponent description='Data verificació' date={fverT1} handleDateChange={handleFvertT1Change} />
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Tipus d'inspecció</Typography>                    
                        <ToggleButtonGroup
                            value={isVisualInspection}
                            exclusive
                            onChange={handleIsVisualInspection}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        >  <ToggleButton value={visual} aria-label='notInspected' css={toggleBtnsStyle}>
                                <Typography variant="subtitle1">Visual</Typography>
                            </ToggleButton>
                            <ToggleButton value={measurement} aria-label='inspected' css={toggleBtnsStyle}>
                                <Typography variant="subtitle1">Medició</Typography>
                            </ToggleButton>
                        </ToggleButtonGroup>                    
                </Box>
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Estat suport({rosmimanDesc})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={rosmiman}
                            exclusive
                            onChange={handleRosmimanSelect}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === 'ROSMIMAN')
                            .map(value => (
                                <ToggleButton key={value.key} value={parseInt(value.key)} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Estat portella({portellaStatusDesc})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={portellaStatus}
                            exclusive
                            onChange={handlePortellaStatus}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'grid',
                                gridTemplateColumns: 'repeat(4, 1fr)'
                            }}
                        > {validValues
                            .filter(value => value.columnName === 'ESTAT_PORTELLA')
                            .map(value => (
                                <ToggleButton key={value.key} value={value.key} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >CANVI ESTAT({canviestatDescription})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={canviestatValue}
                            exclusive
                            onChange={handleCanviEstatSelect}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === 'CANVIESTAT')
                            .map(value => (
                                <ToggleButton key={value.key} value={value.key} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>

                <Box sx={{ display: isVisualInspection === measurement ? 'block' : 'none' }}>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Tipus connexió a terra({codtomatieDesc})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={codtomatie}
                            exclusive
                            onChange={handleCodtomatieSelect}
                            aria-label="status"
                            size='large'                            
                            sx={{
                                width: '100%',                                
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === 'CODTOMATIE')
                            .map(value => (
                                <ToggleButton key={value.key} value={value.key} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>
                <Box sx={{ display: isVisualInspection === measurement ? 'block' : 'none' }}>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Estat unió a terra ({estadoTierraDesc})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={estadoTierra}
                            exclusive
                            onChange={handleEstadoTierraSelect}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === 'ESTADOTIERRA')
                            .map(value => (
                                <ToggleButton key={value.key} value={parseInt(value.key)} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>
                <Box sx={{ display: isVisualInspection === measurement ? 'flex' : 'none', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Typography variant='subtitle1' fontWeight={'medium'}>Mesura de terra</Typography>
                    <TextField id='land-measurement-id' type='number' value={medidaTierra} onChange={(e) => setMedidaTierra(parseFloat(e.target.value))} sx={{ backgroundColor: 'white', marginLeft: '0 !important' }} inputProps={{ step: '0.01' }} />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Typography variant='subtitle1' fontWeight={'medium'}>Observacions</Typography>
                    <TextField id='comments-id' type='text' value={comments} onChange={(e) => setComments(e.target.value)} sx={{ backgroundColor: 'white', marginLeft: '0 !important' }} />
                </Box>
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Revisat</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={isInspected}
                            exclusive
                            onChange={handleIsInspected}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        >  <ToggleButton value='N' aria-label='notInspected' css={toggleBtnsStyle}>
                                <Typography variant="subtitle1">{'No revisat'}</Typography>
                            </ToggleButton>
                            <ToggleButton value='Y' aria-label='inspected' css={toggleBtnsStyle}>
                                <Typography variant="subtitle1">{'Revisat'}</Typography>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    )}
                </Box>
                <CameraComponent setPictures={setPictures} pictures = {pictures} contractType={contractType} />
            </Box>
            <Box width={'100%'} sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <Button variant='contained' onClick={handleOnClick} sx={{ marginY: 3, width:'50%', backgroundColor:'secondary.main', paddingY:'15px', fontSize:'1.2rem' }}>Guardar</Button>
            </Box>
            {error && (
                <ErrorBackdropComponent error={error} isOpen={isOpen} />
            )}
            {(isLoading || isFulfilled || isRejected) && (
                <BackdropComponent isLoading={isLoading} isFulfilled={isFulfilled} isRejected={isRejected} isOpen={isOpen} />
            )}
        </Container>
    )
}



