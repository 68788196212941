/** @jsxImportSource @emotion/react */
import { toggleBtnsStyle } from '../../Styles/styling-variables';
import { Box, ToggleButton, ToggleButtonGroup, Typography, Container, TextField, Button, Alert, CircularProgress, Backdrop, TableContainer, Select, MenuItem, InputLabel, SelectChangeEvent, debounce } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../store/store';
import { ICircuitT2Detail, IValidValue, IMeasurementT2Detail, ICmExternalConnectionT2Detail, IImageDataT2, IImageT2Result } from '../../Api/types';
import { getValidValues } from '../../Api/ValidValues';
import { setIsSubmitLoading, setIsSubmitFulfilled, setIsSavedFulfilled, setIsSavedLoading, updateQcT2DetailThunk, saveQcT2DetailThunk } from '../../Slices/T2/qcT2Slice';
import DecimalInput from '../DecimalInput';
import TableComponent, { TableColumn } from '../TableComponent';
import CircuitsT2 from './CircuitsT2';
import NewCircuitT2 from './NewCircuitT2';
import AddBoxIcon from '@mui/icons-material/AddBox';
import {fetchCircuitT2DetailThunk, setSelectedCircuitT2} from '../../Slices/T2/circuitT2Slice'
import { fetchMeasurementT2DetailThunk } from '../../Slices/T2/measurementT2Slice';
import MeasurementT2 from './MeasurementT2';
import { fetchCmExtConnT2DetailThunk, setSelectedCmExternalConnT2 } from '../../Slices/T2/cmExternalConnT2';
import { additionalQcCategory, closedQcCategory, openQcCategory, planningQcCategory, suportCategory, qcExtConnCategory } from '../../variables';
import CmExternalConnectionT2 from './CmExtConnT2';
import ErrorBackdropComponent from '../ErrorBackdropComponent';
import BackdropComponent from '../BackdropComponent';
import DateInputComponent from '../DateInputComponent';
import CameraT2Component, { IPictureT2 } from '../Camera/CameraCmT2';
import { picturesT2Thunk } from '../../Slices/picturesThunk';
import { getImages } from '../../Api/Images';
import { v4 as uuid } from 'uuid';


export default function QcT2() {
    const {detailInfo, isSubmitLoading, isSubmitRejected, isSubmitFulfilled, isSavedLoading, isSavedFulfilled, isSavedRejected, circuitList, measurementInfo, cmExtConnList, empId, contractType } = useSelector((state: RootState) => ({
        detailInfo : state.qcT2.detailInfo,
        isSubmitLoading: state.qcT2.isSubmitLoading,
        isSubmitRejected: state.qcT2.isSubmitRejected,
        isSubmitFulfilled: state.qcT2.isSubmitFulfilled,
        isSavedLoading: state.qcT2.isSavedLoading,
        isSavedFulfilled: state.qcT2.isSavedFulfilled,
        isSavedRejected: state.qcT2.isSavedRejected,
        circuitList: state.circuitT2.circuitDetailList,
        measurementInfo: state.measurementT2.detailInfo,
        cmExtConnList: state.cmExternalConnT2.externalConnDetailList,
        empId: state.user.empId,
        contractType: state.user.contracttype,
    }));
    const dispatchThunk = useDispatch<AppDispatch>();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>(null);
    const [validValues, setValidValues] = useState<IValidValue[]>([]);
    const [isOpen, setIsOpen] = useState<boolean>(false);    
    const [isExistingCircOpen, setIsExistingCirOpen] = useState<boolean>(false);
    const [isNewCircuitDialogOpen, setIsNewCircuitDialogOpen] = useState(false);
    const [isMeasurmentDialogOpen, setIsMeasurementDialogOpen] = useState<boolean>(false);
    const [isExtConnDialogOpen, setIsExtConnDialogOpen] = useState<boolean>(false);
    const [cenmando, setCenmando] = useState<number>(-1);
    const [bottomMarginContainer, setBottomMarginContainer] = useState<string>();
    const fixedContainerRef = useRef<HTMLDivElement>(null);
    const [images, setImages] = useState<IPictureT2[] | null>(null);
    const [openQcPic, setOpenQcPic] = useState<IPictureT2 | null>(null);
    const [closedQcPic, setClosedQcPic] = useState<IPictureT2 | null>(null);
    const [suportPic, setSuportPic] = useState<IPictureT2 | null>(null);
    const [planningPicture, setPlanningPic] = useState<IPictureT2 | null>(null);  
    const [qcExtConnPicture, setQcExtConnPicture] = useState<IPictureT2 | null>(null);
    const [additionalPicture, setAdditionalPic] = useState<IPictureT2 | null>(null);


    const handlePictureChange = (category: string, picture: IPictureT2 | null) => {

        console.log("handle picture change", category, picture)
        switch (category) {
        case openQcCategory:
            setOpenQcPic(picture);
            break;
        case closedQcCategory:
            setClosedQcPic(picture);
            break;
        case suportCategory:
            setSuportPic(picture);
            break;
        case planningQcCategory:
            setPlanningPic(picture);
            break;
        case qcExtConnCategory: 
            setQcExtConnPicture(picture);
            break;
        case additionalQcCategory:
            setAdditionalPic(picture);
            break;
        default:
            break;
        }
    };

    //#region ColumnName variables
    const generic = 'GENERIC';
    const codtipocuaCN = 'CODTIPOCUA';
    const codestcierreCN = 'CODESTCIERRE';
    const codestcuaCN = 'CODESTCUA';
    const codesttapasCN = 'CODESTTAPAS';
    const codesttubosCN = 'CODESTTUBOS';
    const codestextCN = 'CODESTEXT';
    const codtipopavCN = 'CODTIPOPAV';
    const codtiposopCN = 'CODTIPOSOP';
    const codestsopcmCN =	'CODESTSOPCM';
    const codestlineasCN = 'CODESTLINEAS';
    const codestarqCN =	'CODESTARQ';
    const estconarqCN =	'ESTCONARQ';
    const codelemaniobraCN = 'CODELEMANIOBRA';
    const comsalacCN = 'COMSALAC';
    const comcodtipoCN = 'COMCODTIPO';
    const codtipcanalCN = 'CODTIPCANAL';
    const curvaigaCN = 'CURVAIGA';
    const codtomatieCN = 'CODTOMATIE';    
    const fabregCN = 'FABREG';
    const regmodeloCN = 'REGMODELO';
    const regpotenciaCN = 'REGPOTENCIA';
    const etiquetaCN = 'ETIQUETA';
    const protecstCN = 'PROTECST';
    const xtiposaiCN = 'XTIPOSAI';   
    const saiCN = 'SAI';
    //#endregion

    
    const [fverifiValue, setFverifiValue] = useState<Date>(new Date());
    const handleFverifiChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const dateString = e.target.value;
        const data = new Date(dateString);
        setFverifiValue(data);
    }
    
    const [codtipocuaValue, setCodtipcuaValue] = useState<string | null>(detailInfo?.codtipocua ?? null);
    const handleCodtipocuaSelection = (event: SelectChangeEvent) => {
        const value = event.target.value;
        if (value != null) { 
            setCodtipcuaValue(value);
        } else {
            setError("Seleccioneu un valor");
        }
    }
    
    const [codestcuaValue, setCodestcuaValue] = useState<number | null>(detailInfo?.codestcua ?? null);
    const [codestcuaDescription, setCodestcuaDescription] = useState<string>();
    const handleCodestcuaSelection = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null) { 
            setCodestcuaValue(parseInt(value));
            const description = getDescriptionByKey(value.toString(), generic);
            setCodestcuaDescription(description);
        } else {
            setError("Seleccioneu un valor");
        }
    }    
    
    const [codestcierreValue, setCodestcierreValue] = useState<number | null>(detailInfo?.codestcierre ?? null);
    const [codestcierreDescription, setCodestcierreDescription] = useState<string>();
    const handleCodestcierreSelection = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null) { 
            setCodestcierreValue(parseInt(value));
            const description = getDescriptionByKey(value.toString(), generic);
            setCodestcierreDescription(description);
        } else {
            setError("Seleccioneu un valor");
        }
    }

    const [codesttapasValue, setCodesttapasValue] = useState<number | null>(detailInfo?.codesttapas ?? null);
    const [codesttapasDescription, setCodesttapasDescription] = useState<string>();
    const handleCodesttapasSelection = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null) { 
            setCodesttapasValue(parseInt(value));
            const description = getDescriptionByKey(value.toString(), generic);
            setCodesttapasDescription(description);
        } else {
            setError("Seleccioneu un valor");
        }
    }

    const [codesttubosValue, setCodesttubosValue] = useState<number | null>(detailInfo?.codesttubos ?? null);
    const [codesttubosDescription, setCodesttubosDescription] = useState<string>();
    const handleCodesttubosSelection = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null) { 
            setCodesttubosValue(parseInt(value));
            const description = getDescriptionByKey(value.toString(), generic);
            setCodesttubosDescription(description);
        } else {
            setError("Seleccioneu un valor");
        }
    }

     
    const [codestextValue, setCodestextValue] = useState<string | null>(detailInfo?.codestext ?? null);
    const [codestextDescription, setCodestextDescription] = useState<string>();
    const handleCodestextSelection = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null) { 
            setCodestextValue(value);
            const description = getDescriptionByKey(value, codestextCN);
            setCodestextDescription(description);
        } else {
            setError("Seleccioneu un valor");
        }
    }  

    const [codtipopavValue, setCodtipopavValue] = useState<number | null>(detailInfo?.codtipopav ?? null);
    const handleCodtipopavSelection = (event: SelectChangeEvent) => {
        const value = event.target.value;
        if(value!= null) {
            setCodtipopavValue(parseInt(value));
        } else {
            setError("Seleccioneu un valor");
        }
    }     

    const [codtiposopValue, setCodtiposopValue] = useState<string | null>(detailInfo?.codtiposop ?? null);
    const [codtiposopDescription, setCodtiposopDescription] = useState<string | null>();
    const handleCodtiposopSelection = (e: React.ChangeEvent<any>, value: string | null) => {
        if(value!= null) {
            setCodtiposopValue(value);
            const description = getDescriptionByKey(value.toString(), codtiposopCN);
            setCodtiposopDescription(description);
        } else {
            setError("Seleccioneu un valor");
        }
    }

    const [codestsopcmValue, setCodestsopcmValue] = useState<number | null>(detailInfo?.codestsopcm ?? null);
    const [codestsopcmDescription, setCodestsopcmDescription] = useState<string | null>();
    const handleCodestsopcmSelection = (e: React.ChangeEvent<any>, value: string | null) => {
        if(value!= null) {
            setCodestsopcmValue(parseInt(value));
            const description = getDescriptionByKey(value.toString(), generic);
            setCodestsopcmDescription(description);
        } else {
            setError("Seleccioneu un valor");
        }
    }
    
    const [codestlineasValue, setCodestlineasValue] = useState<number | null>(detailInfo?.codestlineas ?? null);
    const [codestlineasDescription, setCodestlineasDescription] = useState<string | null>();
    const handleCodestlineasSelection = (e: React.ChangeEvent<any>, value: string | null) => {
        if(value!= null) {
            setCodestlineasValue(parseInt(value));
            const description = getDescriptionByKey(value.toString(), generic);
            setCodestlineasDescription(description);
        } else {
            setError("Seleccioneu un valor");
        }
    }
    
    const [codestarqValue, setCodestarqValue] = useState<number | null>(detailInfo?.codestarq ?? null);
    const [codestarqDescription, setCodestarqDescription] = useState<string | null>();
    const handleCodestarqSelection = (e: React.ChangeEvent<any>, value: string | null) => {
        if(value!= null) {
            setCodestarqValue(parseInt(value));
            const description = getDescriptionByKey(value.toString(), generic);
            setCodestarqDescription(description);
        } else {
            setError("Seleccioneu un valor");
        }
    }   	

    const [estconarqValue, setEstconarqValue] = useState<number | null>(detailInfo?.estconarq ?? null);
    const [estconarqDescription, setEstconarqDescription] = useState<string | null>();
    const handleEstconarqSelection = (e: React.ChangeEvent<any>, value: string | null) => {
        if(value!= null) {
            setEstconarqValue(parseInt(value));
            const description = getDescriptionByKey(value.toString(), estconarqCN);
            setEstconarqDescription(description);
        } else {
            setError("Seleccioneu un valor");
        }
    }
     
    const [codelemaniobraValue, setCodelemaniobraValue] = useState<string | null>(detailInfo?.codelemaniobra ?? null);
    const handleCodelemaniobraSelection = (event: SelectChangeEvent) => {
        const value = event.target.value;
        if (value != null) { 
            setCodelemaniobraValue(value);
        } else {
            setError("Seleccioneu un valor");
        }
    }  

    const [salidasValue, setSalidasValue] = useState<number | null>(detailInfo?.salidas ?? null);
    const [salidasActivasValue, setSalidasActivasValue] = useState<number | null>(detailInfo?.salidasactivas ?? null);
    const [numContactorValue, setNumContactorValue] = useState<number | null>(detailInfo?.numcontactor ?? null);
    const [numDifValue, setNumDifValue] = useState<number | null>(detailInfo?.numdif ?? null);

    const [codtipcanalValue, setCodtipcanalValue] = useState<string | null>(detailInfo?.codtipcanal ?? null);
    const handleCodtipcanalSelection = (event: SelectChangeEvent) => {
        const value = event.target.value;
        if (value != null) { 
            setCodtipcanalValue(value);            
        } else {
            setError("Seleccioneu un valor");
        }
    }  

    const [igavalorValue, setIgavalorValue] = useState<number | null>(detailInfo?.igavalor ?? null);

    const [curvaigaValue, setCurvaigaValue] = useState<string | null>(detailInfo?.curvaiga ?? null);
    const [curvaigaDescription, setCurvaigaDescription] = useState<string>();
    const handleCurvaigaSelection = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null) { 
            setCurvaigaValue(value);
            const description = getDescriptionByKey(value, curvaigaCN);
            setCurvaigaDescription(description);
        } else {
            setError("Seleccioneu un valor");
        }
    }  

    const [voltaje1Value, setVoltaje1Value] = useState<number | null>(detailInfo?.voltajE1 ?? null);
    const handleVoltaje1Change = (value: number) => {
        setVoltaje1Value(value);
    }

    const [voltaje2Value, setVoltaje2Value] = useState<number | null>(detailInfo?.voltajE2 ?? null);
    const handleVoltaje2Change = (value: number) => {
        setVoltaje2Value(value);
    }

    const [codtomatieValue, setCodtomatieValue] = useState<string | null>(detailInfo?.codtomatie ?? null);
    const [codtomatieDescription, setCodtomatieDescription] = useState<string>();
    const handleCodtomatieSelection = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null) { 
            setCodtomatieValue(value);
            const description = getDescriptionByKey(value, codtomatieCN);
            setCodtomatieDescription(description);
        } else {
            setError("Seleccioneu un valor");
        }
    }  

    const [tomatievalValue, setTomatievalValue] = useState<number | null>(detailInfo?.tomatieval ?? null);
    const handleTomatievalChange = (value: number) => {
        setTomatievalValue(value);
    }
    const [tensioncontactoValue, setTensioncontactoValue] = useState<number | null>(detailInfo?.tensioncontacto ?? null);
    const handleTensioncontactoChange = (value: number) => {
        setTensioncontactoValue(value);
    }  

    const [fabregValue, setFabregValue] = useState<string | null>(detailInfo?.fabreg ?? null);
    const [fabregDescription, setFabregDescription] = useState<string>();
    const handleFabregSelection = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null) { 
            setFabregValue(value);
            const description = getDescriptionByKey(value, fabregCN);
            setFabregDescription(description);
        } else {
            setError("Seleccioneu un valor");
        }
    }  
    const [regmodeloValue, setRegmodeloValue] = useState<string | null>(detailInfo?.regmodelo ?? null);
    const handleRegModeloSelection = (event: SelectChangeEvent) => {
        const value = event.target.value;
        if (value != null) { 
            setRegmodeloValue(value);            
        } else {
            setError("Seleccioneu un valor");
        }
    } 

    const [regnumserieValue, setRegnumserieValue] = useState<string | null>(detailInfo?.regnumserie ?? null);
    
    const [regpotenciaValue, setRegpotenciaValue] = useState<number | null>(detailInfo?.regpotencia ?? null);
    const handleRegpotenciaSelection = (event: SelectChangeEvent) => {
        const value = event.target.value;
        if (value != null) { 
            setRegpotenciaValue(parseInt(value));            
        } else {
            setError("Seleccioneu un valor");
        }
    }      
    
    const [consumoRValue, setConsumoRValue] = useState<number | null>(detailInfo?.consumor ?? null);
    const handleConsumoRChange = (value: number) => {
        setConsumoRValue(value);
    }
    const [consumoSValue, setConsumoSValue] = useState<number | null>(detailInfo?.consumos ?? null);
    const handleConsumoSChange = (value: number) => {
        setConsumoSValue(value);
    }
    const [consumoTValue, setConsumoTValue] = useState<number | null>(detailInfo?.consumot ?? null);
    const handleConsumoTChange = (value: number) => {
        setConsumoTValue(value);
    }
    const [consumoNValue, setConsumoNValue] = useState<number | null>(detailInfo?.consumon ?? null);
    const handleConsumoNChange = (value: number) => {
        setConsumoNValue(value);
    }

    const [potactivaValue, setPotactivaValue] = useState<number | null>(detailInfo?.potactiva ?? null);
    const handlePotactivaChange = (value: number) => {
        setPotactivaValue(value);
    }
    const [potreactivaValue, setPotreactivaValue] = useState<number | null>(detailInfo?.potreactiva ?? null);
    const handlePotreactivaChange = (value: number) => {
        setPotreactivaValue(value);
    }
    const [potaparenteValue, setPotaparenteValue] = useState<number | null>(detailInfo?.potaparente ?? null);
    const handlePotaparenteChange = (value: number) => {
        setPotaparenteValue(value);
    }
    const [cosenoValue, setCosenoValue] = useState<number | null>(detailInfo?.coseno ?? null);
    const handleCosenoChange = (value: number) => {
        setCosenoValue(value);
    }
    const [tensionRsValue, setTensionRsValue] = useState<number | null>(detailInfo?.tensionrs ?? null);
    const handleTensionRsChange = (value: number) => {
        setTensionRsValue(value);
    }
    const [tensionRtValue, setTensionRtValue] = useState<number | null>(detailInfo?.tensionrt ?? null);
    const handleTensionRtChange = (value: number) => {
        setTensionRtValue(value);
    }
    const [tensionStValue, setTensionStValue] = useState<number | null>(detailInfo?.tensionst ?? null);
    const handleTensionStChange = (value: number) => {
        setTensionStValue(value);
    }    
    const [tensionNrValue, setTensionNrValue] = useState<number | null>(detailInfo?.tensionnr ?? null);
    const handleTensionNrChange = (value: number) => {
        setTensionNrValue(value);
    }    
    const [tensionNsValue, setTensionNsValue] = useState<number | null>(detailInfo?.tensionns ?? null);
    const handleTensionNsChange = (value: number) => {
        setTensionNsValue(value);
    }
    const [tensionNtValue, setTensionNtValue] = useState<number | null>(detailInfo?.tensionnt ?? null);
    const handleTensionNtChange = (value: number) => {
        setTensionNtValue(value);
    }    
    const [paparenteRValue, setPaparenteRValue] = useState<number | null>(detailInfo?.paparenter ?? null);
    const handlePaparenteRChange = (value: number) => {
        setPaparenteRValue(value);
    }
    const [pactivaRValue, setPactivaRValue] = useState<number | null>(detailInfo?.pactivar ?? null);
    const handlePactivaRChange = (value: number) => {
        setPactivaRValue(value);
    }
    const [preactivaRValue, setPreactivaRValue] = useState<number | null>(detailInfo?.preactivar ?? null);
    const handlePreactivaRChange = (value: number) => {
        setPreactivaRValue(value);
    }
    const [pfactorRValue, setPfactorRValue] = useState<number | null>(detailInfo?.pfactorr ?? null);
    const handlePfactorRChange = (value: number) => {
        setPfactorRValue(value);
    }
    const [paparenteSValue, setPaparenteSValue] = useState<number | null>(detailInfo?.paparentes ?? null);
    const handlePaparenteSChange = (value: number) => {
        setPaparenteSValue(value);
    }
    const [pactivaSValue, setPactivaSValue] = useState<number | null>(detailInfo?.pactivas ?? null);
    const handlePactivaSChange = (value: number) => {
        setPactivaSValue(value);
    }
    const [preactivaSValue, setPreactivaSValue] = useState<number | null>(detailInfo?.preactivas ?? null);
    const handlePreactivaSChange = (value: number) => {
        setPreactivaSValue(value);
    }
    const [pfactorSValue, setPfactorSValue] = useState<number | null>(detailInfo?.pfactors ?? null);
    const handlePfactorSChange = (value: number) => {
        setPfactorSValue(value);
    }
    const [paparenteTValue, setPaparenteTValue] = useState<number | null>(detailInfo?.paparentet ?? null);
    const handlePaparenteTChange = (value: number) => {
        setPaparenteTValue(value);
    }
    const [pactivaTValue, setPactivaTValue] = useState<number | null>(detailInfo?.pactivat ?? null);
    const handlePactivaTChange = (value: number) => {
        setPactivaTValue(value);
    }
    const [preactivaTValue, setPreactivaTValue] = useState<number | null>(detailInfo?.preactivat ?? null);
    const handlePreactivaTChange = (value: number) => {
        setPreactivaTValue(value);
    }
    const [pfactorTValue, setPfactorTValue] = useState<number | null>(detailInfo?.pfactort ?? null);
    const handlePfactorTChange = (value: number) => {
        setPfactorTValue(value);
    }    

    const [isInspected, setIsInspected] = useState<string>(detailInfo?.isInspected ?? 'N');
    const handleIsInspected = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null || value != undefined) {
            setIsInspected(value);
        } else {
            setError("Seleccioneu un valor");
        }
    }

    const [etiquetaValue, setEtiquetaValue] = useState<number | null>(detailInfo?.etiqueta ?? null);
    const [etiquetaDescription, setEtiquetaDescription] = useState<string | null>();
    const handleEtiquetaSelection = (e: React.ChangeEvent<any>, value: string | null) => {
        if(value!= null) {
            setEtiquetaValue(parseInt(value));
            const description = getDescriptionByKey(value.toString(), etiquetaCN);
            setEtiquetaDescription(description);
        } else {
            setError("Seleccioneu un valor");
        }
    }

    const [fabcuadroValue, setFabcuadroValue] = useState<string | null>(detailInfo?.fabcuadro ?? '');

    const [protecstValue, setProtecstValue] = useState<string | null>(detailInfo?.protecst ?? null);
    const [protecstDescription, setProtecstDescription] = useState<string>();
    const handleProtecstSelection = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null) { 
            setProtecstValue(value);
            const description = getDescriptionByKey(value, protecstCN);
            setProtecstDescription(description);
        } else {
            setError("Seleccioneu un valor");
        }
    }  

    const [xtiposaiValue, setXtiposaiValue] = useState<string | null>(detailInfo?.xtiposai ?? null);
    const [xtiposaiDescription, setXtiposaiDescription] = useState<string | null>();
    const handleXtiposaiSelection = (e: React.ChangeEvent<any>, value: string | null) => {
        if(value!= null) {
            setXtiposaiValue(value);
            const description = getDescriptionByKey(value.toString(), xtiposaiCN);
            setXtiposaiDescription(description);
        } else {
            setError("Seleccioneu un valor");
        }
    }
    const [saiValue, setSaiValue] = useState<string | null>(detailInfo?.sai ?? null);
    const [saiDescription, setSaiDescription] = useState<string | null>();
    const handleSaiSelection = (e: React.ChangeEvent<any>, value: string | null) => {
        if(value!= null) {
            setSaiValue(value);
            const description = getDescriptionByKey(value.toString(), saiCN);
            setSaiDescription(description);
        } else {
            setError("Seleccioneu un valor");
        }
    }

    const [commentsValue, setCommentsValue] = useState<string | null>(detailInfo?.comments ?? '');
    
    useEffect(() => {
        if (detailInfo && validValues) { 
            setCodestcuaDescription(getDescriptionByKey(detailInfo.codestcua?.toString() || '', generic));
            setCodestcierreDescription(getDescriptionByKey(detailInfo.codestcierre?.toString() || '', generic));
            setCodesttapasDescription(getDescriptionByKey(detailInfo.codesttapas?.toString() || '', generic));
            setCodesttubosDescription(getDescriptionByKey(detailInfo.codesttubos?.toString() || '', generic));
            setCodestextDescription(getDescriptionByKey(detailInfo.codestext || '', codestextCN));
            setCodtiposopDescription(getDescriptionByKey(detailInfo.codtiposop || '', codtiposopCN));
            setCodestsopcmDescription(getDescriptionByKey(detailInfo.codestsopcm?.toString() || '', generic));
            setCodestlineasDescription(getDescriptionByKey(detailInfo.codestlineas?.toString() || '', generic));
            setCodestarqDescription(getDescriptionByKey(detailInfo.codestarq?.toString() || '', generic));
            setEstconarqDescription(getDescriptionByKey(detailInfo.estconarq?.toString() || '', estconarqCN));
            setCurvaigaDescription(getDescriptionByKey(detailInfo.curvaiga || '', curvaigaCN));
            setCodtomatieDescription(getDescriptionByKey(detailInfo.codtomatie?.toString() || '', codtomatieCN));
            setFabregDescription(getDescriptionByKey(detailInfo.fabreg || '', fabregCN));
            setEtiquetaDescription(getDescriptionByKey(detailInfo.etiqueta?.toString() || '', etiquetaCN));
            setProtecstDescription(getDescriptionByKey(detailInfo.protecst || '', protecstCN));
            setXtiposaiDescription(getDescriptionByKey(detailInfo.xtiposai || '', xtiposaiCN));
            setSaiDescription(getDescriptionByKey(detailInfo.sai || '', saiCN));
        }
    }, [detailInfo, validValues])

    const convertPictureToData = (picture: IPictureT2 | null, category: string) => {
        if (picture && detailInfo?.rid && contractType) {
          const date = new Date();
          const day = date.getDate().toString().padStart(2, '0');
          const month = (date.getMonth() + 1).toString().padStart(2, '0');
          const year = date.getFullYear();
          const formattedDate = `${day}${month}${year}`;
    
          return {
            id: picture.id ?? -1,
            entityId: detailInfo.rid,
            imageType: category,
            base64: picture.base64,
            imageName: `${detailInfo.rid}_${formattedDate}_${category}`,
            imageDate: new Date(),
            isNew: picture.isNew            
          };
        }
    
        return null;
      };

    const handleSaveClick = async () => {
        if (detailInfo?.rid && fverifiValue) {
            const savedQc = {
                rid: detailInfo.rid,
                fverifi: fverifiValue,
                codtipocua: codtipocuaValue,
                codestcierre: codestcierreValue,
                codestcua: codestcuaValue,
                codesttapas: codesttapasValue,
                codesttubos: codesttubosValue,
                codestext: codestextValue,
                codtipopav: codtipopavValue,
                codtiposop: codtiposopValue,
                codestsopcm: codestsopcmValue,
                codestlineas: codestlineasValue,
                codestarq: codestarqValue,
                estconarq: estconarqValue,
                codelemaniobra: codelemaniobraValue,
                salidas: salidasValue,
                salidasactivas: salidasActivasValue,
                numcontactor: numContactorValue,
                numdif: numDifValue,
                codtipcanal: codtipcanalValue,
                igavalor: igavalorValue,
                curvaiga: curvaigaValue,
                voltajE1: voltaje1Value,
                voltajE2: voltaje2Value,
                codtomatie: codtomatieValue,
                tomatieval: tomatievalValue,
                tensioncontacto: tensioncontactoValue,
                fabreg: fabregValue,
                regmodelo: regmodeloValue,
                regnumserie: regnumserieValue,
                regpotencia: regpotenciaValue,
                consumor: consumoRValue,
                consumos: consumoSValue,
                consumot: consumoTValue,
                consumon: consumoNValue,
                potactiva: potactivaValue,
                potreactiva: potreactivaValue,
                potaparente: potaparenteValue,
                coseno: cosenoValue,
                tensionrs: tensionRsValue,
                tensionrt: tensionRtValue,
                tensionst: tensionStValue,
                tensionnr: tensionNrValue,
                tensionns: tensionNsValue,
                tensionnt: tensionNtValue,
                paparenter: paparenteRValue,
                pactivar: pactivaRValue,
                preactivar: preactivaRValue,
                pfactorr: pfactorRValue,
                paparentes: paparenteSValue,
                pactivas: pactivaSValue,
                preactivas: preactivaSValue,
                pfactors: pfactorSValue,
                paparentet: paparenteTValue,
                pactivat: pactivaTValue,
                preactivat: preactivaTValue,
                pfactort: pfactorTValue,
                etiqueta: etiquetaValue,
                fabcuadro: fabcuadroValue,
                protecst: protecstValue,
                xtiposai: xtiposaiValue,
                sai: saiValue,
                isInspected: isInspected,
                empId: empId,
                comments: commentsValue
            }

            if (savedQc && contractType) {
                await dispatchThunk(saveQcT2DetailThunk({ savedQc, contractType }));
            }
        }
    }
    
    
    const SubmitClick = async () => {
        if (detailInfo?.rid && fverifiValue && isInspected === 'Y') {
            const updatedQc = {
                rid: detailInfo.rid,
                fverifi: fverifiValue,
                codtipocua: codtipocuaValue,
                codestcierre: codestcierreValue,
                codestcua: codestcuaValue,
                codesttapas: codesttapasValue,
                codesttubos: codesttubosValue,
                codestext: codestextValue,
                codtipopav: codtipopavValue,
                codtiposop: codtiposopValue,
                codestsopcm: codestsopcmValue,
                codestlineas: codestlineasValue,
                codestarq: codestarqValue,
                estconarq: estconarqValue,
                codelemaniobra: codelemaniobraValue,                
                salidas: salidasValue,
                salidasactivas: salidasActivasValue,
                numcontactor: numContactorValue,
                numdif: numDifValue,
                codtipcanal: codtipcanalValue,
                igavalor: igavalorValue,
                curvaiga: curvaigaValue,
                voltajE1: voltaje1Value,
                voltajE2: voltaje2Value,
                codtomatie: codtomatieValue,
                tomatieval: tomatievalValue,
                tensioncontacto: tensioncontactoValue, 
                fabreg: fabregValue,
                regmodelo: regmodeloValue,
                regnumserie: regnumserieValue,
                regpotencia: regpotenciaValue,                
                consumor: consumoRValue,
                consumos: consumoSValue,
                consumot: consumoTValue,
                consumon: consumoNValue,
                potactiva: potactivaValue,
                potreactiva: potreactivaValue,
                potaparente: potaparenteValue,
                coseno: cosenoValue,
                tensionrs: tensionRsValue,
                tensionrt: tensionRtValue,
                tensionst: tensionStValue,
                tensionnr: tensionNrValue,
                tensionns: tensionNsValue,
                tensionnt: tensionNtValue,
                paparenter: paparenteRValue,
                pactivar: pactivaRValue,
                preactivar: preactivaRValue,
                pfactorr: pfactorRValue,
                paparentes: paparenteSValue,
                pactivas: pactivaSValue,
                preactivas: preactivaSValue,
                pfactors: pfactorSValue,
                paparentet: paparenteTValue,
                pactivat: pactivaTValue,
                preactivat: preactivaTValue,
                pfactort: pfactorTValue,
                etiqueta: etiquetaValue,
                fabcuadro: fabcuadroValue,
                protecst: protecstValue,
                xtiposai: xtiposaiValue,
                sai: saiValue,
                isInspected: isInspected,
                empId: empId,
                comments: commentsValue
            }

            try {                 
                const pictureData = [
                    convertPictureToData(openQcPic, openQcCategory),
                    convertPictureToData(closedQcPic, closedQcCategory),
                    convertPictureToData(suportPic, suportCategory),
                    convertPictureToData(planningPicture, planningQcCategory),
                    convertPictureToData(qcExtConnPicture, qcExtConnCategory),
                    convertPictureToData(additionalPicture, additionalQcCategory),
                  ];
                  const filteredPictureData: IImageDataT2[] = pictureData.filter((data) => data !== null) as IImageDataT2[];

                  if(filteredPictureData && contractType) {
                    await dispatchThunk(picturesT2Thunk(filteredPictureData));
                  }           

                  if(updatedQc && contractType) {
                    await dispatchThunk(updateQcT2DetailThunk({updatedQc, contractType}));
                  }

            } catch (error) {
                showError('Hi ha hagut un error amb les imatges. Torneu-ho a intentar.');
            }
        } else if(isInspected ==='N') {
            showError("Indiqueu que s'ha revisat el quadre");
        }
        else {
            showError("Empleneu tots els camps");
        }
    }    

    const showError = (message: string) => {     
        setError(message);
        setIsOpen(true);
        setTimeout(() => {
            setIsOpen(false);
            setError(null);
        }, 5000);
    }; 

    const handleSubmitClick = debounce(SubmitClick, 600);
    
    useEffect(() => {
        if (!detailInfo?.rid) {
            navigate('/')
        }
    }, [detailInfo?.rid])

    const getDescriptionByKey = (key: string, columnName: string): string => {
        const selectedValue = validValues.find(value => value.key === key && value.columnName.toUpperCase() === columnName.toUpperCase());
        return selectedValue ? selectedValue.value : '';
    }

    const fetchImages = async () => {
        try {
            if(detailInfo?.rid && contractType) {
                const data = await getImages(detailInfo?.rid, contractType) as IImageT2Result[];  
                          
                const images = data.map((img: IImageT2Result) => ({
                    id: img.id,
                    entityId: img.entityId,
                    imageType: img.imageType,                    
                    base64: `data:image/png;base64,${img.imageDataString}`,
                    isNew: false,
                    deleted: false,
                    mapId: uuid(),
                    contractType: contractType
                }))
                setImages(images);

            }          
        } catch (error) {
          console.error('Error fetching images:', error);
        }
      };

      useEffect(() => {
        console.log("use effect setting from images array")
         if(images)
         images.forEach((img: any) => {
             switch (img.imageType) {
               case openQcCategory:
                 setOpenQcPic(img);
                 break;
               case closedQcCategory:
                 setClosedQcPic(img);
                 break;
               case suportCategory:
                 setSuportPic(img);
                 break;
               case planningQcCategory:
                 setPlanningPic(img);
                 break;
                case qcExtConnCategory:
                 setQcExtConnPicture(img);
                 break;
               case additionalQcCategory:
                 setAdditionalPic(img);
                 break;
               default:
                 break;
             }
           });
 
       }, [images])
       
    useEffect(() => {
        const tableName = 'qc_t2';
        getValidValues(tableName)
            .then((values) => {
                setValidValues(values);
            })
            .catch((error) => {
                console.error(error);
            });
            fetchImages();
            if(detailInfo?.rid && contractType) {
                const qcId = detailInfo.rid;
                dispatchThunk(fetchCircuitT2DetailThunk({qcId, contractType}));
                dispatchThunk(fetchMeasurementT2DetailThunk({qcId, contractType}));
                dispatchThunk(fetchCmExtConnT2DetailThunk({id: qcId, contractType}));                
            } 
    }, []);

    useEffect(() => {
        if (isSubmitLoading) {
            setIsOpen(true);
        }
        if (isSubmitFulfilled) {
            setIsOpen(true);
            setTimeout(() => {
                dispatch(setIsSubmitFulfilled(false));
                setIsOpen(false);
                navigate('/')
            }, 3000);
        }
        if (isSubmitRejected) {
            setIsOpen(true);
            setTimeout(() => {
                setIsOpen(false);
            }, 3000);
        }
    }, [dispatch, isSubmitLoading, isSubmitFulfilled, isSubmitRejected])

    useEffect(() => {
        if (isSavedLoading) {
            setIsOpen(true);
        }
        if (isSavedFulfilled) {
            setIsOpen(true);
            setTimeout(() => {
                dispatch(setIsSavedFulfilled(false));
                setIsOpen(false);                
            }, 2000);
        }
        if (isSavedRejected) {
            setIsOpen(true);
            setTimeout(() => {
                setIsOpen(false);
            }, 2000);
        }
    }, [dispatch, isSavedLoading, isSavedFulfilled, isSavedRejected])

    /***************************** CIRCUIT CODE**************************/
    

    const handleNewCircuit = () => {
        if(detailInfo?.rid){
            setCenmando(detailInfo.rid); 
            setIsNewCircuitDialogOpen(true);
        }        
    }

    const handleCircuitRowClick = (circuit: ICircuitT2Detail) => {
        dispatch(setSelectedCircuitT2(circuit.rid));
        setIsExistingCirOpen(true);
    }

    const columnsCircuitT2: TableColumn<ICircuitT2Detail>[] = [
        { header: 'Editar', align: 'left'},
        { header: 'ID Circuit', accessor: 'rid', align: 'left' },
        { header: 'CM', accessor: 'cenmando', align: 'right' },
        { header: 'Nom circuit', accessor: 'circuitName', align: 'right' },    
        { header: 'Circuit Actiu', accessor: 'circuitoActivo', align: 'right', format: (value: boolean) => (value != null ? (value ? 'Si' : 'No') : '')},
        { header: 'Descripció', accessor: 'descripcion', align: 'right'},
        { header: 'Secció', accessor: 'seccion', align: 'right'},
        { header: 'Nº contactor', accessor: 'ncontactor', align: 'right'},
        { header: 'Contactor int', accessor: 'intcontactor', align: 'right'},
        { header: 'INTR', accessor: 'intr', align: 'right'},
        { header: 'INTS', accessor: 'ints', align: 'right'},
        { header: 'INTT', accessor: 'intt', align: 'right'},
        { header: 'INTN', accessor: 'intn', align: 'right'},
        { header: 'Diferencial int', accessor: 'intdiferencial', align: 'right'},
        { header: 'Diferencial sen', accessor: 'sendiferencial', align: 'right'},
        { header: 'PIA int', accessor: 'intmagneto', align:'right'},
        { header: 'Diferencial Mesura t resposta', accessor: 'tresdif', align:'right'},
        { header: 'Diferencial Mesura sen', accessor: 'tsendif', align:'right'},
        { header: 'Aïllament', accessor: 'aislamiento', align: 'right'}          
      ];

      /***************************************************** MEASUREMENT CODE  *************************************************************/

      const handleMeasurementClick = () => {
        setIsMeasurementDialogOpen(true);
      }

      /******************************************************* External Connection CODE ***************************************************/

      const handleExtConnRowClick = (selectedExtConn: ICmExternalConnectionT2Detail) => {
        dispatch(setSelectedCmExternalConnT2(selectedExtConn.id));
        setIsExtConnDialogOpen(true);
      }

      const columnsExtConnT2: TableColumn<ICmExternalConnectionT2Detail>[] = [
        { header: 'Editar', align: 'left'},
        { header: 'Id Connexió', accessor: 'rid', align: 'left'},
        { header: 'Id Cm', accessor: 'idCM', align: 'right' },
        { header: 'Tipus Connexió', accessor: 'tipocon', align: 'right' },
        { header: 'Estat Connexió', accessor: 'estadocon', align: 'right' },
        { header: 'Consum', accessor: 'consumo', align: 'right' },
        { header: 'Circuit aliè monofàsic', accessor: 'ciralimon', align: 'right' }

      ];

      

    return(
        <Container>
            <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, my: 2 }} noValidate autoComplete="off">
                <Box sx={{display: 'flex', flexDirection:'column', justifyContent: 'space-between'}}>                    
                    <Typography variant='subtitle1' fontWeight={'medium'}>RID</Typography>
                    <TextField id="suport-id" variant="outlined" value={detailInfo?.rid} contentEditable={false} sx={{backgroundColor: 'white', marginLeft:'0 !important'}}  />
                </Box>
                <DateInputComponent description='Data verificació' date={fverifiValue} handleDateChange={handleFverifiChange} />               
                <Box sx={{ my: 1 }}>
                    <Typography variant='subtitle1' sx={{ my: 1 }} fontWeight={'medium'} >Tipus quadre</Typography>
                    <Select
                        labelId="cm-type-select-label"
                        id="demo-simple-select"
                        value={codtipocuaValue || ''}
                        label="cm-type"
                        onChange={handleCodtipocuaSelection}
                        sx={{
                            width: '100%',
                            display: 'flex',
                            backgroundColor: 'white',

                        }}
                    >
                        {validValues
                            .filter(value => value.columnName === codtipocuaCN)
                            .map((value, index) => (
                                <MenuItem key={index} value={value.key}>{`${value.key} - ${value.value}`}</MenuItem>
                            ))}
                    </Select>
                </Box>
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Estat quadre({codestcuaDescription})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={codestcuaValue}
                            exclusive
                            onChange={handleCodestcuaSelection}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === generic)
                            .map((value, index) => (
                                <ToggleButton key={index} value={parseInt(value.key)} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Estat tancament({codestcierreDescription})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={codestcierreValue}
                            exclusive
                            onChange={handleCodestcierreSelection}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === generic)
                            .map((value, index) => (
                                <ToggleButton key={index} value={parseInt(value.key)} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Estat tapes({codesttapasDescription})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={codesttapasValue}
                            exclusive
                            onChange={handleCodesttapasSelection}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === generic)
                            .map((value, index) => (
                                <ToggleButton key={index} value={parseInt(value.key)} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Estat dels tubs/ muntants({codesttubosDescription})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={codesttubosValue}
                            exclusive
                            onChange={handleCodesttubosSelection}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === generic)
                            .map((value, index) => (
                                <ToggleButton key={index} value={parseInt(value.key)} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Estat estètica exterior({codestextDescription})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={codestextValue}
                            exclusive
                            onChange={handleCodestextSelection}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === codestextCN)
                            .map((value, index) => (
                                <ToggleButton key={index} value={value.key} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>
                <Box sx={{ my: 1 }}>
                    <Typography variant='subtitle1' sx={{ my: 1 }} fontWeight={'medium'} >Tipus paviment</Typography>
                    <Select
                        labelId="cm-type-select-label"
                        id="demo-simple-select"
                        value={codtipopavValue?.toString() || ''}
                        label="cm-type"
                        onChange={handleCodtipopavSelection}
                        sx={{
                            width: '100%',
                            display: 'flex',
                            backgroundColor: 'white',

                        }}
                    >
                        {validValues
                            .filter(value => value.columnName === codtipopavCN)
                            .map((value, index) => (
                                <MenuItem key={index} value={value.key}>{`${value.key} - ${value.value}`}</MenuItem>
                            ))}
                    </Select>
                </Box>                
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Tipus suport del quadre({codtiposopDescription})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={codtiposopValue}
                            exclusive
                            onChange={handleCodtiposopSelection}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === codtiposopCN)
                            .map((value, index) => (
                                <ToggleButton key={index} value={value.key} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Estat suport del quadre({codestsopcmDescription})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={codestsopcmValue}
                            exclusive
                            onChange={handleCodestsopcmSelection}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === generic)
                            .map((value, index) => (
                                <ToggleButton key={index} value={parseInt(value.key)} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Estat línies aèries i grapades ({codestlineasDescription})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={codestlineasValue}
                            exclusive
                            onChange={handleCodestlineasSelection}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === generic)
                            .map((value, index) => (
                                <ToggleButton key={index} value={parseInt(value.key)} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Estat pericons (tapa i marc) ({codestarqDescription})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={codestarqValue}
                            exclusive
                            onChange={handleCodestarqSelection}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === generic)
                            .map((value, index) => (
                                <ToggleButton key={index} value={parseInt(value.key)} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Connexions dins pericons ({estconarqDescription})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={estconarqValue}
                            exclusive
                            onChange={handleEstconarqSelection}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === estconarqCN)
                            .map((value, index) => (
                                <ToggleButton key={index} value={parseInt(value.key)} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>
                <Box width={'100%'} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                    <Button variant='contained' onClick={handleSaveClick} sx={{ marginY: 3, width: '50%', backgroundColor: 'success.light', paddingY: '10px', fontSize: '1.2rem' }}>Guardar Progrés</Button>
                </Box>
                <Box sx={{ my: 1 }}>
                    <Typography variant='subtitle1' sx={{ my: 1 }} fontWeight={'medium'} >Element de Govern </Typography>
                    <Select
                        labelId="cm-type-select-label"
                        id="demo-simple-select"
                        value={codelemaniobraValue || ''}
                        label="cm-type"
                        onChange={handleCodelemaniobraSelection}
                        sx={{
                            width: '100%',
                            display: 'flex',
                            backgroundColor: 'white',

                        }}
                    >
                        {validValues
                            .filter(value => value.columnName === codelemaniobraCN)
                            .map((value, index) => (
                                <MenuItem key={index} value={value.key}>{`${value.key} - ${value.value}`}</MenuItem>
                            ))}
                    </Select>
                </Box>                    
                <Box>
                    <Typography variant='subtitle1' fontWeight={'medium'} >Nº sortides</Typography>
                    <TextField id='toamtie-id' type='number' value={salidasValue} onChange={(e: any) => setSalidasValue(parseInt(e.target.value))}  sx={{backgroundColor: 'white', marginLeft:'0 !important'}} InputProps={{ inputProps: { min: 0 } }} />
                </Box>
                <Box>
                    <Typography variant='subtitle1' fontWeight={'medium'} >Sortides Actives</Typography>
                    <TextField id='toamtie-id' type='number' value={salidasActivasValue} onChange={(e: any) => setSalidasActivasValue(parseInt(e.target.value))}  sx={{backgroundColor: 'white', marginLeft:'0 !important'}} InputProps={{ inputProps: { min: 0 } }} />
                </Box>
                <Box>
                    <Typography variant='subtitle1' fontWeight={'medium'} >Nº Contactor</Typography>
                    <TextField id='toamtie-id' type='number' value={numContactorValue} onChange={(e: any) => setNumContactorValue(parseInt(e.target.value))}  sx={{backgroundColor: 'white', marginLeft:'0 !important'}} InputProps={{ inputProps: { min: 0 } }} />
                </Box>
                <Box>
                    <Typography variant='subtitle1' fontWeight={'medium'} >Nº Diferencials</Typography>
                    <TextField id='toamtie-id' type='number' value={numDifValue} onChange={(e: any) => setNumDifValue(parseInt(e.target.value))}  sx={{backgroundColor: 'white', marginLeft:'0 !important'}} InputProps={{ inputProps: { min: 0 } }} />
                </Box>                
                <Box sx={{ my: 1 }}>
                    <Typography variant='subtitle1' sx={{ my: 1 }} fontWeight={'medium'} >Tipus d'alimentació</Typography>
                    <Select
                        labelId="cm-type-select-label"
                        id="demo-simple-select"
                        value={codtipcanalValue || ''}
                        label="cm-type"
                        onChange={handleCodtipcanalSelection}
                        sx={{
                            width: '100%',
                            display: 'flex',
                            backgroundColor: 'white',

                        }}
                    >
                        {validValues
                            .filter(value => value.columnName === codtipcanalCN)
                            .map((value, index) => (
                                <MenuItem key={index} value={value.key}>{`${value.key} - ${value.value}`}</MenuItem>
                            ))}
                    </Select>
                </Box>
                <Box>
                    <Typography variant='subtitle1' fontWeight={'medium'} >IGA general</Typography>
                    <TextField id='toamtie-id' type='number' value={igavalorValue} onChange={(e: any) => setIgavalorValue(parseInt(e.target.value))}  sx={{backgroundColor: 'white', marginLeft:'0 !important'}} InputProps={{ inputProps: { min: 0 } }} />
                </Box>  
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Corba de l'IGA ({curvaigaDescription})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={curvaigaValue}
                            exclusive
                            onChange={handleCurvaigaSelection}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === curvaigaCN)
                            .map((value, index) => (
                                <ToggleButton key={index} value={value.key} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>
                <DecimalInput labelId='voltaje1' labelText='Tensió subministrament 1' initialValue={voltaje1Value} onChange={handleVoltaje1Change}  />
                <DecimalInput labelId='voltaje2' labelText='Tensió subministrament 2' initialValue={voltaje2Value} onChange={handleVoltaje2Change}  />                
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Estat instal·lació terra ({codtomatieDescription})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={codtomatieValue}
                            exclusive
                            onChange={handleCodtomatieSelection}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === codtomatieCN)
                            .map((value, index) => (
                                <ToggleButton key={index} value={value.key} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>
                <DecimalInput labelId='tomatieval' labelText='Valor TT CM' initialValue={tomatievalValue} onChange={handleTomatievalChange}  />
                <DecimalInput labelId='tensioncontacto' labelText='Tensió de contacte' initialValue={tensioncontactoValue} onChange={handleTensioncontactoChange} />
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Fabricant regulador ({fabregDescription})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={fabregValue}
                            exclusive
                            onChange={handleFabregSelection}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === fabregCN)
                            .map((value, index) => (
                                <ToggleButton key={index} value={value.key} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>                
                <Box sx={{ my: 1 }}>
                    <Typography variant='subtitle1' sx={{ my: 1 }} fontWeight={'medium'} >Model regulador</Typography>
                    <Select
                        labelId="regmodelo-type-select-label"
                        id="demo-simple-select"
                        value={ regmodeloValue || ''}
                        label="regmodelo-type"
                        onChange={handleRegModeloSelection}
                        sx={{
                            width: '100%',
                            display: 'flex',
                            backgroundColor: 'white',

                        }}
                    >
                        {validValues
                            .filter(value => value.columnName === regmodeloCN)
                            .map((value, index) => (
                                <MenuItem key={index} value={value.key}>{`${value.key} - ${value.value}`}</MenuItem>
                            ))}
                    </Select>
                </Box>
                <Box>
                    <Typography variant='subtitle1' fontWeight={'medium'} >Nº de sèrie</Typography>
                    <TextField id='toamtie-id' value={regnumserieValue} onChange={(e: any) => setRegnumserieValue(e.target.value)}  sx={{backgroundColor: 'white', marginLeft:'0 !important'}} InputProps={{ inputProps: { min: 0 } }} />
                </Box>
                <Box sx={{ my: 1 }}>
                    <Typography variant='subtitle1' sx={{ my: 1 }} fontWeight={'medium'} >Potencia del regulador (kVa)</Typography>
                    <Select
                        labelId="cm-type-select-label"
                        id="demo-simple-select"
                        value={ regpotenciaValue?.toString() || ''}
                        label="cm-type"
                        onChange={handleRegpotenciaSelection}
                        sx={{
                            width: '100%',
                            display: 'flex',
                            backgroundColor: 'white',

                        }}
                    >
                        {validValues
                            .filter(value => value.columnName === regpotenciaCN)
                            .map((value, index) => (
                                <MenuItem key={index} value={value.key}>{`${value.key} - ${value.value}`}</MenuItem>
                            ))}
                    </Select>
                </Box> 
                <Box width={'100%'} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                    <Button variant='contained' onClick={handleSaveClick} sx={{ marginY: 3, width: '50%', backgroundColor: 'success.light', paddingY: '10px', fontSize: '1.2rem' }}>Guardar Progrés</Button>
                </Box>               
                <DecimalInput labelId='consumor' labelText='Consum Gral R' initialValue={consumoRValue} onChange={handleConsumoRChange}  />
                <DecimalInput labelId='consumos' labelText='Consum Gral S' initialValue={consumoSValue} onChange={handleConsumoSChange}  />
                <DecimalInput labelId='consumot' labelText='Consum Gral T' initialValue={consumoTValue} onChange={handleConsumoTChange}  />
                <DecimalInput labelId='consumon' labelText='Consum Gral N' initialValue={consumoNValue} onChange={handleConsumoNChange}  />
                <DecimalInput labelId='potactiva' labelText='Potència Kw' initialValue={potactivaValue} onChange={handlePotactivaChange}  />
                <DecimalInput labelId='potreactiva' labelText='Potència Kvar' initialValue={potreactivaValue} onChange={handlePotreactivaChange}  />
                <DecimalInput labelId='potaparente' labelText='Potència KVA' initialValue={potaparenteValue} onChange={handlePotaparenteChange}  />
                
                <Box width={'100%'} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                    <Button variant='contained' onClick={handleSaveClick} sx={{ marginY: 3, width: '50%', backgroundColor: 'success.light', paddingY: '10px', fontSize: '1.2rem' }}>Guardar Progrés</Button>
                </Box>

                <DecimalInput labelId='coseno' labelText='Potència COS' initialValue={cosenoValue} onChange={handleCosenoChange}  />
                <DecimalInput labelId='tensionrs' labelText='Tensiones entre fases R-S' initialValue={tensionRsValue} onChange={handleTensionRsChange}  />
                <DecimalInput labelId='tensionrt' labelText='Tensiones entre fases R-T' initialValue={tensionRtValue} onChange={handleTensionRtChange}  />
                <DecimalInput labelId='tensionst' labelText='Tensiones entre fases S-T' initialValue={tensionStValue} onChange={handleTensionStChange}  />
                <DecimalInput labelId='tensionnr' labelText='Tensió fase N-R' initialValue={tensionNrValue} onChange={handleTensionNrChange}  />
                <DecimalInput labelId='tensionns' labelText='Tensió fase N-S' initialValue={tensionNsValue} onChange={handleTensionNsChange}  />
                <DecimalInput labelId='tensionnt' labelText='Tensió fase N-T' initialValue={tensionNtValue} onChange={handleTensionNtChange}  />

                <Box width={'100%'} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                    <Button variant='contained' onClick={handleSaveClick} sx={{ marginY: 3, width: '50%', backgroundColor: 'success.light', paddingY: '10px', fontSize: '1.2rem' }}>Guardar Progrés</Button>
                </Box>

                <DecimalInput labelId='paparenter' labelText='Potencia Fase R KVA' initialValue={paparenteRValue} onChange={handlePaparenteRChange}  />
                <DecimalInput labelId='pactivar' labelText='Potencia Fase R KW' initialValue={pactivaRValue} onChange={handlePactivaRChange}  />
                <DecimalInput labelId='preactivar' labelText='Potencia Fase R KVar' initialValue={preactivaRValue} onChange={handlePreactivaRChange}  />
                <DecimalInput labelId='pfactorr' labelText='Potencia Fase R COS' initialValue={pfactorRValue} onChange={handlePfactorRChange}  />
                <DecimalInput labelId='paparentes' labelText='Potencia Fase S KVA' initialValue={paparenteSValue} onChange={handlePaparenteSChange}  />
                <DecimalInput labelId='pactivas' labelText='Potencia Fase S KW' initialValue={pactivaSValue} onChange={handlePactivaSChange}  />

                <Box width={'100%'} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                    <Button variant='contained' onClick={handleSaveClick} sx={{ marginY: 3, width: '50%', backgroundColor: 'success.light', paddingY: '10px', fontSize: '1.2rem' }}>Guardar Progrés</Button>
                </Box>

                <DecimalInput labelId='preactivas' labelText='Potencia Fase S KVar' initialValue={preactivaSValue} onChange={handlePreactivaSChange}  />
                <DecimalInput labelId='pfactors' labelText='Potencia Fase S COS' initialValue={pfactorSValue} onChange={handlePfactorSChange}  />
                <DecimalInput labelId='paparentet' labelText='Potencia Fase T KVA' initialValue={paparenteTValue} onChange={handlePaparenteTChange}  />
                <DecimalInput labelId='pactivat' labelText='Potencia Fase T KW' initialValue={pactivaTValue} onChange={handlePactivaTChange}  />
                <DecimalInput labelId='preactivat' labelText='Potencia Fase T KVar' initialValue={preactivaTValue} onChange={handlePreactivaTChange}  />
                <DecimalInput labelId='pfactort' labelText='Potencia Fase T COS' initialValue={pfactorTValue} onChange={handlePfactorTChange}  />

                <Box width={'100%'} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                    <Button variant='contained' onClick={handleSaveClick} sx={{ marginY: 3, width: '50%', backgroundColor: 'success.light', paddingY: '10px', fontSize: '1.2rem' }}>Guardar Progrés</Button>
                </Box>
                
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Etiqueta ({etiquetaDescription})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={etiquetaValue}
                            exclusive
                            onChange={handleEtiquetaSelection}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === etiquetaCN)
                            .map((value, index) => (
                                <ToggleButton key={index} value={parseInt(value.key)} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>
                <Box sx={{display: 'flex', flexDirection:'column', justifyContent: 'space-between'}}>                    
                    <Typography variant='subtitle1' fontWeight={'medium'}>Fabricant QM</Typography>
                    <TextField id="suport-id" variant="outlined" value={fabcuadroValue} onChange={(e: any) => setFabcuadroValue(e.target.value)} sx={{backgroundColor: 'white', marginLeft:'0 !important'}}  />
                </Box>                
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Protector Sobretensions ({protecstDescription})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={protecstValue}
                            exclusive
                            onChange={handleProtecstSelection}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === protecstCN)
                            .map((value, index) => (
                                <ToggleButton key={index} value={value.key} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Tipus SAI({xtiposaiDescription})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={xtiposaiValue}
                            exclusive
                            onChange={handleXtiposaiSelection}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === xtiposaiCN)
                            .map((value, index) => (
                                <ToggleButton key={index} value={value.key} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >SAI({saiDescription})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={saiValue}
                            exclusive
                            onChange={handleSaiSelection}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === saiCN)
                            .map((value, index) => (
                                <ToggleButton key={index} value={value.key} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>
                <Box width={'100%'} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                    <Button variant='contained' onClick={handleSaveClick} sx={{ marginY: 3, width: '50%', backgroundColor: 'success.light', paddingY: '10px', fontSize: '1.2rem' }}>Guardar Progrés</Button>
                </Box>
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Revisat</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={isInspected}
                            exclusive
                            onChange={handleIsInspected}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        >  <ToggleButton value='N' aria-label='notInspected' css={toggleBtnsStyle}>
                                <Typography variant="subtitle1">{'No revisat'}</Typography>
                            </ToggleButton>
                            <ToggleButton value='Y' aria-label='inspected' css={toggleBtnsStyle}>
                                <Typography variant="subtitle1">{'Revisat'}</Typography>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    )}
                </Box>
                <Box sx={{display: 'flex', flexDirection:'column', justifyContent: 'space-between'}}>                    
                    <Typography variant='subtitle1' fontWeight={'medium'}>Observacions</Typography>
                    <TextField id="suport-id" variant="outlined" value={commentsValue} onChange={(e: any) => setCommentsValue(e.target.value)} sx={{backgroundColor: 'white', marginLeft:'0 !important',  width: '100% !important'}}  />
                </Box>
                <Box width={'100%'} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start' }}>
                    <TableComponent dataList={circuitList} columns={columnsCircuitT2} handleRowClick={handleCircuitRowClick} />
                    <Button variant='outlined' onClick={handleNewCircuit}><AddBoxIcon sx={{ marginRight: 1 }} /> Afegir nou circuit</Button>
                </Box>                
                <Box sx={{ mt: 3, border: '2px solid #1565c0', borderRadius: '8px', paddingTop: 2, paddingBottom: 2, paddingLeft: 2 }}>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Mòdul de Mesura {measurementInfo?.idCM}</Typography>
                    <Button variant='contained' onClick={handleMeasurementClick} sx={{my: 1}}>Editar mòdul mesura</Button>
                </Box>
                {cmExtConnList ? (
                    <Box width={'100%'} sx={{ mt: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start' }}>
                        <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Connexions alienes</Typography>
                        <TableComponent dataList={cmExtConnList} columns={columnsExtConnT2} handleRowClick={handleExtConnRowClick} />
                    </Box>
                ) : (
                    <Box width={'100%'} sx={{ mt: 3, border: '2px solid #1565c0', borderRadius: '8px', paddingTop: 2, paddingBottom: 2, paddingLeft: 2 }}>
                        <Typography variant="body1" fontWeight={'medium'}>Aquest quadre no té Connexions alienes</Typography>
                    </Box>
                )}
                <CameraT2Component title='Quadre obert' category={openQcCategory} existingPicture={openQcPic} onPictureChange={handlePictureChange} contractType={contractType} />
                <CameraT2Component title='Quadre tancat' category={closedQcCategory} existingPicture={closedQcPic} onPictureChange={handlePictureChange} contractType={contractType} />
                <CameraT2Component title='Foto general suports' category={suportCategory} existingPicture={suportPic} onPictureChange={handlePictureChange} contractType={contractType} />
                <CameraT2Component title='Foto planols' category={planningQcCategory} existingPicture={planningPicture} onPictureChange={handlePictureChange} contractType={contractType} />
                <CameraT2Component title='Foto proteccions sortida de les connexions alienes (si hi ha)' category={qcExtConnCategory} existingPicture={qcExtConnPicture} onPictureChange={handlePictureChange} contractType={contractType} />
                <CameraT2Component title='Altres' category={additionalQcCategory} existingPicture={additionalPicture} onPictureChange={handlePictureChange} contractType={contractType} />


                <Box width={'100%'} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button variant='contained' disabled={isSubmitLoading} onClick={handleSubmitClick} sx={{ marginY: 3, width: '50%', backgroundColor: 'secondary.main', paddingY: '15px', fontSize: '1.2rem' }}>Guardar</Button>
                </Box>
                <CircuitsT2 isDialogOpen={isExistingCircOpen} setIsDialogOpen={setIsExistingCirOpen} />
                <NewCircuitT2 cenmando={cenmando} isDialogOpen={isNewCircuitDialogOpen} setIsDialogOpen={setIsNewCircuitDialogOpen} />
                <MeasurementT2 isDialogOpen={isMeasurmentDialogOpen} setIsDialogOpen={setIsMeasurementDialogOpen} />
                <CmExternalConnectionT2 isDialogOpen={isExtConnDialogOpen} setIsDialogOpen={setIsExtConnDialogOpen} />
                {error && (
                    <ErrorBackdropComponent error={error} isOpen={isOpen} />
                )}
                {(isSubmitLoading || isSubmitFulfilled || isSubmitRejected) && (
                    <BackdropComponent isLoading={isSubmitLoading} isFulfilled={isSubmitFulfilled} isRejected={isSubmitRejected} isOpen={isOpen} />
                )}
                {(isSavedLoading || isSavedFulfilled || isSavedRejected) && (
                    <BackdropComponent isLoading={isSavedLoading} isFulfilled={isSavedFulfilled} isRejected={isSavedRejected} isOpen={isOpen} />
                )}
            </Box> 
        </Container>
    )
}

