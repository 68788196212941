import { baseUrl } from "../../variables";
import { IMeasurementT2Detail } from "../types";


export const fetchMeasurementT2Detail = async (id: number, contractType: string): Promise<IMeasurementT2Detail> => {
    try {
        const response = await fetch(baseUrl + `/${contractType}/MeasurementT2?Id=${id}`);

        if(!response.ok) {
            return {} as IMeasurementT2Detail;
        }
        const data = await response.json();
        return data;

    } catch (error) {
        throw error;
    }
}

export const updateMeasurementT2Detail = async(formData: IMeasurementT2Detail, contractType: string): Promise<void> => {
    try {
        const response = await fetch(baseUrl + `/${contractType}/MeasurementT2/${formData.idCM}`, {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(formData)
        });

        if (response.status !== 204) {
            throw new Error(`Request failed with status ${response.status}`);
        }   

    } catch (error) {
        throw error;
    }
}