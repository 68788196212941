import { baseUrl } from "../../variables";
import { ISuportT2Detail } from "../types";


export const updateSuportDetail = async (formData: ISuportT2Detail, contract: string): Promise<void> => {
    try {
             
      const response = await fetch(baseUrl + `/${contract}/Suport/${formData.rid}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      });
  
      if (response.status !== 204) {
        throw new Error(`Request failed with status ${response.status}`);
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }