import { IQcT2Detail } from "../../Api/types"
import { Drawer, Button, Container, Box, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CloseIcon from '@mui/icons-material/Close';

type IProps = {
  qcDetailInfo: IQcT2Detail
}

export default function QcT2PlusDetailComponent({qcDetailInfo} : IProps) {

    return(
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', marginX: 2, marginY: 1, '@media (min-width: 600px)': { gridTemplateColumns: '1fr 1fr', gap: '10px', } }}>
            {/* Left Column */}
            <Box>
              <Box sx={{ marginY: '10px'  }}>
                <Typography fontWeight='fontWeightMedium' variant='subtitle1'>RID</Typography>
                <Typography variant='body2'>{qcDetailInfo?.rid}</Typography>                
              </Box>
              <Box sx={{ marginY: '10px' }}>
                <Typography fontWeight='fontWeightMedium' variant='subtitle1'>Data verificació visual</Typography>
                <Typography variant='body2'>{qcDetailInfo?.fverifi? new Date(qcDetailInfo?.fverifi).toLocaleDateString('es-ES') : 'Sense data'}</Typography>
              </Box>
              <Box sx={{ marginY: '10px' }}>
              {qcDetailInfo?.isInspected === 'Y' ? (
                  <Typography fontWeight='fontWeightMedium' sx={{display: 'flex', alignItems:'flex-end'}}>
                    Revisat <CheckIcon sx={{ marginLeft: '10px', color: '#4caf50' }} />
                  </Typography>
                ) : (
                  <Typography fontWeight='fontWeightMedium' sx={{display: 'flex', alignItems:'flex-end', color: 'palette.warning.light'}}>
                    No revisat <CloseIcon sx={{color: '#d32f2f'}} />
                  </Typography>
                )}
              </Box>              
            </Box>
          </Box>
    )
} 