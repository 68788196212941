import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type IProps = {
    description: string;
    date: Date | null;
    handleDateChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function DateInputComponent ({ description, date, handleDateChange }: IProps) {
  const formattedDate = formatDate(date); 

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'start' }}>
      <Typography variant='subtitle1' fontWeight={'medium'}>
        {description}
      </Typography>
      <input type='date' value={formattedDate} onChange={handleDateChange} onKeyDown={(e) => e.preventDefault()} />
    </Box>
  );
};



const formatDate = (date: Date | null): string => {
    if (!date) {
      return '';
    }
  
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = date.getDate().toString().padStart(2, '0');
  
    return `${year}-${month}-${day}`;
};
  