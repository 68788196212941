import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../../store/store';
import { IMeasurementT2Detail } from '../../Api/types';
import { fetchMeasurementT2Detail, updateMeasurementT2Detail } from '../../Api/T2/MeasurementT2';

interface MeasurementState {
    detailInfo: IMeasurementT2Detail | null;
    isLoading: boolean;
    isFulfilled: boolean;
    isRejected: boolean;
}

const initialState: MeasurementState = {
    detailInfo: null,
    isLoading: false,
    isFulfilled: false,
    isRejected: false
}

export const fetchMeasurementT2DetailThunk = createAsyncThunk(
    'measurement/fetchMeasurementT2',
    async(circuitObj:{qcId: number, contractType: string}, thunkApi) => {
      try {
        const data = await fetchMeasurementT2Detail(circuitObj.qcId, circuitObj.contractType);
        thunkApi.dispatch(setMeasurementT2DetailInfo(data));
      } catch (error) {
        
      }
    }
  );

export const updateMeasurementT2DetailThunk = createAsyncThunk(
    'measurement/updateMeasurementT2Detail',
    async(obj: {updatedMeasurement: IMeasurementT2Detail, contractType: string}, thunkApi) => {
        try {
            await updateMeasurementT2Detail(obj.updatedMeasurement, obj.contractType);
        } catch (error) {
            throw error;
        }
    }
);

const measurementT2Slice = createSlice({
    name: 'measurement',
    initialState,
    reducers: {
        setMeasurementT2DetailInfo: (state, action: PayloadAction<IMeasurementT2Detail>) => {
            state.detailInfo = action.payload;
        },
        setIsFulfilled: (state, action: PayloadAction<boolean>) => {
            state.isFulfilled = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateMeasurementT2DetailThunk.pending, (state) => {
                state.isLoading = true;
                state.isFulfilled = false;
                state.isRejected = false;
            })
            .addCase(updateMeasurementT2DetailThunk.fulfilled, (state) => {
                state.isLoading = false;
                state.isFulfilled = true;
                state.isRejected = false;
            })
            .addCase(updateMeasurementT2DetailThunk.rejected, (state) => {
                state.isLoading = false;
                state.isFulfilled = false;
                state.isRejected = true;
            })
    }
})

export const { setMeasurementT2DetailInfo, setIsLoading, setIsFulfilled} = measurementT2Slice.actions;


export default measurementT2Slice.reducer;