import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Container, Dialog, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { IValidValue } from "../../Api/types";
import { getValidValues } from "../../Api/ValidValues";
import { updateTramT2DetailThunk, insertNewTramT2Thunk, fetchTramT2DetailThunk, setIsFulfilled, setTramT2DetailInfo } from "../../Slices/T2/tramT2Slice";
import CameraTramT2, { IPicture } from "../Camera/CameraTramT2";
import BackdropComponent from "../BackdropComponent";
import ErrorBackdropComponent from "../ErrorBackdropComponent";



interface IProps {
    isDialogOpen: boolean;
    setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    cmId: number;
    suport1Id: number;
}

export default function TramT2({isDialogOpen, setIsDialogOpen, cmId, suport1Id}: IProps) {
    const { tramDetailInfo, isLoading, isRejected, isFulfilled, empId, contractType} = useSelector((state: RootState) => ({
        tramDetailInfo: state.tramT2.detailInfo,
        isLoading: state.tramT2.isLoading,
        isRejected: state.tramT2.isRejected,
        isFulfilled: state.tramT2.isFulfilled,
        empId: state.user.empId,
        contractType: state.user.contracttype,
    }))
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const dispatchThunk = useDispatch<AppDispatch>();
    const dispatch = useDispatch();
    const [bottomMarginContainer, setBottomMarginContainer] = useState<string>();
    const fixedContainerRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>(null);
    const [validValues, setValidValues] = useState<IValidValue[]>([]);

    //#region  Variables 
    const codtiposecCN = 'CODTIPOSEC';
    //#endregion

    const [cmIdValue, setCmIdValue] = useState<number | null>(tramDetailInfo?.cm ?? cmId);
    const [ridSuport1, setRidSuport1]  = useState<number | null>(tramDetailInfo?.ridsuporT1 ? tramDetailInfo.ridsuporT1 : suport1Id ? suport1Id : null);
    const [ridSuport2, setRidSuport2] = useState<number | null>(tramDetailInfo?.ridsuporT2 ?? null);
    const [codtiposecValue, setCodtiposecValue] = useState<number | null>(tramDetailInfo?.codtiposec ?? 28);
    const [imageData, setImageData] = useState<IPicture | null>(null);

    const handleModeloCgpSelection = (event: SelectChangeEvent) => {
        const value = parseInt(event.target.value);
        if(value != null) {
            setCodtiposecValue(value);
        } else {
            setError("Seleccioneu un valor");
        }
    }

    useEffect(() => {
        const tableName = 'trams_t2';
        getValidValues(tableName)
            .then((values) => {
                setValidValues(values);
            })
            .catch((error) => {
                console.error(error);
            });            
    }, []);

    useEffect(() => {
        if (fixedContainerRef.current) {
            const fixedContainerHeight = fixedContainerRef.current.offsetHeight;
            setBottomMarginContainer(`${fixedContainerHeight + 60}px`);
        }
    }, [fixedContainerRef.current]);


    
    useEffect(() => {     
        setCmIdValue(tramDetailInfo?.cm ?? cmId);
        setRidSuport1(tramDetailInfo?.ridsuporT1 ? tramDetailInfo.ridsuporT1 : suport1Id ? suport1Id : null);
        setRidSuport2(tramDetailInfo?.ridsuporT2 ?? null);
        const existingPrefix = 'data:image/png;base64,';
        const base64Data = tramDetailInfo?.imageDataString;

        if (base64Data) {
            const formattedBase64 = base64Data.startsWith(existingPrefix)
                ? base64Data
                : `${existingPrefix}${base64Data}`;

            const newPicture: IPicture = {
                base64: formattedBase64,
                isNew: false,
                deleted: false,
            };

            setImageData(newPicture);
        } else {
            setImageData(null);
        }

    }, [tramDetailInfo, cmId, suport1Id, contractType]);
    

    
    const handleSaveClick = async() => {
        if((ridSuport1 !== null && ridSuport2 !== null) && (cmIdValue !== -1 || cmIdValue !== null)) {
            if(tramDetailInfo?.id) {
                const updatedTram = {
                    id: tramDetailInfo?.id,
                    cm: cmIdValue,
                    ridsuporT1: ridSuport1,
                    ridsuporT2: ridSuport2,
                    codtiposec: codtiposecValue,
                    imageDataString: imageData?.base64 ?? null
                }
                if(updatedTram && contractType) {
                   await dispatchThunk(updateTramT2DetailThunk({updatedTram, contractType})) 
                }
            } else {
                const newTram = {
                    id: -1,
                    cm: cmIdValue,
                    ridsuporT1: ridSuport1,
                    ridsuporT2: ridSuport2,
                    codtiposec: codtiposecValue,
                    imageDataString: imageData?.base64 ?? null 
                }
                if(newTram && contractType) {
                    await dispatchThunk(insertNewTramT2Thunk({newTram, contractType}))
                }                
            }
        } else {
            setError("L'id del suport 2 no és vàlid. Tanqueu i torneu a obrir el formulari.")
        }
    }

    const handleCancelBtnClick = () => { 
        setRidSuport2(null); 
        setImageData(null);
        dispatch(setTramT2DetailInfo(null));      
        setIsDialogOpen(false);        
    }

    useEffect(() => {
        if (isLoading) {
            setIsOpen(true);
        }
        if (isFulfilled) {
            setIsOpen(true);
            setTimeout(() => {                
                setRidSuport2(null); 
                setImageData(null);
                dispatch(setTramT2DetailInfo(null));  
                setIsOpen(false);
                setIsDialogOpen(false);   
                dispatch(setIsFulfilled(false));             
            }, 3000);
        }
        if (isRejected) {
            setIsOpen(true);
            setTimeout(() => {
                setIsOpen(false);
            }, 3000);
        }
        if (error) {
            setIsOpen(true);
            setTimeout(() => {
                setIsOpen(false);
                setError(null);
            }, 3000);
        }
    }, [dispatch, isFulfilled, isRejected, error])
    
    return(
        <Dialog open={isDialogOpen} fullScreen sx={{ height: '100vh' }}>
            <Container sx={{ marginTop: 'calc(1vw + 15px)', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', gap: 'calc(1vw + 6px)', marginBottom: bottomMarginContainer }}>
                <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, my: 2 }} noValidate autoComplete="off">
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Typography variant='subtitle1' fontWeight={'medium'}>ID ({tramDetailInfo?.id ?? ''})</Typography>
                    </Box>
                </Box>
                <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, my: 2 }} noValidate autoComplete="off">
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Typography variant='subtitle1' fontWeight={'medium'}>CM</Typography>
                        <TextField id="suport-id" variant="outlined" value={cmIdValue} contentEditable={false} sx={{ backgroundColor: 'white', marginLeft: '0 !important' }} />
                    </Box>
                </Box>
                <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, my: 2 }} noValidate autoComplete="off">
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Typography variant='subtitle1' fontWeight={'medium'}>RID Suport 1</Typography>
                        <TextField id="suport-id" variant="outlined" value={ridSuport1} contentEditable={false} sx={{ backgroundColor: 'white', marginLeft: '0 !important' }} />
                    </Box>
                </Box>
                <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, my: 2 }} noValidate autoComplete="off">
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Typography variant='subtitle1' fontWeight={'medium'}>RID Suport 2</Typography>
                        <TextField id="suport-id" type="number" variant="outlined" value={ridSuport2} onChange={(e) => setRidSuport2(parseInt(e.target.value))} sx={{ backgroundColor: 'white', marginLeft: '0 !important' }} />
                    </Box>
                </Box>
                <Box sx={{ my: 1 }}>
                    <Typography variant='subtitle1' sx={{ my: 1 }} fontWeight={'medium'} >Codi tipus tram</Typography>
                    <Select
                        labelId="cm-type-select-label"
                        id="demo-simple-select"
                        value={codtiposecValue?.toString() || ''}
                        label="cm-type"
                        onChange={handleModeloCgpSelection}
                        sx={{
                            width: '100%',
                            display: 'flex',
                            backgroundColor: 'white',
                        }}
                    >
                        {validValues
                            .filter(value => value.columnName === codtiposecCN)
                            .map((value, index) => (
                                <MenuItem key={index} value={value.key}>{`${value.key} - ${value.value}`}</MenuItem>
                            ))}
                    </Select>
                </Box>
                <CameraTramT2 picture={imageData} setPicture={setImageData} tramId={tramDetailInfo?.id ?? null} contractType={contractType}  />
                {error && (
                    <ErrorBackdropComponent error={error} isOpen={isOpen} />
                )}
                {(isLoading || isFulfilled || isRejected) && (
                    <BackdropComponent isLoading={isLoading} isFulfilled={isFulfilled} isRejected={isRejected} isOpen={isOpen} />
                )}
            </Container>
            <Box
                ref={fixedContainerRef}
                sx={{
                    width: '100%',
                    height: '7vh',
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    backgroundColor: 'white',
                    zIndex: 9999,
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px -2px 15px',
                    marginTop: 'calc(2vw + 10px)',
                }}>
                <Button onClick={handleCancelBtnClick} sx={{ fontSize: '1.2rem', color: '#9e9e9e', }} >Cancel·lar</Button>

                <Button onClick={handleSaveClick} sx={{ fontSize: '1.2rem', color: 'palette.primary.main', }} >Guardar</Button>
            </Box>
        </Dialog>
    )
}