import { baseUrl } from "../../variables";
import { ITramsT2 } from "../types";

export const fetchTramsT2Detail = async (suport1Id: number, contractType: string): Promise<ITramsT2> => {
    try {    
      const response = await fetch(baseUrl + `/${contractType}/TramsT2?ridSuport1=${suport1Id}`);
      if (!response.ok) {
        throw new Error("Error");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  }

  export const updateTramsT2Detail = async (formData: ITramsT2, contractType: string): Promise<void> => {
    try {   
      const response = await fetch(baseUrl + `/${contractType}/TramsT2/${formData.id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Update failed with status ${response.status}: ${errorText}`);
      }
    } catch (error) {
      throw error;
    }
  }

  export const insertNewTramT2 = async (formData: ITramsT2, contractType: string): Promise<void> => {
    try {
      
      const response = await fetch(baseUrl + `/${contractType}/TramsT2`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Insert failed with status ${response.status}: ${errorText}`);
      }
    } catch (error) {
      throw error;
    }
  }

  export const deleteTramImage = async (imageId: number, contractType: string): Promise<boolean> => {
    try {
      const response = await fetch(baseUrl + `/${contractType}/TramsT2/DeleteImage/${imageId}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Failed to delete image.');
      }
  
      return true; 
    } catch (error) {
      console.error(error);
      return false;
    }
  }