/** @jsxImportSource @emotion/react */
import { toggleBtnsStyle } from '../../Styles/styling-variables';
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../store/store"
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IImageDataT2, IImageT2Result, ISuportExternalConnectionT2Detail, IValidValue } from "../../Api/types";
import { Alert, Backdrop, Box, Button, CircularProgress, Container, MenuItem, Select, SelectChangeEvent, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { getValidValues } from "../../Api/ValidValues";
import { fetchSuportExtConnT2DetailThunk, setSelectedSuportExternalConnT2 } from "../../Slices/T2/suportExternalConnT2";
import DecimalInput from '../DecimalInput';
import { setIsFulfilled, updateSupportT2DetailThunk } from '../../Slices/T2/suportT2Slice';
import BackdropComponent from '../BackdropComponent';
import ErrorBackdropComponent from '../ErrorBackdropComponent';
import DateInputComponent from '../DateInputComponent';
import TableComponent, { TableColumn } from '../TableComponent';
import SuportExternalConnectionT2 from './SuportExtConnT2';
import TramT2 from './TramT2';
import { fetchTramT2DetailThunk } from '../../Slices/T2/tramT2Slice';
import CameraT2Component, { IPictureT2 } from '../Camera/CameraCmT2';
import { faultySuportCategory } from '../../variables';
import { getImages } from '../../Api/Images';
import { v4 as uuid } from 'uuid';
import { picturesT2Thunk } from '../../Slices/picturesThunk';

export default function SuportT2() {
    const { detailInfo, isLoading, isRejected, isFulfilled, suportExtConnList, empId, contractType } = useSelector((state: RootState) => ({
        detailInfo: state.suportT2.detailInfo,
        isLoading: state.suportT2.isLoading,
        isRejected: state.suportT2.isRejected,
        isFulfilled: state.suportT2.isFulfilled,
        suportExtConnList: state.suportExternalConnT2.externalConnDetailList,
        empId: state.user.empId,
        contractType: state.user.contracttype
    }));
    const dispatchThunk = useDispatch<AppDispatch>();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>(null);
    const [validValues, setValidValues] = useState<IValidValue[]>([]);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isExtConnDialogOpen, setIsExtConnDialogOpen] = useState<boolean>(false);
    const [isTramDialogOpen, setIsTramDialogOpen] = useState<boolean>(false);
    const [images, setImages] = useState<IPictureT2[] | null>(null);
    const [faultySuportPic, setFaultySuportPic] = useState<IPictureT2 | null>(null);

    const handlePictureChange = (category: string, picture: IPictureT2 | null) => {
        switch (category) {
        case faultySuportCategory:
            setFaultySuportPic(picture);
            break;
        default:
            break;
        }
    };

    //#region Variables
    const tipsote0CN = 'TIPSOPTE0';
    const tipsoteCN = 'TIPSOPTE';
    const codtipanalCN = 'CODTIPCANAL';
    const codtipopavCN = 'CODTIPOPAV';
    const codembellecedorCN = 'CODEMBELLECEDOR';
    const genericCN = 'GENERIC';
    const generic2CN = 'GENERIC2';
    const codestlimpCN = 'CODESTLIMP';
    const portellaStatusCN = 'PortellaStatus';
    const enparedCN = 'ENPARED';
    const codtomatieCN = 'CODTOMATIE';
    const secconmonCN = 'SECCONMON';
    const tensionmonCN = 'TENSIONMON';
    const etiquetaCN = 'ETIQUETA';
    const modsoporteCN = 'MODSOPORTE';
    const codmaterialCN = 'CODMATERIAL';
    const codfabCN = 'CODFAB';
    //#endregion

    const [tipsote0Value, setTipsote0Value] = useState<string | null>(detailInfo?.tipsoptE0 ?? null);
    const handleTipsote0Selection = (event: SelectChangeEvent) => {
        const value = event.target.value;
        if (value != null) {
            setTipsote0Value(value);
        } else {
            setError("Seleccioneu un valor");
        }
    }

    const [tipsoteValue, setTipsoteValue] = useState<string | null>(detailInfo?.tipsopte ?? null);
    const handleTipsoteSelection = (event: SelectChangeEvent) => {
        const value = event.target.value;
        if (value != null) {
            setTipsoteValue(value);
        } else {
            setError("Seleccioneu un valor");
        }
    }

    const [fverifiValue, setFverifiValue] = useState<Date>(new Date());
    const handleFverifiChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const dateString = e.target.value;
        const data = new Date(dateString);
        setFverifiValue(data);
    }

    const [codtipcanalValue, setCotdipcanalValue] = useState<string | null>(detailInfo?.codtipcanal ?? null);
    const handleCodtipcanalSelection = (event: SelectChangeEvent) => {
        const value = event.target.value;
        if (value != null) {
            setCotdipcanalValue(value);
        } else {
            setError("Seleccioneu un valor");
        }
    }

    const [codtipopavValue, setCottipopavValue] = useState<number | null>(detailInfo?.codtipopav ?? null);
    const handleCodtipopavSelection = (event: SelectChangeEvent) => {
        const value = event.target.value;
        if (value != null) {
            setCottipopavValue(parseInt(value));
        } else {
            setError("Seleccioneu un valor");
        }
    }

    const [codembellecedorValue, setCodembellecedorValue] = useState<string | null>(detailInfo?.codembellecedor ?? null);
    const [codembellecedorDescription, setCodembellecedorDescription] = useState<string>();
    const handleCodembellecedorSelection = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null) {
            setCodembellecedorValue(value);
            const description = getDescriptionByKey(value, codembellecedorCN);
            setCodembellecedorDescription(description);
        } else {
            setError("Seleccioneu un valor");
        }
    }

    const [codestgenValue, setCodestgenValue] = useState<number | null>(detailInfo?.codestgen ?? null);
    const [codestgenDescription, setCodestgenDescription] = useState<string>();
    const handleCodestgenSelection = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null) {
            setCodestgenValue(parseInt(value));
            const description = getDescriptionByKey(value.toString(), genericCN);
            setCodestgenDescription(description);
        } else {
            setError("Seleccioneu un valor");
        }
    }

    const [codestlimpValue, setCodestlimpValue] = useState<number | null>(detailInfo?.codestlimp ?? null);
    const handleCodestlimpSelection = (event: SelectChangeEvent) => {
        const value = event.target.value;
        if (value != null) {
            setCodestlimpValue(parseInt(value));
        } else {
            setError("Seleccioneu un valor");
        }
    }

    const [portellaStatusValue, setPortellaStatusValue] = useState<string | null>(detailInfo?.portellaStatus ?? null);
    const handlePortellaStatusSelection = (event: SelectChangeEvent) => {
        const value = event.target.value;
        if (value != null) {
            setPortellaStatusValue(value);
        } else {
            setError("Seleccioneu un valor");
        }
    }

    const [estadoCajaValue, setEstadoCajaValue] = useState<number | null>(detailInfo?.estadocaja ?? null);
    const [estadoCajaDescription, setEstadoCajaDescription] = useState<string>();
    const handleEstadoCajaSelection = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null) {
            setEstadoCajaValue(parseInt(value));
            const description = getDescriptionByKey(value.toString(), generic2CN);
            setEstadoCajaDescription(description);
        } else {
            setError("Seleccioneu un valor");
        }
    }

    const [calibradofusValue, setCalibradofusValue] = useState<number | null>(detailInfo?.calibradofus ?? null);
    const [calibradofusDescription, setCalibradofusDescription] = useState<string>();
    const handleCalibradofusSelection = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null) {
            setCalibradofusValue(parseInt(value));
            const description = getDescriptionByKey(value.toString(), generic2CN);
            setCalibradofusDescription(description);
        } else {
            setError("Seleccioneu un valor");
        }
    }

    const [estadoEquiposValue, setEstadoEquiposValue] = useState<number | null>(detailInfo?.estadoequipos ?? null);
    const [estadoEquiposDescription, setEstadoEquiposDescription] = useState<string>();
    const handleEstadoEquiposSelection = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null) {
            setEstadoEquiposValue(parseInt(value));
            const description = getDescriptionByKey(value.toString(), generic2CN);
            setEstadoEquiposDescription(description);
        } else {
            setError("Seleccioneu un valor");
        }
    }

    const [codestlumValue, setCodestlumValue] = useState<number | null>(detailInfo?.codestlum ?? null);
    const [codestlumDescription, setCodestlumDescription] = useState<string>();
    const handleCodestlumSelection = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null) {
            setCodestlumValue(parseInt(value));
            const description = getDescriptionByKey(value.toString(), genericCN);
            setCodestlumDescription(description);
        } else {
            setError("Seleccioneu un valor");
        }
    }

    const [enparedValue, setEnparedValue] = useState<number | null>(detailInfo?.enpared ?? null);
    const [enparedDescription, setEnparedDescription] = useState<string>();
    const handleEnparedSelection = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null) {
            setEnparedValue(parseInt(value));
            const description = getDescriptionByKey(value.toString(), enparedCN);
            setEnparedDescription(description);
        } else {
            setError("Seleccioneu un valor");
        }
    }

    const [codtomatieValue, setCodtomatieValue] = useState<string | null>(detailInfo?.codtomatie ?? null);
    const [codtomatieDescription, setCodtomatieDescription] = useState<string>();
    const handleCodtomatieSelection = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null) {
            setCodtomatieValue(value);
            const description = getDescriptionByKey(value, codtomatieCN);
            setCodtomatieDescription(description);
        } else {
            setError("Seleccioneu un valor");
        }
    }

    const [estadotierraValue, setEstadotierraValue] = useState<number | null>(detailInfo?.estadotierra ?? null);
    const [estadotierraDescription, setEstadotierraDescription] = useState<string>();
    const handleEstadotierraSelection = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null) {
            setEstadotierraValue(parseInt(value));
            const description = getDescriptionByKey(value.toString(), generic2CN);
            setEstadotierraDescription(description);
        } else {
            setError("Seleccioneu un valor");
        }
    }

    const [medidatierraValue, setMedidatierraValue] = useState<number | null>(detailInfo?.medidatierra ?? null);
    const handleMedidatierraChange = (value: number) => {
        setMedidatierraValue(value);
    }

    const [secconmonValue, setSecconmonValue] = useState<number | null>(detailInfo?.secconmon ?? null);
    const [secconmonDescription, setSecconmonDescription] = useState<string>();
    const handleSecconmonSelection = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null) {
            setSecconmonValue(parseInt(value));
            const description = getDescriptionByKey(value.toString(), secconmonCN);
            setSecconmonDescription(description);
        } else {
            setError("Seleccioneu un valor");
        }
    }

    const [tensionmonValue, setTensionmonValue] = useState<string | null>(detailInfo?.tensionmon ?? null);
    const [tensionmonDescription, setTensionmonDescription] = useState<string>();
    const handleTensionmonSelection = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null) {
            setTensionmonValue(value);
            const description = getDescriptionByKey(value, tensionmonCN);
            setTensionmonDescription(description);
        } else {
            setError("Seleccioneu un valor");
        }
    }

    const [etiquetaValue, setEtiquetaValue] = useState<number | null>(detailInfo?.etiqueta ?? null);
    const [etiquetaDescription, setEtiquetaDescription] = useState<string>();
    const handleEtiquetaSelection = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null) {
            setEtiquetaValue(parseInt(value));
            const description = getDescriptionByKey(value.toString(), etiquetaCN);
            setEtiquetaDescription(description);
        } else {
            setError("Seleccioneu un valor");
        }
    }

    const [modsoporteValue, setModsoporteValue] = useState<string | null>(detailInfo?.modsoporte ?? null);
    const handleModsoporteSelection = (event: SelectChangeEvent) => {
        const value = event.target.value;
        if (value != null) {
            setModsoporteValue(value);
        } else {
            setError("Seleccioneu un valor");
        }
    }

    const [codmaterialValue, setCodmaterialValue] = useState<string | null>(detailInfo?.codmaterial ?? null);
    const handleCodmaterialSelection = (event: SelectChangeEvent) => {
        const value = event.target.value;
        if (value != null) {
            setCodmaterialValue(value);
        } else {
            setError("Seleccioneu un valor");
        }
    }

    const [codfabValue, setCodfabValue] = useState<string | null>(detailInfo?.codfab ?? null);
    const handleCodfabSelection = (event: SelectChangeEvent) => {
        const value = event.target.value;
        if (value != null) {
            setCodfabValue(value);
        } else {
            setError("Seleccioneu un valor");
        }
    }

    const [isInspected, setIsInspected] = useState<string>(detailInfo?.isInspected ?? 'N');
    const handleIsInspected = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null || value != undefined) {
            setIsInspected(value);
        } else {
            setError("Seleccioneu un valor");
        }
    }

    const [commentsValue, setCommentsValue] = useState<string | null>(detailInfo?.comments ?? '');


    useEffect(() => {
        const tableName = 'suport_t2';
        getValidValues(tableName)
            .then((values) => {
                setValidValues(values);
            })
            .catch((error) => {
                console.error(error);
            });
        if (detailInfo?.rid && contractType) {
            const suportId = detailInfo.rid;
            dispatchThunk(fetchSuportExtConnT2DetailThunk({ id: suportId, contractType }));
            fetchImages();
        }
    }, []);

    useEffect(() => {
        if (detailInfo && validValues) {
            setCodembellecedorDescription(getDescriptionByKey(detailInfo.codembellecedor || '', codembellecedorCN));
            setCodestgenDescription(getDescriptionByKey(detailInfo.codestgen?.toString() || '', genericCN));
            setEstadoCajaDescription(getDescriptionByKey(detailInfo.estadocaja?.toString() || '', generic2CN));
            setCalibradofusDescription(getDescriptionByKey(detailInfo.calibradofus?.toString() || '', generic2CN));
            setEstadoEquiposDescription(getDescriptionByKey(detailInfo.estadoequipos?.toString() || '', generic2CN));
            setCodestlumDescription(getDescriptionByKey(detailInfo.codestlum?.toString() || '', genericCN));
            setEnparedDescription(getDescriptionByKey(detailInfo.enpared?.toString() || '', enparedCN));
            setCodtomatieDescription(getDescriptionByKey(detailInfo.codtomatie || '', codtomatieCN));
            setEstadotierraDescription(getDescriptionByKey(detailInfo.estadotierra?.toString() || '', generic2CN));
            setSecconmonDescription(getDescriptionByKey(detailInfo.secconmon?.toString() || '', secconmonCN));
            setTensionmonDescription(getDescriptionByKey(detailInfo.tensionmon || '', tensionmonCN));
            setEtiquetaDescription(getDescriptionByKey(detailInfo.etiqueta?.toString() || '', etiquetaCN));
        }
    }, [detailInfo, validValues])

    const getDescriptionByKey = (key: string, columnName: string): string => {
        const selectedValue = validValues.find(value => value.key === key && value.columnName.toUpperCase() === columnName.toUpperCase());
        return selectedValue ? selectedValue.value : '';
    }
    const fetchImages = async () => {
        try {
            if(detailInfo?.rid && contractType) {
                const data = await getImages(detailInfo?.cm, contractType, detailInfo.rid) as IImageT2Result[];  
                          
                const images = data.map((img: IImageT2Result) => ({
                    id: img.id,
                    entityId: img.entityId,
                    imageType: img.imageType,    
                    relatedEntityId: img.relatedEntityId,                
                    base64: `data:image/png;base64,${img.imageDataString}`,
                    isNew: false,
                    deleted: false,
                    mapId: uuid(),
                    contractType: contractType
                }))
                setImages(images);

            }          
        } catch (error) {
          console.error('Error fetching images:', error);
        }
      };

      const convertPictureToData = (picture: IPictureT2 | null, category: string) => {
        if (picture && detailInfo?.rid && contractType) {
          const date = new Date();
          const day = date.getDate().toString().padStart(2, '0');
          const month = (date.getMonth() + 1).toString().padStart(2, '0');
          const year = date.getFullYear();
          const formattedDate = `${day}${month}${year}`;
    
          return {
            id: picture.id ?? -1,
            entityId: detailInfo.cm,
            relatedEntityId: detailInfo.rid,
            imageType: category,
            base64: picture.base64,
            imageName: `${detailInfo.rid}_${formattedDate}_${category}`,
            imageDate: new Date(),
            isNew: picture.isNew            
          };
        }
    
        return null;
      };

    const handleSaveClick = async () => {
        if (detailInfo?.rid && fverifiValue && isInspected === 'Y') {
            const updatedSuport = {
                rid: detailInfo.rid,
                cm: detailInfo.cm,
                tipsopte: tipsoteValue,
                tipsoptE0: tipsote0Value,
                district: detailInfo.district,
                liniaCM: detailInfo.liniaCM,
                fverifi: fverifiValue,
                codtipcanal: codtipcanalValue,
                codtipopav: codtipopavValue,
                codembellecedor: codembellecedorValue,
                codestgen: codestgenValue,
                codestlimp: codestlimpValue,
                portellaStatus: portellaStatusValue,
                estadocaja: estadoCajaValue,
                calibradofus: calibradofusValue,
                estadoequipos: estadoEquiposValue,
                codestlum: codestlumValue,
                enpared: enparedValue,
                codtomatie: codtomatieValue,
                estadotierra: estadotierraValue,
                medidatierra: medidatierraValue,
                secconmon: secconmonValue,
                tensionmon: tensionmonValue,
                etiqueta: etiquetaValue,
                inventariado: detailInfo?.inventariado,
                fvervis: detailInfo?.fvervis,
                finagura: detailInfo?.finagura,
                fbaja: detailInfo?.fbaja,
                modsoporte: modsoporteValue,
                codmaterial: codmaterialValue,
                codfab: codfabValue,
                isInspected: isInspected,
                empId: empId,
                comments: commentsValue
            }

            try {

                const pictureData = [
                    convertPictureToData(faultySuportPic, faultySuportCategory)                    
                  ];
                  const filteredPictureData: IImageDataT2[] = pictureData.filter((data) => data !== null) as IImageDataT2[];
                  if(filteredPictureData && contractType) {
                    await dispatchThunk(picturesT2Thunk(filteredPictureData));
                  }           



                if (updatedSuport && contractType) {
                    await dispatchThunk(updateSupportT2DetailThunk({ updatedSuport, contractType }));
                }

            } catch (error) {
                showError('Hi ha hagut un error amb les imatges. Torneu-ho a intentar.');
            }
        } else if (isInspected === 'N') {
            showError("Indiqueu que s'ha revisat el suport");
        }
        else {
            showError("Empleneu tots els camps");
        }
    }

    const showError = (message: string) => {
        setError(message);
        setIsOpen(true);
        setTimeout(() => {
            setIsOpen(false);
            setError(null);
        }, 5000);
    };

    useEffect(() => {
        if (!detailInfo?.rid) {
            navigate('/')
        }

        if(images) {
            images.forEach((img: any) => {
                switch (img.imageType) {
                  case faultySuportCategory:
                    setFaultySuportPic(img);
                    break;                  
                  default:
                    break;
                }
              }); 
        }
    }, [detailInfo?.rid, images]);    

    useEffect(() => {
        if (isLoading) {
            setIsOpen(true);
        }
        if (isFulfilled) {
            setIsOpen(true);
            setTimeout(() => {
                dispatch(setIsFulfilled(false));
                setIsOpen(false);
                navigate('/')
            }, 3000);
        }
        if (isRejected) {
            setIsOpen(true);
            setTimeout(() => {
                setIsOpen(false);
            }, 3000);
        }
    }, [dispatch, isFulfilled, isRejected]);

    

    /******************************************************* External Connection CODE ***************************************************/

    const handleExtConnRowClick = (selectedExtConn: ISuportExternalConnectionT2Detail) => {
        dispatch(setSelectedSuportExternalConnT2(selectedExtConn.id));
        setIsExtConnDialogOpen(true);
    }

    const columnsExtConnT2: TableColumn<ISuportExternalConnectionT2Detail>[] = [
        { header: 'Editar', align: 'left' },
        { header: 'Id Connexió', accessor: 'rid', align: 'left' },
        { header: 'Id Suport', accessor: 'idSuport', align: 'right' },
        { header: 'Tipus Connexió', accessor: 'tipocon', align: 'right' },
        { header: 'Estat Connexió', accessor: 'estadocon', align: 'right' },
        { header: 'Tipus Alimentació Aliena', accessor: 'codtipoalim', align: 'right' }
    ];

    /*************************************************************** TRAMS CODE *************************************************************/

    const handleTramClick = () => {
        if(detailInfo?.rid && contractType) {
            dispatchThunk(fetchTramT2DetailThunk({ suport1Id: detailInfo?.rid, contractType }));
            setIsTramDialogOpen(true);
        }        
    }

    return (
        <Container>
            <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, my: 2 }} noValidate autoComplete="off">
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Typography variant='subtitle1' fontWeight={'medium'}>RID</Typography>
                    <TextField id="suport-id" variant="outlined" value={detailInfo?.rid} contentEditable={false} sx={{ backgroundColor: 'white', marginLeft: '0 !important' }} />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Typography variant='subtitle1' fontWeight={'medium'}>CM</Typography>
                    <TextField id="suport-id" variant="outlined" value={detailInfo?.cm} contentEditable={false} sx={{ backgroundColor: 'white', marginLeft: '0 !important' }} />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Typography variant='subtitle1' fontWeight={'medium'}>Línia CM</Typography>
                    <TextField id="suport-id" variant="outlined" value={detailInfo?.liniaCM} contentEditable={false} sx={{ backgroundColor: 'white', marginLeft: '0 !important' }} />
                </Box>
                <Box sx={{ my: 1 }}>
                    <Typography variant='subtitle1' sx={{ my: 1 }} fontWeight={'medium'} >Tipus de suport</Typography>
                    <Select
                        labelId="cm-type-select-label"
                        id="demo-simple-select"
                        value={tipsote0Value || ''}
                        label="cm-type"
                        onChange={handleTipsote0Selection}
                        sx={{
                            width: '100%',
                            display: 'flex',
                            backgroundColor: 'white',

                        }}
                    >
                        {validValues
                            .filter(value => value.columnName === tipsote0CN)
                            .map((value, index) => (
                                <MenuItem key={index} value={value.key}>{`${value.key} - ${value.value}`}</MenuItem>
                            ))}
                    </Select>
                </Box>
                <Box sx={{ my: 1 }}>
                    <Typography variant='subtitle1' sx={{ my: 1 }} fontWeight={'medium'} >Subtipus de suport</Typography>
                    <Select
                        labelId="cm-type-select-label"
                        id="demo-simple-select"
                        value={tipsoteValue || ''}
                        label="cm-type"
                        onChange={handleTipsoteSelection}
                        sx={{
                            width: '100%',
                            display: 'flex',
                            backgroundColor: 'white',
                        }}
                    >
                        {validValues
                            .filter(value => value.columnName === tipsoteCN)
                            .map((value, index) => (
                                <MenuItem key={index} value={value.key}>{`${value.key} - ${value.value}`}</MenuItem>
                            ))}
                    </Select>
                </Box>
                <DateInputComponent description='Data verificació' date={fverifiValue} handleDateChange={handleFverifiChange} />
                <Box sx={{ my: 1 }}>
                    <Typography variant='subtitle1' sx={{ my: 1 }} fontWeight={'medium'} >Tipus d'alimentació</Typography>
                    <Select
                        labelId="cm-type-select-label"
                        id="demo-simple-select"
                        value={codtipcanalValue || ''}
                        label="cm-type"
                        onChange={handleCodtipcanalSelection}
                        sx={{
                            width: '100%',
                            display: 'flex',
                            backgroundColor: 'white',
                        }}
                    >
                        {validValues
                            .filter(value => value.columnName === codtipanalCN)
                            .map((value, index) => (
                                <MenuItem key={index} value={value.key}>{`${value.key} - ${value.value}`}</MenuItem>
                            ))}
                    </Select>
                </Box>
                <Box sx={{ my: 1 }}>
                    <Typography variant='subtitle1' sx={{ my: 1 }} fontWeight={'medium'} >Tipus paviment</Typography>
                    <Select
                        labelId="cm-type-select-label"
                        id="demo-simple-select"
                        value={codtipopavValue?.toString() || ''}
                        label="cm-type"
                        onChange={handleCodtipopavSelection}
                        sx={{
                            width: '100%',
                            display: 'flex',
                            backgroundColor: 'white',
                        }}
                    >
                        {validValues
                            .filter(value => value.columnName === codtipopavCN)
                            .map((value, index) => (
                                <MenuItem key={index} value={value.key}>{`${value.key} - ${value.value}`}</MenuItem>
                            ))}
                    </Select>
                </Box>
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Embellidor({codembellecedorDescription})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={codembellecedorValue}
                            exclusive
                            onChange={handleCodembellecedorSelection}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === codembellecedorCN)
                            .map((value, index) => (
                                <ToggleButton key={index} value={value.key} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Estat suport({codestgenDescription})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={codestgenValue}
                            exclusive
                            onChange={handleCodestgenSelection}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === genericCN)
                            .map((value, index) => (
                                <ToggleButton key={index} value={parseInt(value.key)} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>
                <Box sx={{ my: 1 }}>
                    <Typography variant='subtitle1' sx={{ my: 1 }} fontWeight={'medium'} >Estat neteja</Typography>
                    <Select
                        labelId="cm-type-select-label"
                        id="demo-simple-select"
                        value={codestlimpValue?.toString() || ''}
                        label="cm-type"
                        onChange={handleCodestlimpSelection}
                        sx={{
                            width: '100%',
                            display: 'flex',
                            backgroundColor: 'white',
                        }}
                    >
                        {validValues
                            .filter(value => value.columnName === codestlimpCN)
                            .map((value, index) => (
                                <MenuItem key={index} value={value.key}>{`${value.key} - ${value.value}`}</MenuItem>
                            ))}
                    </Select>
                </Box>
                <Box sx={{ my: 1 }}>
                    <Typography variant='subtitle1' sx={{ my: 1 }} fontWeight={'medium'} >Estat portella</Typography>
                    <Select
                        labelId="cm-type-select-label"
                        id="demo-simple-select"
                        value={portellaStatusValue || ''}
                        label="cm-type"
                        onChange={handlePortellaStatusSelection}
                        sx={{
                            width: '100%',
                            display: 'flex',
                            backgroundColor: 'white',
                        }}
                    >
                        {validValues
                            .filter(value => value.columnName === portellaStatusCN)
                            .map((value, index) => (
                                <MenuItem key={index} value={value.key}>{`${value.key} - ${value.value}`}</MenuItem>
                            ))}
                    </Select>
                </Box>
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Estat caixa protecció i deriva({estadoCajaDescription})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={estadoCajaValue}
                            exclusive
                            onChange={handleEstadoCajaSelection}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === generic2CN)
                            .map((value, index) => (
                                <ToggleButton key={index} value={parseInt(value.key)} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Calibrat fusibles({calibradofusDescription})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={calibradofusValue}
                            exclusive
                            onChange={handleCalibradofusSelection}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === generic2CN)
                            .map((value, index) => (
                                <ToggleButton key={index} value={parseInt(value.key)} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Estat equips({estadoEquiposDescription})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={estadoEquiposValue}
                            exclusive
                            onChange={handleEstadoEquiposSelection}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === generic2CN)
                            .map((value, index) => (
                                <ToggleButton key={index} value={parseInt(value.key)} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Estat lluminària({codestlumDescription})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={codestlumValue}
                            exclusive
                            onChange={handleCodestlumSelection}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === genericCN)
                            .map((value, index) => (
                                <ToggleButton key={index} value={parseInt(value.key)} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Fanals a paret({enparedDescription})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={enparedValue}
                            exclusive
                            onChange={handleEnparedSelection}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === enparedCN)
                            .map((value, index) => (
                                <ToggleButton key={index} value={parseInt(value.key)} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Tipus de connexió a terra({codtomatieDescription})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={codtomatieValue}
                            exclusive
                            onChange={handleCodtomatieSelection}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === codtomatieCN)
                            .map(value => (
                                <ToggleButton key={value.key} value={value.key} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Estat unió cable de terra al suport({estadotierraDescription})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={estadotierraValue}
                            exclusive
                            onChange={handleEstadotierraSelection}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === generic2CN)
                            .map((value, index) => (
                                <ToggleButton key={index} value={parseInt(value.key)} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>
                <DecimalInput labelId='medidatierra' labelText='Mesura de terra' initialValue={medidatierraValue} onChange={handleMedidatierraChange} />
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Nombre i secció de conductors({secconmonDescription})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={secconmonValue}
                            exclusive
                            onChange={handleSecconmonSelection}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === secconmonCN)
                            .map((value, index) => (
                                <ToggleButton key={index} value={parseInt(value.key)} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Tensió assignada del muntant({tensionmonDescription})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={tensionmonValue}
                            exclusive
                            onChange={handleTensionmonSelection}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === tensionmonCN)
                            .map((value, index) => (
                                <ToggleButton key={index} value={value.key} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Etiqueta inventari({etiquetaDescription})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={etiquetaValue}
                            exclusive
                            onChange={handleEtiquetaSelection}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === etiquetaCN)
                            .map((value, index) => (
                                <ToggleButton key={index} value={parseInt(value.key)} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>
                <Box sx={{ my: 1 }}>
                    <Typography variant='subtitle1' sx={{ my: 1 }} fontWeight={'medium'} >Model suport</Typography>
                    <Select
                        labelId="cm-type-select-label"
                        id="demo-simple-select"
                        value={modsoporteValue || ''}
                        label="cm-type"
                        onChange={handleModsoporteSelection}
                        sx={{
                            width: '100%',
                            display: 'flex',
                            backgroundColor: 'white',

                        }}
                    >
                        {validValues
                            .filter(value => value.columnName === modsoporteCN)
                            .map((value, index) => (
                                <MenuItem key={index} value={value.key}>{`${value.key} - ${value.value}`}</MenuItem>
                            ))}
                    </Select>
                </Box>
                <Box sx={{ my: 1 }}>
                    <Typography variant='subtitle1' sx={{ my: 1 }} fontWeight={'medium'} >Material suport</Typography>
                    <Select
                        labelId="cm-type-select-label"
                        id="demo-simple-select"
                        value={codmaterialValue || ''}
                        label="cm-type"
                        onChange={handleCodmaterialSelection}
                        sx={{
                            width: '100%',
                            display: 'flex',
                            backgroundColor: 'white',

                        }}
                    >
                        {validValues
                            .filter(value => value.columnName === codmaterialCN)
                            .map((value, index) => (
                                <MenuItem key={index} value={value.key}>{`${value.key} - ${value.value}`}</MenuItem>
                            ))}
                    </Select>
                </Box>
                <Box sx={{ my: 1 }}>
                    <Typography variant='subtitle1' sx={{ my: 1 }} fontWeight={'medium'} >Fabricant suport</Typography>
                    <Select
                        labelId="cm-type-select-label"
                        id="demo-simple-select"
                        value={codfabValue || ''}
                        label="cm-type"
                        onChange={handleCodfabSelection}
                        sx={{
                            width: '100%',
                            display: 'flex',
                            backgroundColor: 'white',

                        }}
                    >
                        {validValues
                            .filter(value => value.columnName === codfabCN)
                            .map((value, index) => (
                                <MenuItem key={index} value={value.key}>{`${value.key} - ${value.value}`}</MenuItem>
                            ))}
                    </Select>
                </Box>
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Revisat</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={isInspected}
                            exclusive
                            onChange={handleIsInspected}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        >  <ToggleButton value='N' aria-label='notInspected' css={toggleBtnsStyle}>
                                <Typography variant="subtitle1">{'No revisat'}</Typography>
                            </ToggleButton>
                            <ToggleButton value='Y' aria-label='inspected' css={toggleBtnsStyle}>
                                <Typography variant="subtitle1">{'Revisat'}</Typography>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    )}
                </Box>
                <Box sx={{display: 'flex', flexDirection:'column', justifyContent: 'space-between'}}>                    
                    <Typography variant='subtitle1' fontWeight={'medium'}>Observacions</Typography>
                    <TextField id="suport-id" variant="outlined" value={commentsValue} onChange={(e: any) => setCommentsValue(e.target.value)} sx={{backgroundColor: 'white', marginLeft:'0 !important', width: '100% !important'}}  />
                </Box>
                {suportExtConnList ? (
                    <Box width={'100%'} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start' }}>
                        <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Connexions alienes</Typography>
                        <TableComponent dataList={suportExtConnList} columns={columnsExtConnT2} handleRowClick={handleExtConnRowClick} />
                    </Box>
                ) : (
                    <Box width={'100%'} sx={{ mt: 3, border: '2px solid #1565c0', borderRadius: '8px', paddingTop: 2, paddingBottom: 2, paddingLeft: 2 }}>
                        <Typography variant="body1" fontWeight={'medium'}>Aquest suport no té Connexions alienes</Typography>
                    </Box>
                )}
                <SuportExternalConnectionT2 isDialogOpen={isExtConnDialogOpen} setIsDialogOpen={setIsExtConnDialogOpen} />
                <Box sx={{ my: 3, border: '2px solid #1565c0', borderRadius: '8px', paddingTop: 2, paddingBottom: 2, paddingLeft: 2 }}>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Secció Trams</Typography>
                    <Button variant='contained' onClick={handleTramClick} sx={{ my: 1 }}>Afegir tram aeri</Button>
                </Box>
                <TramT2 isDialogOpen={isTramDialogOpen} setIsDialogOpen={setIsTramDialogOpen} cmId={detailInfo?.cm ?? -1} suport1Id={detailInfo?.rid ?? -1} />
                <CameraT2Component title='Suport malestat' category={faultySuportCategory} existingPicture={faultySuportPic} onPictureChange={handlePictureChange} contractType={contractType} />
                <Box width={'100%'} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button variant='contained' onClick={handleSaveClick} sx={{ marginY: 3, width: '50%', backgroundColor: 'secondary.main', paddingY: '15px', fontSize: '1.2rem' }}>Guardar</Button>
                </Box>
                {error && (
                    <ErrorBackdropComponent error={error} isOpen={isOpen} />
                )}
                {(isLoading || isFulfilled || isRejected) && (
                    <BackdropComponent isLoading={isLoading} isFulfilled={isFulfilled} isRejected={isRejected} isOpen={isOpen} />
                )}
            </Box>
        </Container>
    )
} 