import { baseUrl } from "../../variables";
import { ICircuitT1plusDetail } from "../types";


export const fetchCircuitDetail = async (cmId: number, contractType: string): Promise<ICircuitT1plusDetail[]> => {
  try {
    const response = await fetch(baseUrl + `/${contractType}/CircuitsPlus?cmId=${cmId}`);
    if (!response.ok) {
      throw new Error("Error");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}

export const updateCircuitDetail = async (formData: ICircuitT1plusDetail, contractType: string): Promise<boolean> => {
  try {
    const apiForm = {
      rid: formData.rid,
      fvervis: formData.fvervis,
      respuestaDiferencial: formData.respuestaDiferencial,
      medidaDiferencial: formData.medidaDiferencial,
      aislamiento: formData.aislamiento,
      circuitoActivo: formData.circuitoActivo,
      isInspected: formData.isInspected,
      empId: formData.empId,
      sormon: formData.sormon,
      xcirafereg: formData.xcirafereg
      
    }
    const response = await fetch(baseUrl + `/${contractType}/CircuitsPlus/${formData.rid}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(apiForm)
    });

    if (!response.ok) {
      console.log("error");
      return false;
    }
    return response.ok;

  } catch (error) {
    console.error(error);
    return false;
  }
}


export const insertNewCircuit = async (formData: ICircuitT1plusDetail, contractType: string): Promise<boolean> => {
  try {
    
    const response = await fetch(baseUrl + `/${contractType}/CircuitsPlus`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    });
    if(response.status === 200) {
      return true;
    } else if(response.status === 400) {
      return false;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
}
