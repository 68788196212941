import { css } from "@emotion/react";
import { blue50, blue500 } from "./color-variables";

export const toggleBtnsStyle = css({
  color: '#1976d2',
  outline: '1px solid #1976d2',
  border: 0,
  marginTop: 'calc( 1vw + 5px )',
  marginBottom: 'calc( 1vw + 5px)',
  flex: 1,
  textAlign: 'center',
  '&.MuiToggleButton-root': {
    justifyContent: 'center',
    padding: '10px',
  },
  backgroundColor: blue50,
  '&.Mui-selected': {
    color: '#ffffff',
    backgroundColor: blue500,
  },
  '&.Mui-selected:hover': {
    color: '#ffffff',
    backgroundColor: blue500
  }

})


