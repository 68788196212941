import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ICmExternalConnectionT2Detail } from '../../Api/types';
import { AppDispatch, RootState } from '../../store/store';
import { fetchCmExtConnT2Detail, updateCmExtConnT2Detail } from '../../Api/T2/CmExternalConnT2';
import { AnyAction } from 'redux'; 
import { useDispatch } from 'react-redux';


interface CmExternalConnState {
    externalConnDetailList: ICmExternalConnectionT2Detail[] | null;
    selectedExternalConn: ICmExternalConnectionT2Detail | null;
    isLoading: boolean;
    isFulfilled: boolean;
    isRejected: boolean;
}

const initialState : CmExternalConnState = {
    externalConnDetailList: null,
    selectedExternalConn: null,
    isLoading: false,
    isFulfilled: false,
    isRejected: false
}

export const fetchCmExtConnT2DetailThunk = createAsyncThunk(
    'externalConnT2/fetchCmExtConnT2',
    async (extConn: { id: number, contractType: string }, thunkAPI) => {
        try {
            const data = await fetchCmExtConnT2Detail(extConn.id, extConn.contractType);
            thunkAPI.dispatch(setCmExternalConnT2DetailList(data));
        } catch (error) {
            return thunkAPI.rejectWithValue('Error retrieving circuit.');
        }
    }
);

export const updateCmExtConnT2Thunk = createAsyncThunk(
    'externalConnT2/updateExtConnT2',
    async (extConnObj: { updatedCmExtConn: ICmExternalConnectionT2Detail, contractType: string }, thunkAPI) => {
        try {
            await updateCmExtConnT2Detail(extConnObj.updatedCmExtConn, extConnObj.contractType);
            return extConnObj.updatedCmExtConn;
        } catch (error) {
            return thunkAPI.rejectWithValue('Error updating circuit.');
        }
    }
);

const cmExternalConnSlice = createSlice({
    name: 'externalConnT2',
    initialState,
    reducers: {
        setCmExternalConnT2DetailList: (state, action: PayloadAction<ICmExternalConnectionT2Detail[] | null>) => {
            state.externalConnDetailList = action.payload;
        },
        setSelectedCmExternalConnT2: (state, action: PayloadAction<number | null>) => {
            if(state.externalConnDetailList) {
                const selectedExternalConn = state.externalConnDetailList.find(conn => conn.id === action.payload);
                state.selectedExternalConn = selectedExternalConn || null;
            }
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsFulfilled: (state, action: PayloadAction<boolean>) => {
            state.isFulfilled = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateCmExtConnT2Thunk.pending, (state) => {
                state.isLoading = true;
                state.isFulfilled = false;
                state.isRejected = false;
            })
            .addCase(updateCmExtConnT2Thunk.fulfilled, (state, action: PayloadAction<ICmExternalConnectionT2Detail>) => {
                const updatedExtConn = action.payload;
                const index = state.externalConnDetailList?.findIndex((conn) => conn.id === updatedExtConn.id);
                if (state.externalConnDetailList && index !== undefined && index !== -1) {
                    state.externalConnDetailList[index] = updatedExtConn;
                }
                state.isLoading = false;
                state.isFulfilled = true;
                state.isRejected = false;
            })
            .addCase(updateCmExtConnT2Thunk.rejected, (state) => {
                state.isLoading = false;
                state.isFulfilled = false;
                state.isRejected = true;
            })
    }
})


export const { setCmExternalConnT2DetailList, setSelectedCmExternalConnT2, setIsLoading, setIsFulfilled } = cmExternalConnSlice.actions;

export default cmExternalConnSlice.reducer;