import React from 'react';
import Main from './Layout/Main';
import Box from '@mui/material/Box';


function App() {

  return (
    <Box sx={{ display: 'flex',  minHeight:'100vh' }}>  
      <Main />
    </Box>
  );
}

export default App;
