import { baseUrl } from "../../variables";
import { ISuportExternalConnectionT2Detail } from "../types";


export const fetchSuportExtConnT2Detail = async (id: number, contractType: string): Promise<ISuportExternalConnectionT2Detail[] | null> => {
    try {              
        const response = await fetch(baseUrl + `/${contractType}/SuportExternalConnectionT2?id=${id}`);
        if(!response.ok) {
            return null;
        }
        const data = await response.json();
        return data;

    } catch (error) {
        throw error;
    }
}

export const updateSuportExtConnT2Detail = async(formData: ISuportExternalConnectionT2Detail, contractType: string): Promise<void> => {
    try {
        const response = await fetch(baseUrl + `/${contractType}/SuportExternalConnectionT2/${formData.id}`, {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(formData)
        });
        if (response.status !== 204) {
            throw new Error(`Request failed with status ${response.status}`);
        }

    } catch (error) {
        throw error;
    }
}