import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { IImageDataT1Plus, IImageDataT2 } from '../Api/types';
import { insertImagesT1Plus, deleteImage, insertImagesT2 } from '../Api/Images';

  export const picturesT1PlusThunk = createAsyncThunk(
    't1Plus/updatePictures',
    async (pictureData: IImageDataT1Plus[], thunkAPI) => {
      try {
        
        const newImages = pictureData.filter((picture) => picture.isNew === true);
        const insertPromises = newImages.map((picture) => insertImagesT1Plus(picture));
        await Promise.all(insertPromises);  
        return 'Images updated successfully';
      } catch (error) {
        throw error;
      }
    }
  );


  export const picturesT2Thunk = createAsyncThunk(
    't2/updatePictures',
    async (pictureData: IImageDataT2[], thunkAPI) => {
      try {
        
        const newImages = pictureData.filter((picture) => picture.isNew === true);
        const insertPromises = newImages.map((picture) => insertImagesT2(picture));
        await Promise.all(insertPromises);  
        return 'Images updated successfully';
      } catch (error) {
        throw error;
      }
    }
  );