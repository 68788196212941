import {IUser, jwtDecoded} from '../Api/types';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import {fetchUser} from '../Api/Users';
import {RootState} from '../store/store';
import Cookies from 'js-cookie';


interface UserState {
  empId: number | null;
  username: string | null;
  bearerToken: string | null;
  isLoggedIn: boolean;
  bearerTokenExp?: number;
  contracttype: string | null;

}

const initialState: UserState = {
  empId: null,
  username: null,
  bearerToken: null,
  isLoggedIn: false,
  bearerTokenExp: undefined,
  contracttype: null
}

export const getUserThunk = createAsyncThunk(
  'user/getUser',
  async (userinfo: {username: string, password: string, contractType: string}, thunkAPI) => {
    try {
      const response = await fetchUser(userinfo);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
)


const userSlice = createSlice({
  name : 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.empId = action.payload.empId;
      state.bearerToken = action.payload.bearerToken;
      state.username = action.payload.username;
      state.isLoggedIn = action.payload.isLoggedIn;
      state.bearerTokenExp = action.payload.bearerTokenExp;
      state.contracttype = action.payload.contracttype;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserThunk.fulfilled, (state, action) => {
        if (action.payload && action.payload.bearerToken) {
          const jwt = jwtDecode(action.payload.bearerToken || '') as jwtDecoded;
          const user: IUser = {
            empId: action.payload.empId,
            username: action.payload.username,
            bearerToken: action.payload.bearerToken,
            bearerTokenExp: jwt.exp,
            isLoggedIn: true,
            contracttype: jwt.contracttype
          }
          state.bearerTokenExp = user.bearerTokenExp;
          state.isLoggedIn = user.isLoggedIn;
          state.bearerToken = user.bearerToken;
          state.contracttype = user.contracttype;

          Cookies.set('user', JSON.stringify(user));
        }
      })
  }
});

export const {setUser} = userSlice.actions;
export const userState = (state: RootState) => state.user;
export default userSlice.reducer;