import Box from '@mui/material/Box';
import BottomNavBar from './BottomNavBar';
import Map from '../Pages/MapPage';
import { Route, Routes } from "react-router-dom";
import List from '../Components/List/List';
import TopNavBar from './TopNavBar';
import { useState, useEffect } from 'react';
import SuportsT1plus from '../Components/T1 Forms/SuportsT1plus';
import Qct1plus from '../Components/T1 Forms/Qct1plus';
import {LoginForm} from '../Components/LogIn/LogIn';
import Cookies from 'js-cookie';
import {IUser} from '../Api/types';
import {useDispatch, useSelector} from 'react-redux';
import {setUser} from '../Slices/userSlice';
import {RootState} from '../store/store';
import { T1plus, T2 } from '../variables';
import QcT2 from '../Components/T2 Forms/QcT2';
import SuportT2 from '../Components/T2 Forms/SuportT2';

export default function Main() {
  const [bottomNavBarHeight, setBottomNavBarHeight] = useState(0);
  const [topNavBarHeight, setTopNavBarHeight] = useState(0);
  const userInfo = Cookies.get('user');
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state: RootState) => state.user.isLoggedIn);
  const contractType = useSelector((state: RootState) => state.user.contracttype);

  useEffect(() => {
    if (userInfo) {
      const user = JSON.parse(userInfo) as IUser;
      if (user.isLoggedIn && user.bearerTokenExp &&  new Date(user.bearerTokenExp * 1000) > new Date(Date.now())) {
        dispatch(setUser(user))
        setTimeout(() => {
          Cookies.remove('user');
          dispatch(setUser({
            empId: null,
            username: null,
            bearerToken: null,
            isLoggedIn: false,
            bearerTokenExp: undefined,
            contracttype: null,
          }))
        }, (user.bearerTokenExp * 1000) - Date.now())
      }
    }
  }, [userInfo]);

  useEffect(() => {
    const handleResize = () => {
      const bottomNavBar = document.getElementById('bottomNavBar');
      const topNavBar = document.getElementById('topNavBar');

      if (bottomNavBar) {
        setBottomNavBarHeight(bottomNavBar.offsetHeight);
      }

      if (topNavBar) {
        setTopNavBarHeight(topNavBar.offsetHeight);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const mainContentPadding = `${bottomNavBarHeight}px`;
  const mainContentMargin = `${topNavBarHeight}px`;

  let mainContent;

  if(contractType === T1plus) {
    mainContent = (
      <Routes >
        <Route path='/' element={<Map />} />
        <Route path='/list' element={<List />} />
        <Route path="/suportT1plus/:id" element={<SuportsT1plus />} />
        <Route path="/qcT1plus/:id" element={<Qct1plus />} />
      </Routes >
    )
  } else if(contractType === T2) {
    mainContent = (
      <Routes >
        <Route path='/' element={<Map />} /> 
        <Route path="/qcT2/:id" element={<QcT2 />} />
        <Route path="/suportT2/:id" element={<SuportT2 />} />
       
      </Routes >
    )
  } else {
    mainContent = <p>El contracte no és vàlid</p>
  }


    return(
        <Box id='main' width={'100%'} height={'100vh'} >
            <TopNavBar id="topNavBar" />
            
              {
                isLoggedIn ?
                  <Box sx={{flex: 1, overflow: 'auto', backgroundColor: '#f3f4f4', marginBottom: 'calc( 1vw + 5px )', paddingBottom: mainContentPadding, marginTop: mainContentMargin }}>
                    {mainContent}
                  </Box>
                :
                <Box sx={{ flex: 1, overflow: 'auto', paddingBottom: mainContentPadding, marginTop: mainContentMargin }}>
                 <Routes>
                    <Route path="/login" element={<LoginForm />} />
                  <Route path="*" element={<LoginForm />} />
                  </Routes>
                </Box>
              }
            
            {!isLoggedIn || <BottomNavBar id="bottomNavBar" />}
        </Box>
    )
}