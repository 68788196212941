import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ICircuitT1plusDetail } from '../../Api/types';
import { AppDispatch, RootState } from '../../store/store';
import { updateCircuitDetail, fetchCircuitDetail, insertNewCircuit } from '../../Api/T1plus/CircuitT1plus';
import { AnyAction } from 'redux'; 
import { useDispatch } from 'react-redux';

interface CircuitState {
    circuitDetailList: ICircuitT1plusDetail[] | null;
    selectedCircuit: ICircuitT1plusDetail | null;
    isLoading: boolean;
    isFulfilled: boolean;
    isRejected: boolean;
}

const initialState: CircuitState = {
    circuitDetailList: null,
    selectedCircuit: null,
    isLoading: false,
    isFulfilled: false,
    isRejected: false,
};

export const fetchCircuitDetailThunk = createAsyncThunk(
  'circuit/fetchCircuit',
  async(circuitObj: {circuitId: number, contractType: string}, thunkApi) => {
    try {
      const data = await fetchCircuitDetail(circuitObj.circuitId, circuitObj.contractType);
      thunkApi.dispatch(setCircuitDetailList(data));
    } catch (error) {
      
    }
  }
);

export const updateCircuitThunk = createAsyncThunk(
    'circuit/updateCircuit',
    async (circuitObj: {updatedCircuit: ICircuitT1plusDetail, contractType: string}, thunkAPI) => {
      try {
        await updateCircuitDetail(circuitObj.updatedCircuit, circuitObj.contractType);
        return circuitObj.updatedCircuit;
      } catch (error) {
        return thunkAPI.rejectWithValue('Error updating circuit.');
      }
    }
  );

  export const insertNewCircuitThunk = createAsyncThunk(
    'circuit/insertNewCircuit',
    async(circuitObj: {newCircuit: ICircuitT1plusDetail, contractType: string}, thunkAPI) => {
      try {
        const isSuccess = await insertNewCircuit(circuitObj.newCircuit, circuitObj.contractType);

        if(isSuccess) {
          thunkAPI.dispatch(addNewCircuitToDetailList(circuitObj.newCircuit));
        } else {
          throw new Error("Hi ha hagut un error");
        }        
      } catch (error) {
        
        return thunkAPI.rejectWithValue('Error updating circuit.');
      }
    }
  )

const circuitSlice = createSlice({
  name: 'circuit',
  initialState,
  reducers: {    
    setCircuitDetailList: (state, action: PayloadAction<ICircuitT1plusDetail[]>) => {
      state.circuitDetailList = action.payload;
    },
    setSelectedCircuitT1Plus: (state, action: PayloadAction<number | null>) => {
      if (state.circuitDetailList) {
        const selectedCircuit = state.circuitDetailList.find(circuit => circuit.rid === action.payload);
        state.selectedCircuit = selectedCircuit || null;
      }
    },
    addNewCircuitToDetailList: (state, action: PayloadAction<ICircuitT1plusDetail>) => {
      if(state.circuitDetailList) {
        state.circuitDetailList.push(action.payload);
      }
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsFulfilled: (state, action: PayloadAction<boolean>) => {
      state.isFulfilled = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(updateCircuitThunk.pending, (state) => {
        state.isLoading = true;
        state.isFulfilled = false;
        state.isRejected = false;
      })    
      .addCase(updateCircuitThunk.fulfilled, (state, action: PayloadAction<ICircuitT1plusDetail>) => {
        const updatedCircuit = action.payload;
        const index = state.circuitDetailList?.findIndex((circuit) => circuit.rid === updatedCircuit.rid);
        if (state.circuitDetailList && index !== undefined && index !== -1) {
          state.circuitDetailList[index] = updatedCircuit;
        }
        state.isLoading = false;
        state.isFulfilled = true;
        state.isRejected = false;
      })
      .addCase(updateCircuitThunk.rejected, (state) => {
        state.isLoading = false;
        state.isFulfilled = false;
        state.isRejected = true;
      })
      .addCase(insertNewCircuitThunk.pending, (state) => {
        state.isLoading = true;
        state.isFulfilled = false;
        state.isRejected = false;
      })
      .addCase(insertNewCircuitThunk.fulfilled, (state) => {
        state.isLoading = false;
        state.isFulfilled = true;
        state.isRejected = false;
      })
      .addCase(insertNewCircuitThunk.rejected, (state) => {
        state.isLoading = false;
        state.isFulfilled = false;
        state.isRejected = true;
      })
  },
});

export const { setCircuitDetailList, setSelectedCircuitT1Plus, addNewCircuitToDetailList, setIsFulfilled, setIsLoading } = circuitSlice.actions;

export const selectCircuitsDetailInfo = (state: RootState) => state.circuit.circuitDetailList;

export default circuitSlice.reducer;
