import { baseUrl } from "../../variables";
import { IQcT2Detail } from "../types";


export const fetchQCT2Detail = async (id: number, contract: string): Promise<IQcT2Detail> => {
    try {
        const response = await fetch(baseUrl + `/${contract}/QcT2?Id=${id}`);

        if(!response.ok) {
            throw new Error("Error");
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
 }


export const updateQcDetail = async(formData: IQcT2Detail, contractType: string): Promise<void> => {
    try {
             
        const response = await fetch(baseUrl + `/${contractType}/QC/${formData.rid}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(formData)
        });        
        
        if (response.status !== 204) {
            throw new Error(`Request failed with status ${response.status}`);
        }        
      } catch (error) {
        throw error;
      }
}


