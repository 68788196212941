import { IImageDataT1Plus, IImageDataT2, IImageT1PlusResult, IImageT2Result } from "./types";
import { baseUrl } from "../variables";

export const getImages = async (entityId: number, contractType: string, relatedEntityId?: number): Promise<IImageT1PlusResult[] | IImageT2Result[]> => {
  try {
    let url;
    if (relatedEntityId !== undefined) {
      url = baseUrl + `/${contractType}/Images/${entityId}?relatedEntityId=${relatedEntityId}`;
    } else {
      url = baseUrl + `/${contractType}/Images/${entityId}`;
    }
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Error");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const insertImagesT1Plus = async (picture: IImageDataT1Plus) => {
  try {

    const pictureData = {
      entityId: picture.entityId,
      entityType: picture.entityType,
      base64: picture.base64,
      imageName: picture.imageName,
      imageDate: picture.imageDate
    }
    const response = await fetch(baseUrl + `/T1Plus/Images`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(pictureData),
    });

    if (response.status !== 204) {
      throw new Error('Failed to insert image.');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const insertImagesT2 = async (picture: IImageDataT2) => {
  try {

    const pictureData = {
      entityId: picture.entityId,
      imageType: picture.imageType,
      base64: picture.base64,
      imageName: picture.imageName,
      imageDate: picture.imageDate,
      relatedEntityId: picture.relatedEntityId ?? null
    }
    const response = await fetch(baseUrl + `/T2/Images`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(pictureData),
    });

    if (response.status !== 204) {
      throw new Error('Failed to insert image.');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteImage = async (imageId: number, contractType: string) => {
  try {
    const response = await fetch(baseUrl + `/${contractType}/Images/${imageId}`, {
      method: 'DELETE',
    });

    if (response.status !== 204) {
      throw new Error('Failed to delete image.');
    }
  } catch (error) {
    throw error;
  }
};

