import { selectDetailInfo } from './suportT1PlusSlice';
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../../store/store';
import { IQcT1PlusDetail } from '../../Api/types';
import { updateQcDetail } from '../../Api/T1plus/Qct1plus';


interface QCState {
    detailInfo: IQcT1PlusDetail | null;
    isLoading: boolean;
    isFulfilled: boolean,
    isRejected: boolean,    
}

const initialState: QCState = {
  detailInfo: null,
  isLoading: false,
  isFulfilled: false,
  isRejected: false,  
};

export const updateQcDetailThunk = createAsyncThunk(
    'qc/updateQcDetail',
    async (qcObj: {formData: IQcT1PlusDetail, contractType: string}, thunkAPI) => {
      try {
        const updatedDetailInfo = await updateQcDetail(qcObj.formData, qcObj.contractType);   
        return updatedDetailInfo;
      } catch (error) {
        return thunkAPI.rejectWithValue('Error updating circuit.');
      }
    }
  );

  const qcSlice = createSlice({
    name:'qc',
    initialState,
    reducers: {
        setQcDetailInfo: (state, action: PayloadAction<IQcT1PlusDetail>) => {
          state.detailInfo = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
          state.isLoading = action.payload;
        },
        setIsFulfilled: (state, action: PayloadAction<boolean>) => {
          state.isFulfilled = action.payload;
        },            
      },
      extraReducers: (builder) => {
        builder
          .addCase(updateQcDetailThunk.pending, (state) => {
            state.isLoading = true;
            state.isFulfilled = false;
            state.isRejected = false;
          })
          .addCase(updateQcDetailThunk.fulfilled, (state, action: PayloadAction<IQcT1PlusDetail>) => {       
            state.detailInfo = action.payload;
            state.isLoading = false;
            state.isFulfilled = true;
            state.isRejected = false;
          })
          .addCase(updateQcDetailThunk.rejected, (state, action) => {
            state.isLoading = false;
            state.isFulfilled = false;
            state.isRejected = true;
          });
      },
  })

  export const { setQcDetailInfo, setLoading, setIsFulfilled } = qcSlice.actions;

  export const selectQcDetailInfo = (state: RootState) => state.qc.detailInfo;

  export default qcSlice.reducer;