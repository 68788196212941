import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {useState} from 'react';
import { Link } from 'react-router-dom';
import ViewListIcon from '@mui/icons-material/ViewList';

interface IProps {
  id: string
}
export default function BottomNavBar(props: IProps) {
  const [value, setValue] = useState<string>('map');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  

  return (
    <BottomNavigation
      id={props.id}
      sx={{ width: '100vw', position: 'fixed', bottom: 0, display:'flex', alignItems:'center', }}
      value={value}
      onChange={handleChange}
      
    >
      <BottomNavigationAction
        component={Link}
        to="/"
        label="Mapa"
        value="map"
        icon={<LocationOnIcon />}
      />
      <BottomNavigationAction
        component={Link}
        to="/list"
        label="Llistat"
        value="list"
        icon={<ViewListIcon />}
        disabled
      />
    </BottomNavigation>
  );
}