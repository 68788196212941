import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store/store';
import { IDistrictAndQcId } from '../Api/types';
import { fetchDistricts, fetchDistrictsAndQcId } from '../Api/Map';

interface MapState {
    selectedQcDistrict: string,
    selectedQcId: string,
    districts: string [],
    districtsAndQcId: IDistrictAndQcId[],
}

const initialState: MapState = {
    selectedQcDistrict: "",
    selectedQcId: "",
    districts: [],
    districtsAndQcId: []
}

export const fetchDistrictsThunk = createAsyncThunk(
    'map/fetchDistricts', 
    async (contractType: string) => {
    try {
      const districtList = await fetchDistricts(contractType);
      return districtList;
    } catch (error) {
        throw error;
    }
  });
  
  export const fetchDistrictsAndQcIdThunk = createAsyncThunk(
    'map/fetchDistrictsAndQcId',
    async (contractType: string) => {
      try {
        const distAndQcIdList = fetchDistrictsAndQcId(contractType);
        return distAndQcIdList;
      } catch (error) {
        throw error;
      }
    }
  );

const mapSlice = createSlice({
    name: 'map',
    initialState,
    reducers: {
        setSelectedQcDistrict: (state, action: PayloadAction<string>) => {
            state.selectedQcDistrict = action.payload;
        },
        setSelectedQcId: (state, action: PayloadAction<string>) => {
            state.selectedQcId = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchDistrictsThunk.fulfilled, (state, action) => {
            state.districts = action.payload;
        })
        .addCase(fetchDistrictsAndQcIdThunk.fulfilled, (state, action) => {
            state.districtsAndQcId = action.payload;
        })        
    }
})

export const { setSelectedQcDistrict, setSelectedQcId } = mapSlice.actions;
export default mapSlice.reducer;