import { Container, Box, Avatar, Typography, Button, TextField, Grid, Link, FormControlLabel, Checkbox, Select, MenuItem } from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '../../store/store';
import {getUserThunk} from '../../Slices/userSlice';
import {useState} from 'react';
import AuriaLogo from '../../Images/AFIL_new.png';
import { T1plus, T2 } from "../../variables";

export const LoginForm = () => {
  const dispatch = useDispatch();
  const dispatchThunk = useDispatch<AppDispatch>();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [contractType, setContractType] = useState(T1plus);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        dispatchThunk(getUserThunk({username, password, contractType}));
    }
    return(
        <Container maxWidth='xs' >
            <Box sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
                <Avatar sx={{ m: 1, bgcolor: 'primary.dark' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
            Inici sessió
          </Typography>
          <Box sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Usuari"
              name="username"
              autoFocus
              onChange={(e) => setUsername(e.target.value.toLowerCase())}
              sx={{backgroundColor: 'white'}}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Contrasenya"
              type="password"
              id="password"
              onChange={(e)=> setPassword(e.target.value.toLowerCase())}
              sx={{backgroundColor: 'white'}}
            />
            <Select
              labelId="contract-label"
              id="contract-select-id"
              value={contractType}
              label=""
              onChange={(e) => setContractType(e.target.value)}
            >
              <MenuItem value={T2}>T2</MenuItem>
              <MenuItem value={T1plus}>T1 plus</MenuItem>
            </Select>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSubmit}
            >
              Log In
            </Button>

          </Box>
            </Box>
        </Container>
    )
}