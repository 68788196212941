/** @jsxImportSource @emotion/react */
import { Box, ToggleButton, ToggleButtonGroup, Typography, Container, TextField, Button, Alert, CircularProgress, Backdrop, TableContainer } from '@mui/material';
import CameraComponent from '../Camera/CameraT1Plus';
import { useState, useEffect } from 'react';
import { toggleBtnsStyle } from '../../Styles/styling-variables';
import { getValidValues } from '../../Api/ValidValues';
import { IValidValue } from '../../Api/types';
import type { RootState } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { setIsFulfilled, updateQcDetailThunk } from '../../Slices/T1Plus/qcT1PlusSlice';
import { AppDispatch } from '../../store/store';
import { useNavigate } from 'react-router-dom';
import { getImages } from '../../Api/Images';
import { v4 as uuid } from 'uuid';
import { IImageT1PlusResult, ICircuitT1plusDetail } from '../../Api/types';
import { picturesT1PlusThunk } from '../../Slices/picturesThunk';
import TableComponent from '../TableComponent';
import CircuitsT1plus from './CircuitsT1plus';
import { fetchCircuitDetailThunk, setSelectedCircuitT1Plus } from "../../Slices/T1Plus/circuitT1PlusSlice";
import AddBoxIcon from '@mui/icons-material/AddBox';
import NewCircuitsT1plus from './NewCircuitsT1plus';
import { TableColumn } from '../TableComponent';
import ErrorBackdropComponent from '../ErrorBackdropComponent';
import BackdropComponent from '../BackdropComponent';
import DateInputComponent from '../DateInputComponent';

export default function Qct1plus() {
    //#region Variables Circuit
    const isActiveCn = 'ISACTIVE';
    //#endregion
    const [pictures, setPictures] = useState<{ id?: number; base64: string; isNew?: boolean; deleted?: boolean; mapId: string; }[]>([]);     
    const [selectedCircuit, setSelectedCircuit] = useState<ICircuitT1plusDetail>();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isNewCircuitDialogOpen, setIsNewCircuitDialogOpen] = useState(false);
    const [cenmando, setCenmando] = useState<number>(-1);
    const [isVisual, setIsVisual] = useState<boolean>(true);

    const { detailInfo, isLoading, isRejected, isFulfilled, circuitList, empId, contractType } = useSelector((state: RootState) => ({
        detailInfo: state.qc.detailInfo,
        isLoading: state.qc.isLoading,
        isRejected: state.qc.isRejected,
        isFulfilled: state.qc.isFulfilled,
        circuitList: state.circuit.circuitDetailList,
        empId: state.user.empId,
        contractType: state.user.contracttype,        
    }));

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const dispatchThunk = useDispatch<AppDispatch>();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>(null);
    const [validValues, setValidValues] = useState<IValidValue[]>([]);
    const [fvervis, setFervis] = useState<Date>(detailInfo?.fvervis ? new Date(detailInfo.fvervis) : new Date());
    const handleFvervisChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const dateString = e.target.value;
        const data = new Date(dateString);
        setFervis(data);
    }

    const [isInspected, setIsInspected] = useState<string>(detailInfo?.isInspected ?? 'N');
    const handleIsInspected = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null || value != undefined) {
            setIsInspected(value);
        } else {
            setError("Seleccioneu un valor");
        }
    }

    const [codestatcuaValue, setCodestatcuaValue] = useState<number | null>(detailInfo?.codestatcua ?? null);
    const [codestatcuaDescription, setCodestatcuaDescription] = useState<string>();
    const handleCodestatcuaSelection = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null) { 
            setCodestatcuaValue(parseInt(value));
            const description = getDescriptionByKey(value.toString());
            setCodestatcuaDescription(description);
        } else {
            setError("Seleccioneu un valor");
        }
    }    
    const [codestatcierreValue, setCodestatcierreValue] = useState<number | null>(detailInfo?.codestatcierre ?? null);
    const [codestatcierreDescription, setCodestatcierreDescription] = useState<string>();
    const handleCodestatcierreSelection = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null) { 
            setCodestatcierreValue(parseInt(value));
            const description = getDescriptionByKey(value.toString());
            setCodestatcierreDescription(description);
        } else {
            setError("Seleccioneu un valor");
        }
    }
    const [codestattapasValue, setCodestattapasValue] = useState<number | null>(detailInfo?.codestattapas ?? null);
    const [codestattapasDescription, setCodestattapasDescription] = useState<string>();
    const handleCodestattapasSelection = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null) { 
            setCodestattapasValue(parseInt(value));
            const description = getDescriptionByKey(value.toString());
            setCodestattapasDescription(description);
        } else {
            setError("Seleccioneu un valor");
        }
    }
    const [codesttubosValue, setCodesttubosValue] = useState<number | null>(detailInfo?.codesttubos ?? null);
    const [codesttubosDescription, setCodesttubosDescription] = useState<string>();
    const handleCodesttubosSelection = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null) { 
            setCodesttubosValue(parseInt(value));
            const description = getDescriptionByKey(value.toString());
            setCodesttubosDescription(description);
        } else {
            setError("Seleccioneu un valor");
        }
    }

    
 
    const [codestextValue, setCodestextValue] = useState<string | null>(detailInfo?.codestext ?? null);
    const [codestextDescription, setCodestextDescription] = useState<string>();
    const handleCodestextSelection = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null) { 
            setCodestextValue(value);
            const description = getDescriptionByKey(value);
            setCodestextDescription(description);
        } else {
            setError("Seleccioneu un valor");
        }
    }

    
       /*APM*/
    const [protecstValue, setProtecstValue] = useState<string | null>(detailInfo?.protecst ?? null);
    const [protecstDescription, setProtecsDescription] = useState<string>();
    const handleProtecstSelection = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null) { 
            setProtecstValue(value);
            const description = getDescriptionByKey(value);
            setProtecsDescription(description);
        } else {
            setError("Seleccioneu un valor");
        }
    }

    const [xtiposaiValue, setXtiposaiValue] = useState<string | null>(detailInfo?.xtiposai ?? null);
    const [xtiposaiDescription, setXtiposaiDescription] = useState<string>();
    const handleXtiposaiSelection = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null) { 
            setXtiposaiValue(value);
            const description = getDescriptionByKey(value);
            setXtiposaiDescription(description);
        } else {
            setError("Seleccioneu un valor");
        }
    }

    const [saiValue, setSaiValue] = useState<string | null>(detailInfo?.sai ?? null);
    const [saiDescription, setSaiDescription] = useState<string>();
    const handleSaiSelection = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null) { 
            setSaiValue(value);        
            const description = getDescriptionByKeyAnByColumName(value,'SAI');                       
            setSaiDescription(description);
        } else {
            setError("Seleccioneu un valor");
        }
    }
    
    const [coddispcppValue, setCoddispcppValue] = useState<string | null>(detailInfo?.coddispcpp ?? null);
    const [coddispcppDescription, setCoddispcppDescription] = useState<string>();
    const handleCoddispcppSelection = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null) { 
            setCoddispcppValue(value);
            const description = getDescriptionByKeyAnByColumName(value,'CODDISPCPP');
            setCoddispcppDescription(description);
        } else {
            setError("Seleccioneu un valor");
        }
    }

    const [modelocontA1Value, setmodelocontA1Value] = useState<string | null>(detailInfo?.modelocontA1 ?? null);
    const [modelocontA1Description, setModelocontA1Description] = useState<string>();
    const handleModelocontA1Selection = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null) { 
            setmodelocontA1Value(value);
            const description = getDescriptionByKey(value);
            setModelocontA1Description(description);
        } else {
            setError("Seleccioneu un valor");
        }
    }
        
  /*---*/

    const [codtomatieValue, setCodtomatieValue] = useState<string | null>(detailInfo?.codtomatie ?? null);
    const [codtomatieDescription, setCodtomatieDescription] = useState<string>();
    const handleCodtomatieSelection = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null) { 
            setCodtomatieValue(value);
            const description = getDescriptionByKey(value);
            setCodtomatieDescription(description);
        } else {
            setError("Seleccioneu un valor");
        }
    }

    const [tomatieval, setTomatieval] = useState<number>(detailInfo?.tomatieval ?? 0);
    const [tensioncontacto, setTensioncontacto] = useState<number>(detailInfo?.tensioncontacto ?? 0);

    const getDescriptionByKey = (key: string): string => {
        const selectedValue = validValues.find(value => value.key === key);
        return selectedValue ? selectedValue.value : '';
    } 

    const getDescriptionByKeyAnByColumName = (key: string, columnName: string): string => {
        const selectedValue = validValues.find(value => value.key === key && value.columnName.toUpperCase() === columnName.toUpperCase());
        return selectedValue ? selectedValue.value : '';
    }

    const fetchImages = async () => {
        try {
            if(detailInfo?.rid && contractType) {
                const data = await getImages(detailInfo?.rid, contractType) as IImageT1PlusResult[];                
                const image = data.map((img: IImageT1PlusResult) => ({
                    id: img.id,                    
                    base64: `data:image/png;base64,${img.imageDataString}`,
                    isNew: false,
                    deleted: false,
                    mapId: uuid(),
                    contractType: contractType
                }))
                setPictures(image);
            }          
        } catch (error) {
          // Handle the error or display an error message to the user
          console.error('Error fetching images:', error);
        }
      };

    useEffect(() => {
        if (!detailInfo?.rid) {
            navigate('/')
        }
    }, [detailInfo?.rid])

    useEffect(() => {
        const tableName = 'QCT1plus';
        getValidValues(tableName)
            .then((values) => {
                setValidValues(values);
            })
            .catch((error) => {
                console.error(error);
            });
            fetchImages();
            if(detailInfo?.rid && contractType) dispatchThunk(fetchCircuitDetailThunk({circuitId: detailInfo.rid, contractType}));
    }, []);

    

    useEffect(() => {
        if (detailInfo && validValues) { 
            setCodestatcuaDescription(getDescriptionByKey(detailInfo.codestatcua?.toString() ?? ''));
            setCodestatcierreDescription(getDescriptionByKey(detailInfo.codestatcierre?.toString() ?? '' ));
            setCodestattapasDescription(getDescriptionByKey(detailInfo.codestattapas?.toString() ?? ''));
            setCodesttubosDescription(getDescriptionByKey(detailInfo.codesttubos?.toString() ?? ''));
            setCodestextDescription(getDescriptionByKey(detailInfo.codestext ?? ''));
            setCodtomatieDescription(getDescriptionByKey(detailInfo.codtomatie ?? ''));
            setProtecsDescription(getDescriptionByKey(detailInfo.protecst ?? '')); 
            setXtiposaiDescription(getDescriptionByKey(detailInfo.xtiposai ?? ''));
            setSaiDescription(getDescriptionByKeyAnByColumName(detailInfo.sai || '', 'SAI'));
            setCoddispcppDescription(getDescriptionByKeyAnByColumName(detailInfo.coddispcpp || '' ,'CODDISPCPP'));
            setModelocontA1Description(getDescriptionByKey(detailInfo.modelocontA1 ?? ''));
        }
    }, [detailInfo, validValues])

    useEffect(() => {
        if (isLoading) {
            setIsOpen(true);
        }
        if (isFulfilled) {
            setIsOpen(true);
            setTimeout(() => {
                dispatch(setIsFulfilled(false));
                setIsOpen(false);
                navigate('/')
            }, 3000);
        }
        if (isRejected) {
            setIsOpen(true);
            setTimeout(() => {
                setIsOpen(false);
            }, 3000);
        }
        
    }, [dispatch, isFulfilled, isRejected])

    /* ERROR AND OTHER STATE HANDLING FUNCTIONS */
    const showError = (message: string) => {     
        setError(message);
        setIsOpen(true);
        setTimeout(() => {
            setIsOpen(false);
            setError(null);
        }, 5000);
    }; 

    const handleNewCircuit = () => {
        if(detailInfo?.rid){
            setCenmando(detailInfo.rid); 
            setIsNewCircuitDialogOpen(true);
        }        
    }

    const handleRowClick = (circuit: ICircuitT1plusDetail) => {
        dispatch(setSelectedCircuitT1Plus(circuit.rid));
        setIsDialogOpen(true);
    }

    const handleOnSaveClick = async () => {
        if(detailInfo?.rid && fvervis && codestatcuaValue && codestatcierreValue && codestattapasValue && codesttubosValue && codestextValue && codtomatieValue && protecstValue && xtiposaiValue && saiValue && coddispcppValue && modelocontA1Value && isInspected ==='Y') {
            console.log ( coddispcppValue );
            const formData = {
                rid: detailInfo.rid,
                fvervis: fvervis,
                codestatcua: codestatcuaValue,
                codestatcierre: codestatcierreValue,
                codestattapas: codestattapasValue,
                codesttubos: codesttubosValue,
                codestext: codestextValue,
                codtomatie: codtomatieValue,
                tomatieval: tomatieval,
                tensioncontacto: tensioncontacto,
                protecst:protecstValue,
                xtiposai:xtiposaiValue,
                sai:saiValue,
                coddispcpp: coddispcppValue,
                modelocontA1: modelocontA1Value,
                isInspected: isInspected,
                empId: empId
            }
            try {                
                const pictureData = pictures.map((picture, index) => {
                const date = new Date();
                const day = date.getDate().toString().padStart(2, '0');
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const year = date.getFullYear();
                const formattedDate = `${day}${month}${year}`;
                return {
                    id: picture.id ?? -1,
                    entityId: detailInfo.rid,
                    entityType: 'Qct1plus',
                    base64: picture.base64,
                    imageName: `${detailInfo.rid}_${formattedDate}_${index}`,
                    imageDate: new Date(),
                    isNew: picture.isNew                    
                  }
                });

                if (contractType) {
                    await dispatchThunk(picturesT1PlusThunk(pictureData));
                }

                if (formData && contractType) {
                    await dispatchThunk(updateQcDetailThunk({ formData, contractType }));
                }
            } catch (error) {
                showError('Hi ha hagut un error amb les imatges. Torneu-ho a intentar.');
            }
        } else if(isInspected ==='N') {
            showError("Indiqueu que s'ha revisat el quadre");
        }
        else {
            showError("Empleneu tots els camps");
        }
    }

    const columnsT1plus: TableColumn<ICircuitT1plusDetail>[] = [
        { header: 'Editar', align: 'left'},
        { header: 'ID Circuit', accessor: 'rid', align: 'left' },
        { header: 'CM', accessor: 'cenmando', align: 'right' },
        { header: 'Nom circuit', accessor: 'circuitName', align: 'right' },
        { header: 'Circuit Actiu', accessor: 'circuitoActivo', align: 'right', format: (value: boolean) => (value != null ? (value ? 'Si' : 'No') : '')},
        { header: 'T.resposta diferencial', accessor: 'respuestaDiferencial', align: 'right'},
        { header: 'M.sensibilitat diferencial', accessor: 'medidaDiferencial', align: 'right'},
       // { header: 'Aïllament', accessor: 'aislamiento', align: 'right'},
        { header: 'Sort.Monofàsica', accessor:'sormon',align: 'right', format: (value: boolean) => (value != null ? (value ? 'Si' : 'No') : '')},
        { header: 'Sort.Regulació', accessor:'sormon',align: 'right', format: (value: boolean) => (value != null ? (value ? 'Si' : 'No') : '')}
      ];

    return (        
        <Container>
            <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, my: 2 }} noValidate autoComplete="off">
                <Box sx={{display: 'flex', flexDirection:'column', justifyContent: 'space-between'}}>
                    <Typography variant='subtitle1' fontWeight={'medium'}>RID</Typography>
                    <TextField id="suport-id" variant="outlined" value={detailInfo?.rid} contentEditable={false} sx={{backgroundColor: 'white', marginLeft:'0 !important'}}  />
                </Box>
                <DateInputComponent description='Data verificació visual' date={fvervis} handleDateChange={handleFvervisChange} />                
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Estat quadre({codestatcuaDescription})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={codestatcuaValue}
                            exclusive
                            onChange={handleCodestatcuaSelection}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === 'CODESTATCUA')
                            .map((value, index) => (
                                <ToggleButton key={index} value={parseInt(value.key)} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Estat tancament({codestatcierreDescription})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={codestatcierreValue}
                            exclusive
                            onChange={handleCodestatcierreSelection}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === 'CODESTATCIERRE')
                            .map((value, index) => (
                                <ToggleButton key={index} value={parseInt(value.key)} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Estat tapes({codestattapasDescription})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={codestattapasValue}
                            exclusive
                            onChange={handleCodestattapasSelection}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === 'CODESTATTAPAS')
                            .map((value, index) => (
                                <ToggleButton key={index} value={parseInt(value.key)} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Estat dels tubs/ muntants({codesttubosDescription})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={codesttubosValue}
                            exclusive
                            onChange={handleCodesttubosSelection}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === 'CODESTTUBOS')
                            .map((value, index) => (
                                <ToggleButton key={index} value={parseInt(value.key)} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Estat estètica exterior({codestextDescription})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={codestextValue}
                            exclusive
                            onChange={handleCodestextSelection}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === 'CODESTEXT')
                            .map((value, index) => (
                                <ToggleButton key={index} value={value.key} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Estat instal·lació de terra({codtomatieDescription})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={codtomatieValue}
                            exclusive
                            onChange={handleCodtomatieSelection}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === 'CODTOMATIE')
                            .map((value, index) => (
                                <ToggleButton key={index} value={value.key} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>
                
             {/* APM */}

                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} > Protecció Sobretensions ({protecstDescription})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={protecstValue}
                            exclusive
                            onChange={handleProtecstSelection}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === 'PROTECST')
                            .map((value, index) => (
                                <ToggleButton key={index} value={value.key} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>

                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} > Tipus SAI ({xtiposaiDescription})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={xtiposaiValue}
                            exclusive
                            onChange={handleXtiposaiSelection}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === 'XTIPOSAI')
                            .map((value, index) => (
                                <ToggleButton key={index} value={value.key} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>

                 
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >SAI ({saiDescription})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={saiValue}
                            exclusive
                            onChange={handleSaiSelection}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === 'SAI')
                            .map((value, index) => (
                                <ToggleButton key={index} value={value.key} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>

                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Disp.Control CM ({coddispcppDescription})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={coddispcppValue}
                            exclusive
                            onChange={handleCoddispcppSelection}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === 'CODDISPCPP')
                            .map((value, index) => (
                                <ToggleButton key={index} value={value.key} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>
             


                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} > Mòdul comptador ({modelocontA1Description})</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={modelocontA1Value}
                            exclusive
                            onChange={handleModelocontA1Selection}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        > {validValues
                            .filter(value => value.columnName === 'MODELOCONTA1')
                            .map((value, index) => (
                                <ToggleButton key={index} value={value.key} aria-label={value.key} css={toggleBtnsStyle}>
                                    <Typography variant="subtitle1">{value.key}</Typography>
                                </ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    )}
                </Box>              


                <Box>
                    <Typography variant='subtitle1' fontWeight={'medium'} >TT CM</Typography>
                    <TextField id='toamtie-id' type='number' value={tomatieval} onChange={(e) => setTomatieval(parseFloat(e.target.value))}  sx={{backgroundColor: 'white', marginLeft:'0 !important'}} inputProps={{ step: '0.01' }} />
                </Box>
                <Box>
                    <Typography variant='subtitle1' fontWeight={'medium'}>Tensió contacte</Typography>
                    <TextField id='medidatierra-id' type='number' value={tensioncontacto} onChange={(e) => setTensioncontacto(parseFloat(e.target.value))}  sx={{backgroundColor: 'white', marginLeft:'0 !important'}} inputProps={{ step: '0.01' }} />
                </Box>
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Revisat</Typography>
                    {validValues && (
                        <ToggleButtonGroup
                            value={isInspected}
                            exclusive
                            onChange={handleIsInspected}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        >  <ToggleButton value='N' aria-label='notInspected' css={toggleBtnsStyle}>
                                <Typography variant="subtitle1">{'No revisat'}</Typography>
                            </ToggleButton>
                            <ToggleButton value='Y' aria-label='inspected' css={toggleBtnsStyle}>
                                <Typography variant="subtitle1">{'Revisat'}</Typography>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    )}
                </Box>
                <CameraComponent setPictures={setPictures} pictures = {pictures} contractType={contractType} />
            </Box>
            <Box width={'100%'} sx={{ display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'start' }}>
                <TableComponent dataList={circuitList} columns={columnsT1plus} handleRowClick={handleRowClick} />
                <Button variant='outlined' onClick={handleNewCircuit}><AddBoxIcon sx={{marginRight:1}} /> Afegir nou circuit</Button>
            </Box>            
            <Box width={'100%'} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button variant='contained' onClick={handleOnSaveClick} sx={{ marginY: 3, width: '50%', backgroundColor: 'secondary.main', paddingY: '15px', fontSize: '1.2rem' }}>Guardar</Button>
            </Box>
            <CircuitsT1plus isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} />
            <NewCircuitsT1plus cenmando={cenmando} isDialogOpen={isNewCircuitDialogOpen} setIsDialogOpen={setIsNewCircuitDialogOpen} />
            {error && (
                <ErrorBackdropComponent error={error} isOpen={isOpen} />
            )}
            {(isLoading || isFulfilled || isRejected) && (
                <BackdropComponent isLoading={isLoading} isFulfilled={isFulfilled} isRejected={isRejected} isOpen={isOpen} />
            )}
        </Container>
    )
}


