import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../../store/store';
import { ISuportT1PlusDetail } from '../../Api/types';
import { updateSuportDetail } from '../../Api/T1plus/SuportsT1plus';

interface SuportState {
  detailInfo: ISuportT1PlusDetail | null;
  isLoading: boolean,
  isFulfilled: boolean,
  isRejected: boolean,
}

const initialState: SuportState = {
  detailInfo: null,
  isLoading: false,
  isFulfilled: false,
  isRejected: false,

};

export const updateSupportDetailThunk = createAsyncThunk(
  'suport/updateSupportDetail',
  async (suport : {formData: ISuportT1PlusDetail, contractType: string}, thunkAPI) => {
    try {
      await updateSuportDetail(suport.formData, suport.contractType);
    } catch (error) {
      return thunkAPI.rejectWithValue('Error updating suport.');
    }
  }
);


const suportSlice = createSlice({
  name: 'suport',
  initialState,
  reducers: {
    setSuportT1PlusDetailInfo: (state, action: PayloadAction<ISuportT1PlusDetail>) => {
      state.detailInfo = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsFulfilled: (state, action: PayloadAction<boolean>) => {
      state.isFulfilled = action.payload;
    }    
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateSupportDetailThunk.pending, (state) => {
        state.isLoading = true;
        state.isFulfilled = false;
        state.isRejected = false;
      })
      .addCase(updateSupportDetailThunk.fulfilled, (state) => {       
        state.isLoading = false;
        state.isFulfilled = true;
        state.isRejected = false;
      })
      .addCase(updateSupportDetailThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.isFulfilled = false;
        state.isRejected = true;
      });
  },
});

export const { setSuportT1PlusDetailInfo, setLoading, setIsFulfilled } = suportSlice.actions;

export const selectDetailInfo = (state: RootState) => state.suport.detailInfo;

export default suportSlice.reducer;