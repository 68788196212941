import { ISuportCoordinates, ISuportT1PlusDetail, IQCCoordinates, IQcT1PlusDetail, IDistrictAndQcId } from "./types";
import { baseUrl } from "../variables";

// export const fetchSuportCoordinates = async (): Promise<ISuportCoordinates[]> => {
//     try {
//         const response = await fetch(baseUrl + '/SuportCoordinates');
//         if (!response.ok) {
//             return [];
//         }
//         const data = await response.json();
//         return data;
//     } catch (error) {
//         console.error(error);
//         return [];
//     }
// }

export const fetchQCCoordinates = async (contract: string): Promise<IQCCoordinates[]> => {
    try {
        const response = await fetch(baseUrl + `/${contract}/QCCoordinates`);
        if(!response.ok) {
            return [];
        }
        const data = await response.json();
        return data;

    } catch (error) {
        console.error(error);
        return [];
    }
}

export const fetchSuportCoord = async (idCm: number, contract: string) : Promise<ISuportCoordinates[]> => {
    try {
        const response = await fetch(baseUrl + `/${contract}/SuportCoordinates?idCM=${idCm}`);
        if (!response.ok) { 
            return []; 
        }
        const data = await response.json();
        return data;
    } catch (error) {
        return [];
    }
}


 export const fetchSuportDetail = async<T> (id: number, contract: string): Promise<T> => {
    try {
        const response = await fetch(baseUrl + `/${contract}/Suport?Id=${id}`);
        if(!response.ok) {
            throw new Error("Error");
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
 }

 export const fetchQCDetail = async <T> (id: number, contract: string): Promise<T> => {
    try {
        const response = await fetch(baseUrl + `/${contract}/QC?Id=${id}`);
  
        if(!response.ok) {
            throw new Error("Error");
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
  } 

 export const fetchDistricts = async (contract: string): Promise<string[]> => {
    try {
        const response = await fetch(baseUrl + `/${contract}/QCCoordinates/Districts`);
        if(!response.ok) {
            return [];
        }

        const data = await response.json();
        return data;

    } catch (error) {
        return [];
    }
 }

 export const fetchDistrictsAndQcId = async(contract: string) : Promise<IDistrictAndQcId[]> => {
    try {
        const response = await fetch(baseUrl + `/${contract}/QCCoordinates/DistrictsAndQcId`);
        if(!response.ok) {
            return [];
        }
        const data = await response.json();
        return data;
    } catch (error) {
        return [];
    }
 }