/** @jsxImportSource @emotion/react */
import { Alert, Backdrop, Box, Button, CircularProgress, Container, Dialog, MenuItem, Select, SelectChangeEvent, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from "../../store/store";
import { useNavigate } from "react-router-dom";
import { IValidValue } from "../../Api/types";
import { getValidValues } from "../../Api/ValidValues";
import { toggleBtnsStyle } from '../../Styles/styling-variables';
import { setIsFulfilled, updateSuportExtConnT2Thunk, setSelectedSuportExternalConnT2} from '../../Slices/T2/suportExternalConnT2';
import ErrorBackdropComponent from "../ErrorBackdropComponent";
import BackdropComponent from "../BackdropComponent";

interface IProps {
    isDialogOpen: boolean;
    setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function SuportExternalConnectionT2({isDialogOpen, setIsDialogOpen}: IProps) {
    const { suportExtConnInfo, isLoading, isRejected, isFulfilled, empId, contractType} = useSelector((state: RootState) => ({
        suportExtConnInfo: state.suportExternalConnT2.selectedExternalConn,
        isLoading: state.suportExternalConnT2.isLoading,
        isRejected: state.suportExternalConnT2.isRejected,
        isFulfilled: state.suportExternalConnT2.isFulfilled,
        empId: state.user.empId,
        contractType: state.user.contracttype
    }))
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const dispatchThunk = useDispatch<AppDispatch>();
    const dispatch = useDispatch();
    const [bottomMarginContainer, setBottomMarginContainer] = useState<string>();
    const fixedContainerRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>(null);
    const [validValues, setValidValues] = useState<IValidValue[]>([]);
    //#region  Variables
    const tipoconCN = 'TIPOCON';
    const estadoconCN = 'ESTADOCON';
    const codtipoalimCN = 'CODTIPOALIM';
    //#endregion

    const [tipoconValue, setTipoconValue] = useState<string | null>(suportExtConnInfo?.tipocon ?? null);
    const handleTipoconSelection = (event: SelectChangeEvent) => {
        const value = event.target.value;
        if (value != null) { 
            setTipoconValue(value);            
        } else {
            setError("Seleccioneu un valor");
        }
    }
    const [estadoconValue, setEstadoconValue] = useState<number | null>(suportExtConnInfo?.estadocon ?? null);
    const [estadoconDescription, setEstadoconDescription] = useState<string>();
    const handleEstadoconSelection = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null) { 
            setEstadoconValue(parseInt(value));
            const description = getDescriptionByKey(value.toString(), estadoconCN);
            setEstadoconDescription(description);
        } 
    }
    const [codtipoalimValue, setCodtipoalimValue] = useState<number | null>(suportExtConnInfo?.codtipoalim ?? null);
    const [codtipoalimDescription, setCodtipoalimDescription] = useState<string>();
    const handleCodtipoalimSelection = (e: React.ChangeEvent<any>, value: string | null) => {
        if(value != null) {
            setCodtipoalimValue(parseInt(value));
            const description = getDescriptionByKey(value.toString(), codtipoalimCN);
            setCodtipoalimDescription(description);
        } 
    }
    useEffect(() => {
        const tableName = 'connection_t2';
        getValidValues(tableName)
            .then((values) => {
                setValidValues(values);
            })
            .catch((error) => {
                console.error(error);
            });            
    }, []);

    const getDescriptionByKey = (key: string, columnName: string): string => {
        const selectedValue = validValues.find(value => value.key === key && value.columnName.toUpperCase() === columnName.toUpperCase());
        return selectedValue ? selectedValue.value : '';
    }

    useEffect(() => {
        setTipoconValue(suportExtConnInfo?.tipocon ?? null);
        setEstadoconValue(suportExtConnInfo?.estadocon ?? null);
        setCodtipoalimValue(suportExtConnInfo?.codtipoalim ?? null);
        if(validValues) {
            setEstadoconDescription(getDescriptionByKey(suportExtConnInfo?.estadocon?.toString() || '', estadoconCN));
            setCodtipoalimDescription(getDescriptionByKey(suportExtConnInfo?.codtipoalim?.toString() || '', codtipoalimCN));
        }

        if (!suportExtConnInfo?.id) {
            setIsDialogOpen(false);
        }
    }, [suportExtConnInfo, validValues])

    useEffect(() => {
        if (fixedContainerRef.current) {
            const fixedContainerHeight = fixedContainerRef.current.offsetHeight;
            setBottomMarginContainer(`${fixedContainerHeight + 60}px`);
        }
    }, [fixedContainerRef.current]);

    useEffect(() => {
        if (isLoading) {
            setIsOpen(true);
        }
        if (isFulfilled) {
            setIsOpen(true);
            setTimeout(() => {
                dispatch(setIsFulfilled(false));
                setIsOpen(false);
                setIsDialogOpen(false);
                setTipoconValue(null);
                setEstadoconValue(null);
                setCodtipoalimValue(null);
            }, 3000);
        }
        if (isRejected) {
            setIsOpen(true);
            setTimeout(() => {
                setIsOpen(false);
            }, 3000);
        }
        if (error) {
            setIsOpen(true);
            setTimeout(() => {
                setIsOpen(false);
                setError(null);
            }, 3000);
        }
    }, [dispatch, isFulfilled, isRejected, error])

    const handleSaveClick = async () => {
        if (suportExtConnInfo?.id && estadoconValue !== null && codtipoalimValue !== null) {
            const updatedExtConn = {
                id: suportExtConnInfo.id,
                rid: suportExtConnInfo.rid,
                idSuport: suportExtConnInfo.idSuport ?? null,
                tipocon: tipoconValue ?? null,
                estadocon: estadoconValue ?? null,
                codtipoalim: codtipoalimValue ?? null,
                isInspected: "Y",
                empId: empId
            }
            if (updatedExtConn && contractType) {
                await dispatchThunk(updateSuportExtConnT2Thunk({ updatedExtConn, contractType }));

            }
        } else if( estadoconValue === null) {
            setError("Indiqueu l'estat de la connexió")
        } else if( codtipoalimValue === null) {
            setError("Indiqueu el tipus d'alimentació")
        }
    }

    const handleCancelBtnClick = () => {
        setIsDialogOpen(false);
        dispatch(setSelectedSuportExternalConnT2(null));
        setTipoconValue(null);
        setEstadoconValue(null);
        setCodtipoalimValue(null);
    }

    return (
        <Dialog open={isDialogOpen} fullScreen sx={{ height: '100vh' }}>
            <Container sx={{ marginTop: 'calc(1vw + 15px)', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', gap: 'calc(1vw + 6px)', marginBottom: bottomMarginContainer }}>
                <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, my: 2 }} noValidate autoComplete="off">
                    <Typography variant="h4" sx={{ marginY: 2 }} >Connexió aliena ({suportExtConnInfo?.id})</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Typography variant='subtitle1' fontWeight={'medium'}>RID</Typography>
                        <TextField id="suport-id" variant="outlined" value={suportExtConnInfo?.rid} contentEditable={false} sx={{ backgroundColor: 'white', marginLeft: '0 !important' }} />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Typography variant='subtitle1' fontWeight={'medium'}>Id Suport</Typography>
                        <TextField id="suport-id" variant="outlined" value={suportExtConnInfo?.idSuport} contentEditable={false} sx={{ backgroundColor: 'white', marginLeft: '0 !important' }} />
                    </Box>                    
                    <Box sx={{ my: 1 }}>
                        <Typography variant='subtitle1' sx={{ my: 1 }} fontWeight={'medium'} >Tipus de connexió</Typography>
                        <Select
                            labelId="cm-type-select-label"
                            id="demo-simple-select"
                            value={tipoconValue || ''}
                            label="cm-type"
                            onChange={handleTipoconSelection}
                            sx={{
                                width: '100%',
                                display: 'flex',
                                backgroundColor: 'white',

                            }}
                        >
                            {validValues
                                .filter(value => value.columnName === tipoconCN)
                                .map((value, index) => (
                                    <MenuItem key={index} value={value.key}>{`${value.key} - ${value.value}`}</MenuItem>
                                ))}
                        </Select>
                    </Box>
                    <Box>
                        <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Estat connexió({estadoconDescription})</Typography>
                        {validValues && (
                            <ToggleButtonGroup
                                value={estadoconValue}
                                exclusive
                                onChange={handleEstadoconSelection}
                                aria-label="status"
                                size='large'
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                }}
                            > {validValues
                                .filter(value => value.columnName === estadoconCN)
                                .map((value, index) => (
                                    <ToggleButton key={index} value={parseInt(value.key)} aria-label={value.key} css={toggleBtnsStyle}>
                                        <Typography variant="subtitle1">{value.key}</Typography>
                                    </ToggleButton>
                                ))
                                }
                            </ToggleButtonGroup>
                        )}
                    </Box>                    
                    <Box>
                        <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Tipus Alimentacio Aliena({codtipoalimDescription})</Typography>
                        {validValues && (
                            <ToggleButtonGroup
                                value={codtipoalimValue}
                                exclusive
                                onChange={handleCodtipoalimSelection}
                                aria-label="status"
                                size='large'
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                }}
                            > {validValues
                                .filter(value => value.columnName === codtipoalimCN)
                                .map((value, index) => (
                                    <ToggleButton key={index} value={parseInt(value.key)} aria-label={value.key} css={toggleBtnsStyle}>
                                        <Typography variant="subtitle1">{value.key}</Typography>
                                    </ToggleButton>
                                ))
                                }
                            </ToggleButtonGroup>
                        )}
                    </Box>
                </Box>
                {error && (
                    <ErrorBackdropComponent error={error} isOpen={isOpen} />
                )}
                {(isLoading || isFulfilled || isRejected) && (
                    <BackdropComponent isLoading={isLoading} isFulfilled={isFulfilled} isRejected={isRejected} isOpen={isOpen} />
                )}
            </Container>
            <Box
                ref={fixedContainerRef}
                sx={{
                    width: '100%',
                    height: '7vh',
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    backgroundColor: 'white',
                    zIndex: 9999,
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px -2px 15px',
                    marginTop: 'calc(2vw + 10px)',
                }}>
                <Button onClick={handleCancelBtnClick} sx={{ fontSize: '1.2rem', color: '#9e9e9e', }} >Cancel·lar</Button>
                <Button onClick={handleSaveClick} sx={{ fontSize: '1.2rem', color: 'palette.primary.main', }} >Guardar</Button>
            </Box>
        </Dialog>
    )
}