import React, { useState, useRef, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import IconButton from '@mui/material/IconButton';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { Button, Typography } from '@mui/material';
import { deleteImage } from '../../Api/Images';

export interface IPictureT2 {
  id?: number;
  entityId?: number;
  imageType?: string;
  base64: string;
  isNew?: boolean;
  deleted?: boolean;
  mapId: string;
}

interface IPicturesComponentProps {
  category: string;
  title: string;
  existingPicture: IPictureT2 | null;
  onPictureChange: (category: string, picture: IPictureT2 | null) => void;
  contractType: string | null;
}

const CameraT2Component: React.FC<IPicturesComponentProps> = ({ category, title, existingPicture, onPictureChange, contractType }) => {
  const [picture, setPicture] = useState<IPictureT2 | null>(existingPicture ?? null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setPicture(existingPicture)
  }, [existingPicture, picture])

  const handleTakePicture = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const base64Img = await convertToBase64(file);
      const newPicture: IPictureT2 = { mapId: uuid(), base64: base64Img as string, isNew: true };
      setPicture(newPicture);
      onPictureChange(category, newPicture);
    }
  };

  const convertToBase64 = (file: File) => {
    return new Promise<string>((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result as string);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleCameraClick = () => {
    if(!picture) {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    }
    
  };

  const handleDeletePicture = () => {
    if (picture && picture.id && !picture.isNew && contractType) {
      deleteImage(picture.id, contractType);      
      onPictureChange(category, null);
      setPicture(null);
    } else {
      onPictureChange(category, null);
      setPicture(null);      
    }
  };

  return (
    <>
      <Typography variant='subtitle1' sx={{ my: 0.5 }} fontWeight={'medium'}>{title}</Typography>
      <input
        accept="image/*"
        id={`icon-button-file-${category}`}
        type="file"
        capture="environment"
        onChange={handleTakePicture}
        ref={fileInputRef}
        style={{ display: 'none' }}
      />
      <Button
        sx={{ backgroundColor: 'primary.main', color: 'white', marginY: 2 }}
        onClick={handleCameraClick} 
        disabled={!!picture}       
      >
        Fer foto
        <CameraAltIcon sx={{ marginLeft: 'calc(1vw + 1px)' }} />
      </Button>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {(picture && picture !== null) && (
          <div key={picture.mapId} style={{ position: 'relative' }}>
            <img
              src={picture.base64}
              alt={`Picture ${picture.id}`}
              style={{
                width: '150px',
                height: '150px',
                objectFit: 'cover',
                margin: '5px',
              }}
            />
            <IconButton
              onClick={handleDeletePicture}
              style={{
                position: 'absolute',
                top: '5px',
                right: '5px',
                zIndex: 1,
                color: 'white',
              }}
            >
              <RemoveCircleIcon fontSize="large" sx={{ color: '#1565c0' }} />
            </IconButton>
          </div>
        )}
      </div>
    </>
  );
};

export default CameraT2Component;

