import { ReactNode } from 'react';
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Box } from "@mui/material";
import { ICircuitT1plusDetail } from "../Api/types";
import EditIcon from '@mui/icons-material/Edit';


interface ITableComponent<T> {
    dataList: T[] | null;
    columns: TableColumn<T>[];
    handleRowClick: (item: T) => void;
}

export interface TableColumn<T> {
    header: string;
    accessor?: keyof T;
    align: 'left' | 'right';
    format?: (value: any) => string;
}

export default function TableComponent<T>({ dataList, columns, handleRowClick }: ITableComponent<T>) {
    return (
        <>
            {dataList && (
                <TableContainer sx={{ marginBottom: 5, backgroundColor: 'white', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', marginY: 2 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell key={column.header} sx={{ fontWeight: 'bold' }} align={column.align}>
                                        {column.header}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataList.map((item, index) => (
                                <TableRow key={index}>
                                {columns.map((column, columnIndex) => (
                                  <TableCell key={column.header} align={column.align} onClick={() => {
                                    if (columnIndex === 0) {
                                      handleRowClick(item);
                                    }
                                  }}>
                                    {columnIndex === 0 ? (
                                      <span onClick={() => handleRowClick(item)}>
                                        <EditIcon />
                                      </span>
                                    ) : (
                                      column.accessor ? (
                                        column.format ? column.format(item[column.accessor]) as ReactNode : item[column.accessor] as ReactNode
                                      ) : null
                                    )}
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>

    )
}

{/**

<>
            {dataList && (
                <TableContainer sx={{ marginBottom: 5, backgroundColor: 'white', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', marginY: 2 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell key={column.header} sx={{ fontWeight: 'bold' }} align={column.align}>
                                        {column.header}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataList.map((item, index) => (
                                <TableRow key={index}>
                                {columns.map((column, columnIndex) => (
                                  <TableCell key={column.header} align={column.align} onClick={() => {
                                    if (columnIndex === 0) { // Assuming "Editar" is the first column
                                      // Handle the "Editar" column
                                      handleRowClick(item);
                                    }
                                  }}>
                                    {columnIndex === 0 ? (
                                      <span onClick={() => handleRowClick(item)}>
                                        <EditIcon />
                                      </span>
                                    ) : (column.format ? column.format(item[column.accessor]) : item[column.accessor])}
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>

*/}


























{/* <>
        
        {circuitList && (
            <TableContainer sx={{ marginBottom: 5, backgroundColor:'white', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', marginY: 2}}>
                <Table>
                    <TableHead>
                        <TableRow>
                        <TableCell sx={{fontWeight:'bold'}} align="left">Editar</TableCell>
                            <TableCell sx={{fontWeight:'bold'}} align="left">ID Circuit</TableCell>
                            <TableCell sx={{fontWeight:'bold'}} align="right">CM</TableCell>
                            <TableCell sx={{fontWeight:'bold'}} align="right">Nom circuit</TableCell>
                            <TableCell sx={{fontWeight:'bold'}} align="right">Circuit Actiu</TableCell>
                            <TableCell sx={{fontWeight:'bold'}} align="right">Temps resposta diferencial</TableCell>
                            <TableCell sx={{fontWeight:'bold'}} align="right">Mesura sensibilitat diferencial</TableCell>
                            <TableCell sx={{fontWeight:'bold'}} align="right">Aïllament</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {circuitList && circuitList.map((circuit) => (
                            <TableRow key={circuit.rid}>
                                <TableCell align="left" onClick={() => { handleRowClick(circuit) }}><EditIcon /></TableCell>
                                <TableCell align="left">{circuit.rid}</TableCell>
                                <TableCell align="right">{circuit.cenmando}</TableCell>
                                <TableCell align="right">{circuit.circuitName}</TableCell>
                                <TableCell align="right">{circuit.circuitoActivo ? 'Si' : 'No'}</TableCell>
                                <TableCell align="right">{circuit.respuestaDiferencial?.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                                <TableCell align="right">{circuit.medidaDiferencial?.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                                <TableCell align="right">{circuit.aislamiento?.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )}
    </> */}