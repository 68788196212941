import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import { useState } from 'react';
import {setUser} from '../Slices/userSlice';
import {setSelectedQcDistrict, setSelectedQcId} from '../Slices/mapSlice';
import Cookies from 'js-cookie';
import {useDispatch} from 'react-redux';
import AuriaSmallLogo from '../Images/AFIL_small.png';
import { topNavBarColor } from '../Styles/color-variables';
interface IProps {
  id: string;
}

export default function TopNavBar(props: IProps) {
    const [auth, setAuth] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    Cookies.remove('user');
    dispatch(setSelectedQcDistrict(""));
    dispatch(setSelectedQcId(""));
    dispatch(setUser({
      empId: null,
      username: null,
      bearerToken: null,
      isLoggedIn: false,
      bearerTokenExp: undefined,
      contracttype: null      
    }))
    
    setAnchorEl(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>      
      <AppBar id={props.id} position="fixed" sx={{top: 0, zIndex: 1, marginBottom: '50px', backgroundColor:topNavBarColor}}>
        <Toolbar sx={{display:'flex', justifyContent:'space-between'}}>
          <Box sx={{maxHeight: '50px', display:'flex', alignItems:'center'}}>
            <img className='auria-logo' src={AuriaSmallLogo} alt="logo Àuria" />
          </Box>
          {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Circuits
          </Typography> */}
          {auth && (
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}><LogoutIcon /> Sortir</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}