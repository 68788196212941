import { useEffect } from 'react';
import { Drawer, Button, Container, Box, Typography } from '@mui/material';
import { fetchSuportDetail } from '../../Api/Map';
import {ISuportCoordinates, ISuportT1PlusDetail, ISuportT2Detail} from '../../Api/types';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useDispatch, useSelector } from 'react-redux';
import { selectDetailInfo, setSuportT1PlusDetailInfo } from '../../Slices/T1Plus/suportT1PlusSlice';
import { setSuportT2DetailInfo } from '../../Slices/T2/suportT2Slice';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../store/store';
import { T1plus, T2 } from '../../variables';
import SuportT1PlusDetailComponent from './SuportT1PlusDetailComponent';
import SuportT2DetailComponent from './SuportT2DetailComponent';


interface DetailDrawerProps {
  suportDetail: ISuportCoordinates;
  open: boolean;
  onClose: () => void;
}

export default function DetailSuportDrawer({ suportDetail, open, onClose }: DetailDrawerProps) {
  const dispatch = useDispatch();
  const {suportT1PlusDetailInfo, suportT2DetailInfo, contractType} = useSelector((state: RootState) => ({
    suportT1PlusDetailInfo: state.suport.detailInfo,
    suportT2DetailInfo: state.suportT2.detailInfo,
    contractType: state.user.contracttype,
  }));
  const navigate = useNavigate(); 


  const toggleDrawer = () => {
    onClose(); 
  };

  useEffect(() => {
    if (contractType) {
      if (contractType === T2) {
        fetchSuportDetail<ISuportT2Detail>(suportDetail.id, contractType)
          .then((data) => {
            dispatch(setSuportT2DetailInfo(data));
          })
      } else if (contractType === T1plus) {
        fetchSuportDetail<ISuportT1PlusDetail>(suportDetail.id, contractType)
          .then((data) => {
            dispatch(setSuportT1PlusDetailInfo(data));
          })
      }

    }
  }, [])

  const openInGoogleMaps = () => {
    let url = `https://www.google.com/maps/dir/?api=1&destination=${suportDetail.latitude},${suportDetail.longitude}`;  
    window.open(url, '_blank');
  };

  const openSuportForm = () => {
    switch(contractType) {
      case T2: 
      navigate(`/suportT2/${suportT2DetailInfo?.rid}`);
      break;
      case T1plus: 
      navigate(`/suportT1plus/${suportT1PlusDetailInfo?.id}`);
      break;  
    }    
  }

  let detailComponent = contractType && (
    (contractType === T2 && suportT2DetailInfo) ? (
      <SuportT2DetailComponent detailInfo={suportT2DetailInfo} />
    ) : (
      (contractType === T1plus && suportT1PlusDetailInfo) ?  (
        <SuportT1PlusDetailComponent detailInfo = {suportT1PlusDetailInfo} />
      ) : <p>Sense informació</p>
    )
  )


  

  return (
    <div>     
      <Drawer anchor="bottom" 
      open={open} 
      onClose={toggleDrawer} 
      PaperProps={{
        sx: {
          borderTopRightRadius: '10px',
          borderTopLeftRadius: '10px',
        },
      }}>        
        <Container
          sx={{
            height: '40vh',
            paddingX: 2,
            paddingY: 1,                        
          }}
        >
          <Box sx={{ height: '10%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{gap: '20px'}}>
              <Button onClick={openSuportForm}><EditIcon sx={{backgroundColor:'palette.primary.light'}} /></Button>
              <Button onClick={openInGoogleMaps}><DirectionsCarIcon sx={{backgroundColor:'palette.primary.light'}} /></Button>
            </Box>
            <Button onClick={toggleDrawer}><CancelIcon /></Button>
          </Box> 
          {detailComponent}         
        </Container>
      </Drawer>
    </div>
  );
};

