import { GoogleMap, InfoWindow, Marker, MarkerClustererF } from '@react-google-maps/api';
//import { MarkerClusterer, SuperClusterAlgorithm } from '@googlemaps/markerclusterer';
import { useMemo, useState, useEffect, useCallback, useRef } from 'react';
import { fetchDistricts, fetchDistrictsAndQcId, fetchQCCoordinates, fetchSuportCoord } from '../../Api/Map';
import { IDistrictAndQcId, IQCCoordinates, ISuportCoordinates } from '../../Api/types';
import { GoogleMapsProvider } from '@ubilabs/google-maps-react-hooks';
import '../../Styles/global.css';
import DetailSuportDrawer from './DetailSuportDrawer';
import redSquareMark from '../../Images/red-square.png';
import grnSquareMark from '../../Images/grn-square.png';
import orangeSquareMark from '../../Images/orange-square.png';
import redBlankMark from '../../Images/red-blank.png';
import blueBlankMark from '../../Images/blu-blank.png';
import DetailQCDrawer from './DetailQCDrawer';
import { Box, TextField, InputAdornment, Select, MenuItem, InputLabel } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../store/store';
import { setSelectedQcDistrict, setSelectedQcId } from '../../Slices/mapSlice';
import { fetchDistrictsAndQcIdThunk, fetchDistrictsThunk } from '../../Slices/mapSlice';

type LatLngLiteral = google.maps.LatLngLiteral;
type MapOptions = google.maps.MapOptions;
export default function Map() {
    const {selectedQcDistrict, selectedQcId, districts, districtsAndQcId, contractType  } = useSelector((state: RootState) => ({
        selectedQcDistrict: state.map.selectedQcDistrict,
        selectedQcId: state.map.selectedQcId,
        districts: state.map.districts,
        districtsAndQcId: state.map.districtsAndQcId,
        contractType: state.user.contracttype,
    }));
    const mapRef = useRef<GoogleMap>();    
    const centerCoordinate = useMemo<LatLngLiteral>(() => ({lat: 41.3868454, lng: 2.1682533}), []);
    const [mapLoaded, setMapLoaded] = useState(false);
    const dispatch = useDispatch();   
    const dispatchThunk = useDispatch<AppDispatch>();

    const [userLocation, setUserLocation] = useState<LatLngLiteral>();
    const [distAndQcIds, setDistAndQcIds] = useState<IDistrictAndQcId[]>();
    const [suportLocations, setSuportLocations] = useState<ISuportCoordinates[]>();
    const [qcLocations, setQcLocations] = useState<IQCCoordinates[]>();
    const [selectedSuportLocation, setSelectedSuportLocation] = useState<ISuportCoordinates | null>(null);
    const [selectedQCLocation, setSelectedQCLocation] = useState<IQCCoordinates | null>(null);
    const [filteredQCLocations, setFilteredQCLocations] = useState<IQCCoordinates[]>();
    
    const options = useMemo<MapOptions>(() => ({
        disableDefaultUI: true,
        clickableIcons: false,
        gestureHandling: "greedy",
        styles: [{
            featureType: "poi",
            elementType: "labels",
            stylers: [{
                visibility: "off"
            }]
        }]
    }), [])

    useEffect(() => {
        if (userLocation && mapLoaded && mapRef.current) {
            mapRef.current.panTo(userLocation);
        }
    }, [userLocation, mapLoaded]);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    const { latitude, longitude } = position.coords;
                    setUserLocation({ lat: latitude, lng: longitude });
                },
                error => {
                    console.error('Error getting user location:', error);
                }
            );
        }
    }, []);


    

    const fetchSupportLocationsForQC = async (qcId: number) : Promise<ISuportCoordinates[]> => {
        try {
            if(contractType != null) {
                const supportLocations = await fetchSuportCoord(qcId, contractType);
                return supportLocations;
            }            
        } catch (error) {
            console.error("Error fetching support locations:", error);
            return [];
        }
        return []
    };
    
    useEffect(() => {
        if(selectedQcDistrict && districtsAndQcId) {
            const filteredDistrictsAndIds = districtsAndQcId
            .filter((distAndId) => distAndId.district === selectedQcDistrict)
            setDistAndQcIds(filteredDistrictsAndIds);
        }
        if (!distAndQcIds && !selectedQcDistrict) {
            setFilteredQCLocations(qcLocations);
            return;
        }    
        if(qcLocations){
            const filteredArray = qcLocations.filter(loc => 
                (selectedQcId
                    ? loc.id.toString() === selectedQcId.toString()
                    : true) && (!selectedQcDistrict || loc.district === selectedQcDistrict)
                );
            setFilteredQCLocations(filteredArray);
            if((selectedQcDistrict && selectedQcId) || selectedQcId) {
                 Promise.all(filteredArray.map(qcLoc => fetchSupportLocationsForQC(qcLoc.id)))
                 .then(values => {
                     const allSupportLocations = values.flat();
                     if (allSupportLocations !== undefined) {
                        setSuportLocations(allSupportLocations);
                     }
                 })
                 .catch(error => {
                     console.error("Error fetching support locations:", error);
                 });
            }            
        }        
    }, [qcLocations, selectedQcId, selectedQcDistrict]);

    const onLoad = useCallback((map: any) => { mapRef.current = map; setMapLoaded(true); }, [filteredQCLocations]);

    const getSuportDetail = (loc: ISuportCoordinates) => {
        setSelectedSuportLocation(loc);
    }

    const getQCDetail = (loc: IQCCoordinates) => {        
        setSelectedQCLocation(loc);
        if(contractType !== null) {
            fetchSuportCoord(loc.id, contractType)
        .then((values) => {
            setSuportLocations(values);
        })
        }       
    }

    useEffect(() => {
        if(contractType != null) {
            fetchQCCoordinates(contractType)
            .then((values) => {
                setQcLocations(values);
            })
            .catch((error) => {
                console.error(error);
              });
        }               
    }, [])   

    useEffect(() => {
        if(contractType) {
            dispatchThunk(fetchDistrictsThunk(contractType));
            dispatchThunk(fetchDistrictsAndQcIdThunk(contractType));       
        }
      }, [dispatch]);
    
    return (
        <Box sx={{position: 'relative'}}>            
            <GoogleMap zoom={21} center={userLocation || centerCoordinate} mapContainerClassName='map-container' options={options} onLoad={onLoad} >
                {filteredQCLocations && (
                    filteredQCLocations.map((loc) => {
                        let iconUrl = '';
                        if (loc.isInspected === 'N') {
                            iconUrl = redSquareMark;
                        } else if (loc.isInspected === 'Y') {
                            iconUrl = grnSquareMark;
                        } else if (loc.isInspected === 'P') {
                            iconUrl = orangeSquareMark;
                        }
                        return (                            
                            <Marker key={loc.id} position={{ lat: loc.latitude, lng: loc.longitude }} onClick={() => getQCDetail(loc)} icon={{ url: iconUrl, scaledSize: new window.google.maps.Size(35, 35) }} />
                                                
                        )
                    })                    
                )}
                {suportLocations && (
                    suportLocations.map((loc) => {
                        let iconUrl = '';
                        if (loc.isInspected === 'N') {
                            iconUrl = redBlankMark;
                        } else if (loc.isInspected === 'Y') {
                            iconUrl = blueBlankMark;
                        }
                        return (
                            <Marker key={loc.id} position={{ lat: loc.latitude, lng: loc.longitude }} onClick={() => getSuportDetail(loc)} icon={{ url: iconUrl, scaledSize: new window.google.maps.Size(35, 35) }} />
                        )
                    })                    
                )}                
                {selectedSuportLocation && (
                    <DetailSuportDrawer suportDetail={selectedSuportLocation} open={true} onClose={() => setSelectedSuportLocation(null)} />
                )}
                {selectedQCLocation && (
                    <DetailQCDrawer qcDetail={selectedQCLocation} open={true} onClose={() => setSelectedQCLocation(null)} />
                )}
            </GoogleMap>            
            <Select 
                labelId="qcId-select-label"
                id="qcId-select" 
                value={selectedQcId} 
                onChange={(e) => dispatch(setSelectedQcId(e.target.value))}
                inputProps={{                    
                    endAdornment:(
                        <InputAdornment position="end">
                            <ClearIcon aria-label='clear icon' onClick={() => dispatch(setSelectedQcId(""))} />
                        </InputAdornment>
                    )
                      }}
                sx={{
                    position:'absolute', 
                    zIndex: 1,
                    width: '30%',  
                    top: '20px', 
                    textAlign:'right', 
                    right:'10px', 
                    backgroundColor:'white',
                    '@media (min-width: 641px)': {
                        width: '15%',
                        right:'20px'
                    },
                    '@media (min-width: 1025px)': {
                        width: '20%',
                        right:'20px', 
                    }
                }}
                >
                {distAndQcIds && (
                    distAndQcIds.map((distAndId, index) => {
                        return <MenuItem key={index} value={distAndId.id}>{distAndId.id}</MenuItem>
                    })
                )}
            </Select>
            <Select 
                labelId="district-select-label"
                id="district-select" 
                value={selectedQcDistrict} 
                onChange={(e) => {
                    dispatch(setSelectedQcDistrict(e.target.value.toString()));
                    dispatch(setSelectedQcId(""));
                    setSuportLocations([]);
                }}
                sx={{
                    position:'absolute', 
                    zIndex: 1,
                    width: '20%', 
                    top: '20px', 
                    left:'10px',
                    textAlign:'right', 
                    backgroundColor:'white',
                    '@media (min-width: 641px)': {
                        width: '15%',
                        left:'20px', 
                    },
                    '@media (min-width: 1025px)': {
                        width: '20%',
                        left:'20px', 
                    }
                }}
                >
                {districts && (
                    districts.map((district, index) => {
                        return <MenuItem key={index} value={district}>{district}</MenuItem>
                    })
                )}
            </Select>
        </Box>        
    )
 }
 