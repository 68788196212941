import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ISuportExternalConnectionT2Detail } from '../../Api/types';
import { AppDispatch, RootState } from '../../store/store';
import { fetchSuportExtConnT2Detail, updateSuportExtConnT2Detail } from '../../Api/T2/SuportExternalConnT2';
import { AnyAction } from 'redux'; 
import { useDispatch } from 'react-redux';


interface ExternalConnState {
    externalConnDetailList: ISuportExternalConnectionT2Detail[] | null;
    selectedExternalConn: ISuportExternalConnectionT2Detail | null;
    isLoading: boolean;
    isFulfilled: boolean;
    isRejected: boolean;
}

const initialState : ExternalConnState = {
    externalConnDetailList: null,
    selectedExternalConn: null,
    isLoading: false,
    isFulfilled: false,
    isRejected: false
}

export const fetchSuportExtConnT2DetailThunk = createAsyncThunk(
    'externalConnT2/fetchSuportExtConnT2',
    async (extConn: { id: number, contractType: string }, thunkAPI) => {
        try {
            const data = await fetchSuportExtConnT2Detail(extConn.id, extConn.contractType);
            thunkAPI.dispatch(setExternalConnT2DetailList(data));
        } catch (error) {
            return thunkAPI.rejectWithValue('Error retrieving circuit.');
        }
    }
);

export const updateSuportExtConnT2Thunk = createAsyncThunk(
    'externalConnT2/updateExtConnT2',
    async (extConnObj: { updatedExtConn: ISuportExternalConnectionT2Detail, contractType: string }, thunkAPI) => {
        try {
            await updateSuportExtConnT2Detail(extConnObj.updatedExtConn, extConnObj.contractType);
            return extConnObj.updatedExtConn;
        } catch (error) {
            return thunkAPI.rejectWithValue('Error updating circuit.');
        }
    }
);

const suportExternalConnSlice = createSlice({
    name: 'externalConnT2',
    initialState,
    reducers: {
        setExternalConnT2DetailList: (state, action: PayloadAction<ISuportExternalConnectionT2Detail[] | null>) => {
            state.externalConnDetailList = action.payload;
        },
        setSelectedSuportExternalConnT2: (state, action: PayloadAction<number | null>) => {
            if(state.externalConnDetailList) {
                const selectedExternalConn = state.externalConnDetailList.find(conn => conn.id === action.payload);
                state.selectedExternalConn = selectedExternalConn || null;
            }
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsFulfilled: (state, action: PayloadAction<boolean>) => {
            state.isFulfilled = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateSuportExtConnT2Thunk.pending, (state) => {
                state.isLoading = true;
                state.isFulfilled = false;
                state.isRejected = false;
            })
            .addCase(updateSuportExtConnT2Thunk.fulfilled, (state, action: PayloadAction<ISuportExternalConnectionT2Detail>) => {
                const updatedExtConn = action.payload;
                const index = state.externalConnDetailList?.findIndex((conn) => conn.id === updatedExtConn.id);
                if (state.externalConnDetailList && index !== undefined && index !== -1) {
                    state.externalConnDetailList[index] = updatedExtConn;
                }
                state.isLoading = false;
                state.isFulfilled = true;
                state.isRejected = false;
            })
            .addCase(updateSuportExtConnT2Thunk.rejected, (state) => {
                state.isLoading = false;
                state.isFulfilled = false;
                state.isRejected = true;
            })
    }
})


export const { setExternalConnT2DetailList, setSelectedSuportExternalConnT2, setIsLoading, setIsFulfilled } = suportExternalConnSlice.actions;

export default suportExternalConnSlice.reducer;