import { IQcT1PlusDetail } from "../../Api/types"
import { Drawer, Button, Container, Box, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CloseIcon from '@mui/icons-material/Close';

type IProps = {
  qcDetailInfo: IQcT1PlusDetail
}

export default function QcT1PlusDetailComponent({qcDetailInfo} : IProps) {

    

    return(
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', marginX: 2, marginY: 1, '@media (min-width: 600px)': { gridTemplateColumns: '1fr 1fr', gap: '10px', } }}>
            {/* Left Column */}
            <Box>
              <Box sx={{ marginY: '10px'  }}>
                <Typography fontWeight='fontWeightMedium' variant='subtitle1'>RID</Typography>
                <Typography variant='body2'>{qcDetailInfo?.rid}</Typography>                
              </Box>
              <Box sx={{ marginY: '10px' }}>
                <Typography fontWeight='fontWeightMedium' variant='subtitle1'>Data verificació visual</Typography>
                <Typography variant='body2'>{qcDetailInfo?.fvervis? new Date(qcDetailInfo?.fvervis).toLocaleDateString('es-ES') : 'Sense data'}</Typography>
              </Box>
              <Box sx={{ marginY: '10px' }}>
              {qcDetailInfo?.isInspected === 'Y' ? (
                  <Typography fontWeight='fontWeightMedium' sx={{display: 'flex', alignItems:'flex-end'}}>
                    Revisat <CheckIcon sx={{ marginLeft: '10px', color: '#4caf50' }} />
                  </Typography>
                ) : (
                  <Typography fontWeight='fontWeightMedium' sx={{display: 'flex', alignItems:'flex-end', color: 'palette.warning.light'}}>
                    No revisat <CloseIcon sx={{color: '#d32f2f'}} />
                  </Typography>
                )}
              </Box>
              <Box sx={{ marginY: '10px' }}>
                <Typography fontWeight='fontWeightMedium' variant='subtitle1'>Codestatcua</Typography>
                <Typography variant='body2'>{qcDetailInfo?.codestatcua}</Typography>
              </Box>
              <Box sx={{ marginY: '10px' }}>
                <Typography fontWeight='fontWeightMedium' variant='subtitle1'>Codestatcierre</Typography>
                <Typography variant='body2'>{qcDetailInfo?.codestatcierre}</Typography>
              </Box>
              <Box sx={{ marginY: '10px' }}>
                <Typography fontWeight='fontWeightMedium' variant='subtitle1'>Codestattapas</Typography>
                <Typography variant='body2'>{qcDetailInfo?.codestattapas}</Typography>
              </Box>
            </Box>

            {/* Right Column */}
            <Box>              
              <Box sx={{ marginY: '10px' }}>
                <Typography fontWeight='fontWeightMedium' variant='subtitle1'>Codesttubos</Typography>
                <Typography variant='body2'>{qcDetailInfo?.codesttubos}</Typography>
              </Box>
              <Box sx={{ marginY: '10px' }}>
                <Typography fontWeight='fontWeightMedium' variant='subtitle1'>Codestext</Typography>
                <Typography variant='body2'>{qcDetailInfo?.codestext}</Typography>
              </Box>
              <Box sx={{ marginY: '10px' }}>
                <Typography fontWeight='fontWeightMedium' variant='subtitle1'>Codtomatie</Typography>
                <Typography variant='body2'>{qcDetailInfo?.codtomatie}</Typography>
              </Box>
              <Box sx={{ marginY: '10px' }}>
                <Typography fontWeight='fontWeightMedium' variant='subtitle1'>Tomatieval</Typography>
                <Typography variant='body2'>{qcDetailInfo?.tomatieval?.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
              </Box>
              <Box sx={{ marginY: '10px' }}>
                <Typography fontWeight='fontWeightMedium' variant='subtitle1'>Tensió contacte</Typography>
                <Typography variant='body2'>{qcDetailInfo?.tensioncontacto?.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
              </Box>
            </Box>
          </Box>
    )
} 