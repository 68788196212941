import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ICircuitT2Detail } from '../../Api/types';
import { AppDispatch, RootState } from '../../store/store';
import { fetchCircuitT2Detail, updateCircuitDetail, insertNewCircuit} from '../../Api/T2/CircuitT2';
import { AnyAction } from 'redux'; 
import { useDispatch } from 'react-redux';

interface CircuitState {
    circuitDetailList: ICircuitT2Detail[] | null;
    selectedCircuit: ICircuitT2Detail | null;
    isLoading: boolean;
    isFulfilled: boolean;
    isRejected: boolean;
}

const initialState: CircuitState = {
    circuitDetailList: null,
    selectedCircuit: null,
    isLoading: false,
    isFulfilled: false,
    isRejected: false,
};

export const fetchCircuitT2DetailThunk = createAsyncThunk(
    'circuitT2/fetchCircuitT2',
    async(circuitObj:{qcId: number, contractType: string}, thunkAPI) => {
      try {
        const data = await fetchCircuitT2Detail(circuitObj.qcId, circuitObj.contractType);
        thunkAPI.dispatch(setCircuitT2DetailList(data));
      } catch (error) {
        return thunkAPI.rejectWithValue('Error retrieving circuit.');
      }
    }
  );
  
  export const updateCircuitThunk = createAsyncThunk(
      'circuitT2/updateCircuitT2',
      async (circuitObj: {updatedCircuit: ICircuitT2Detail, contractType: string}, thunkAPI) => {
        try {
          await updateCircuitDetail(circuitObj.updatedCircuit, circuitObj.contractType);
          return circuitObj.updatedCircuit;
        } catch (error) {
          return thunkAPI.rejectWithValue('Error updating circuit.');
        }
      }
    );
  
    export const insertNewCircuitThunk = createAsyncThunk(
      'circuitT2/insertNewCircuitT2',
      async(circuitObj: {newCircuit: ICircuitT2Detail, contractType: string}, thunkAPI) => {
        try {
          const isSuccess = await insertNewCircuit(circuitObj.newCircuit, circuitObj.contractType);            
            thunkAPI.dispatch(addNewCircuitT2ToDetailList(circuitObj.newCircuit));                 
        } catch (error) {
          
          return thunkAPI.rejectWithValue('Error updating circuit.');
        }
      }
    )
  

    const circuitSlice = createSlice({
        name: 'circuitT2',
        initialState,
        reducers: {    
          setCircuitT2DetailList: (state, action: PayloadAction<ICircuitT2Detail[]>) => {
            state.circuitDetailList = action.payload;
          },
          setSelectedCircuitT2: (state, action: PayloadAction<number | null>) => {
            if (state.circuitDetailList) {
              const selectedCircuit = state.circuitDetailList.find(circuit => circuit.rid === action.payload);
              state.selectedCircuit = selectedCircuit || null;
            }
          },
          addNewCircuitT2ToDetailList: (state, action: PayloadAction<ICircuitT2Detail>) => {
            if(state.circuitDetailList) {
              state.circuitDetailList.push(action.payload);
            }
          },
          setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
          },
          setIsFulfilled: (state, action: PayloadAction<boolean>) => {
            state.isFulfilled = action.payload;
          }
        },
        extraReducers: (builder) => {
          builder
          .addCase(updateCircuitThunk.pending, (state) => {
              state.isLoading = true;
              state.isFulfilled = false;
              state.isRejected = false;
            })    
            .addCase(updateCircuitThunk.fulfilled, (state, action: PayloadAction<ICircuitT2Detail>) => {
              const updatedCircuit = action.payload;
              const index = state.circuitDetailList?.findIndex((circuit) => circuit.rid === updatedCircuit.rid);
              if (state.circuitDetailList && index !== undefined && index !== -1) {
                state.circuitDetailList[index] = updatedCircuit;
              }
              state.isLoading = false;
              state.isFulfilled = true;
              state.isRejected = false;
            })
            .addCase(updateCircuitThunk.rejected, (state) => {
              state.isLoading = false;
              state.isFulfilled = false;
              state.isRejected = true;
            })
            .addCase(insertNewCircuitThunk.pending, (state) => {
              state.isLoading = true;
              state.isFulfilled = false;
              state.isRejected = false;
            })
            .addCase(insertNewCircuitThunk.fulfilled, (state) => {
              state.isLoading = false;
              state.isFulfilled = true;
              state.isRejected = false;
            })
            .addCase(insertNewCircuitThunk.rejected, (state) => {
              state.isLoading = false;
              state.isFulfilled = false;
              state.isRejected = true;
            })
        },
      });
      
      export const { setCircuitT2DetailList, setSelectedCircuitT2, addNewCircuitT2ToDetailList, setIsFulfilled, setIsLoading } = circuitSlice.actions;
      
      export const selectCircuitsDetailInfo = (state: RootState) => state.circuit.circuitDetailList;
      
      export default circuitSlice.reducer;
      