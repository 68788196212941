//API BASE URL

//export const baseUrl = 'https://localhost:7159/api';
export const baseUrl = 'https://dev.auriafil.org:2030/api';


export const T1plus = 'T1plus';
export const T2 = 'T2';
export const openQcCategory = 'open';
export const closedQcCategory = 'closed';
export const suportCategory = 'suport';
export const planningQcCategory = 'planning';
export const qcExtConnCategory = 'qcExtConn';
export const additionalQcCategory = 'additional';
export const faultySuportCategory = 'faultySuport';

// GOOGLE MAPS MARKER ICONS URL  taken from: https://sites.google.com/site/gmapsdevelopment/
//check vector based icons: https://developers.google.com/maps/documentation/javascript/symbols


export const catalanLocalization = {
    // Text overrides
    footerRowSelected: (count: number) =>
      count !== 1 ? `${count.toLocaleString()} files seleccionades` : `${count.toLocaleString()} fila seleccionada`,  
    toolbarQuickFilterPlaceholder: 'Cerca...',
    columnHeaderSortIconLabel: 'Filtra',
    columnMenuSortAsc: 'Filtar ASC',
    columnMenuSortDesc: 'Filtrar DESC',
    columnMenuUnsort: 'Sense filtrar',
    footerTotalVisibleRows: (visibleCount: number, totalCount: number) =>
      `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,  
    MuiTablePagination: {
      labelRowsPerPage: 'Files per pàgina',
      labelDisplayedRows: ({ from, to, count }: { from: number; to: number; count: number }) =>
                `${from} - ${to} de ${to}`,
    }  
  };