import { baseUrl } from "../variables";
import { IUser } from "./types";

export const fetchUser = async (userinfo: {username: string, password: string, contractType: string}): Promise<IUser> => {
    try {
      const response = await fetch(baseUrl + '/Users', {
        method: 'POST',
        headers: { 'Content-Type':'application/json' },
        body: JSON.stringify(userinfo)
      } );
      if(!response.ok) {
        throw new Error("Error");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(error)
      throw error;
    }
  }