import { useEffect, useState } from "react";
import { baseUrl } from "../../variables";
import { DataGrid, GridToolbar, GridColDef } from '@mui/x-data-grid';
import { Container, FormControl, MenuItem, Select, Box, Typography } from "@mui/material";
import { ICircuitT1plusDetail, IQcT1PlusDetailList } from "../../Api/types";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { catalanLocalization } from '../../variables';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import IconButton from '@mui/material/IconButton';
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

export default function List() {
    const suportOption = "Suport";
    const qcOption = "Qc";
    const circuitOption = "CircuitsPlus";
    const [selectedType, setSelectedType] = useState<string>(circuitOption); 
    //SuportPlus , CircuitsPlus, QCPlus
    const [data, setData] = useState([]);
    const {contractType } = useSelector((state: RootState) => ({        
        contractType: state.user.contracttype
    }));    


    const fetchElementList = async (tableName: string) => {
        try {
            const response = await fetch(baseUrl + `/${contractType}/${tableName}/all`);
            if (!response.ok) throw new Error("Error");
            const data = await response.json();
            if(tableName === circuitOption || tableName === qcOption) {
                return data.map((item: ICircuitT1plusDetail | IQcT1PlusDetailList) => ({...item, id: item.rid}))
            }
            return data;
        } catch (error) {
            throw error;
        }
    }    

    useEffect(() => {
        fetchElementList(selectedType)
        .then((response) => {
            if(response) {
                setData(response);
            }            
        })
    }, [selectedType])

    const columns: { [key: string]: GridColDef[] } = {
        Suport: [
            {field: 'isInspected', headerName: 'Revisat', width: 100, renderCell: (params) => (params.value === 'Y' ? <CheckIcon sx={{ color: '#4caf50' }} /> : <CloseIcon sx={{color: '#d32f2f'}} />)},
            {field: 'directions', headerName:'Direcció', width: 100, renderCell: (params) => {
                if (params.row.latitude && params.row.longitude) {
                    return (
                        <IconButton
                            onClick={() => {                                
                                const url = `https://www.google.com/maps/dir/?api=1&destination=${params.row.latitude},${params.row.longitude}`;
                                window.open(url, '_blank');
                            }}
                        >
                            <DirectionsCarIcon />
                        </IconButton>
                    );
                }
                return null;
            }},
            {field: 'id', headerName: 'Id', width: 120, valueFormatter: (params) => {
                if (params.value === null || params.value === undefined) {
                    return '';
                }
                return params.value;
            }},
            {field: 'cm', headerName: 'CM', width: 120, valueFormatter: (params) => {
                if (params.value === null || params.value === undefined) {
                    return '';
                }
                return params.value;
            }},
            {field: 'fverT1', headerName: 'Data verificació', width: 120, sortable: false, valueFormatter: (params) => {
                if(params.value) {
                    const date = new Date(params.value);
                const day = date.getDate();
                const month = date.getMonth() + 1;
                const year = date.getFullYear();
    
                return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
                }
                return '';
            }},
            {field: 'rosmiman', headerName: 'Estat suport', width: 120, sortable: false, valueFormatter: (params) => {
                if (params.value === null || params.value === undefined) {
                    return '';
                }
                return params.value;
            }},
            {field: 'portellaStatus', headerName: 'Estat portella', width: 120, sortable: false, valueFormatter: (params) => {
                if (params.value === null || params.value === undefined) {
                    return '';
                }
                return params.value;
            }},
            {field: 'codtomatie', headerName: 'Presa terra', width: 120, sortable: false, valueFormatter: (params) => {
                if (params.value === null || params.value === undefined) {
                    return '';
                }
                return params.value;
            }},
            {field: 'estadotierra', headerName: 'Estat unió cable terra', width: 120, sortable: false, valueFormatter: (params) => {
                if (params.value === null || params.value === undefined) {
                    return '';
                }
                return params.value;
            }},
            {field: 'medidatierra', headerName: 'Mesura de terra', width: 120, sortable: false, valueFormatter: (params) => {
                if (params.value === null || params.value === undefined) {
                    return '';
                }
                
                return params.value.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 });
            }},        
            {field: 'comments', headerName: 'Observacions', width: 120, sortable: false, valueFormatter: (params) => {
                if (params.value === null || params.value === undefined) {
                    return '';
                }
                return params.value;
            }}        
        ],
        CircuitsPlus: [
            {field: 'isInspected', headerName: 'Revisat', width: 100, renderCell: (params) => (params.value === 'Y' ? <CheckIcon sx={{ color: '#4caf50' }} /> : <CloseIcon sx={{color: '#d32f2f'}} />)},
            {field: 'rid', headerName: 'Id Circuit', width: 120},
            {field: 'cm', headerName: 'CM', width: 120},
            {field: 'circuitName', headerName: 'Nom circuit', width: 120, sortable: false},
            {field: 'fvervis', headerName: 'Data verificació visual', width: 120, sortable: false, valueFormatter: (params) => {
                if(params.value) {
                    const date = new Date(params.value);
                const day = date.getDate();
                const month = date.getMonth() + 1;
                const year = date.getFullYear();
    
                return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
                }
                return '';
            }},
            {field: 'respuestaDiferencial', headerName: 'Diferencial temps resposta', width: 120, sortable: false},
            {field: 'medidaDiferencial', headerName: 'Diferencial Mesura sen', width: 120, sortable: false},
            {field: 'aislamiento', headerName: 'Aïllament', width: 120, sortable: false, valueFormatter: (params) => {
                if (params.value === null || params.value === undefined) {
                    return '';
                }
                
                return params.value.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 });
            }},
            {field: 'circuitoActivo', headerName: 'Circuit actiu', width: 120, sortable: false, renderCell: (params) => (params.value ? 'Si' : 'No')}         
        ],
        Qc: [
            {field: 'isInspected', headerName: 'Revisat', width: 100, renderCell: (params) => (params.value === 'Y' ? <CheckIcon sx={{ color: '#4caf50' }} /> : <CloseIcon sx={{color: '#d32f2f'}} />)},
            {field: 'directions', headerName:'Direcció', width: 100, renderCell: (params) => {
                if (params.row.latitude && params.row.longitude) {
                    return (
                        <IconButton
                            onClick={() => {                                
                                const url = `https://www.google.com/maps/dir/?api=1&destination=${params.row.latitude},${params.row.longitude}`;
                                window.open(url, '_blank');
                            }}
                        >
                            <DirectionsCarIcon />
                        </IconButton>
                    );
                }
                return null;
            }},
            {field: 'rid', headerName: 'RID', width: 120},
            {field: 'fvervis', headerName: 'Data verificació visual', width: 120, sortable: false, valueFormatter: (params) => {
                if(params.value) {
                    const date = new Date(params.value);
                const day = date.getDate();
                const month = date.getMonth() + 1;
                const year = date.getFullYear();
    
                return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
                }
                return '';
            }},
            {field: 'codestatcua', headerName: 'Estat quadre', width: 120, sortable: false},
            {field: 'codestatcierre', headerName: 'Estat tancament', width: 120, sortable: false},
            {field: 'codestattapas', headerName: 'Estat tapes', width: 120, sortable: false},
            {field: 'codesttubos', headerName: 'Estat tubs/muntants', width: 120, sortable: false},
            {field: 'codestext', headerName: 'Estat exterior', width: 120, sortable: false},
            {field: 'codtomatie', headerName: 'Estat instal·lació terra', width: 120, sortable: false},
            {field: 'tomatieval', headerName: 'TT CM', width: 120, sortable: false, valueFormatter: (params) => {
                if (params.value === null || params.value === undefined) {
                    return '';
                }
                
                return params.value.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 });
            }},
            {field: 'tensioncontacto', headerName: 'Tensió contacte', width: 120, sortable: false, valueFormatter: (params) => {
                if (params.value === null || params.value === undefined) {
                    return '';
                }
                
                return params.value.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 });
            }},
        ]
    };

    useEffect(() => {
        console.log(data);
    }, [data])


    return (
        <Container>
            <Box sx={{my: 3 }} >

            <FormControl fullWidth sx={{my: 2}}>
                <Typography sx={{my: 2, fontSize: 'h6.fontSize',fontWeight: 'bold',}}>Tipus</Typography>
                <Select                
                id='typeOption'
                value={selectedType}
                onChange={(e) => {setSelectedType(e.target.value)}}
                sx={{backgroundColor:'white', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',}}
                >
                    <MenuItem value={suportOption}>Suports</MenuItem>
                    <MenuItem value={qcOption}>QC</MenuItem>
                    <MenuItem value={circuitOption}>Circuits</MenuItem>
                </Select>
            </FormControl>
            {data && data.length > 0 ? (
                <DataGrid
                    columns={columns[selectedType]}
                    rows={data}
                    localeText={catalanLocalization}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 5 } }

                    }}
                    pageSizeOptions={[5, 10, 25]}
                    sx={{
                        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                        marginBottom: 5,
                        backgroundColor:'white',
                        '.css-128fb87-MuiDataGrid-toolbarContainer' : {
                            marginTop: '20px',
                            marginBottom: '20px'
                        },
                        '.css-t89xny-MuiDataGrid-columnHeaderTitle': {
                            fontWeight: 'bold'
                        }
                    }}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                            printOptions: { disableToolbarButton: true },
                            csvOptions: { disableToolbarButton: true },
                        },
                    }}
                    disableColumnMenu
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    
                />
            ) : (
                <Typography variant="body1">No data available</Typography>
            )}
            </Box>
        </Container>
    )
}

