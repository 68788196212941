import Map from "../Components/Map/MapComponent"
import {  useLoadScript } from '@react-google-maps/api';
import { CircularProgress, Container } from '@mui/material';


export default function MapPage() {
    
    const { isLoaded } = useLoadScript({googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_TOKEN as string})
    //console.log('inici '+process.env.REACT_APP_GOOGLE_MAPS_API_TOKEN )
    if(!isLoaded) return <CircularProgress />
    return(
        <Container>
           <Map />
        </Container>
    )
}