import { configureStore } from '@reduxjs/toolkit';
import suportReducer from '../Slices/T1Plus/suportT1PlusSlice';
import qcReducer from '../Slices/T1Plus/qcT1PlusSlice';
import circuitReducer from '../Slices/T1Plus/circuitT1PlusSlice';
import userReducer from '../Slices/userSlice';
import mapReducer from '../Slices/mapSlice';
import qcT2Reducer from '../Slices/T2/qcT2Slice';
import circuitT2Reducer from '../Slices/T2/circuitT2Slice';
import measurementT2Reducer from '../Slices/T2/measurementT2Slice';
import cmExternalConnT2Reducer from '../Slices/T2/cmExternalConnT2';
import suportExternalConnT2 from '../Slices/T2/suportExternalConnT2';
import suportT2Slice from '../Slices/T2/suportT2Slice';
import tramT2Slice from '../Slices/T2/tramT2Slice';

export const store = configureStore({
  reducer: {
    suport: suportReducer,
    qc: qcReducer,
    circuit: circuitReducer,
    map: mapReducer,
    user: userReducer,
    qcT2: qcT2Reducer,
    suportT2: suportT2Slice,
    circuitT2: circuitT2Reducer,
    measurementT2: measurementT2Reducer,
    cmExternalConnT2: cmExternalConnT2Reducer,
    suportExternalConnT2: suportExternalConnT2,
    tramT2: tramT2Slice,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch