import { baseUrl } from "../variables";
import { IValidValue } from "./types";


export const getValidValues = async (tableName: string): Promise<IValidValue[]> => {
    try {
        const response = await fetch(baseUrl + `/ValidValues?tableName=${tableName}`);
        if(!response.ok) {
            throw new Error('No sha pogut executar aquest procés correctament')
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

