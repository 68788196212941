import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

type IProps = {
  labelText: string;
  labelId: string;
  initialValue: number | null | string;
  onChange: (value: number) => void;
}

export default function DecimalInput({ labelText, labelId, initialValue, onChange }: IProps) {

  const [value, setValue] = useState(initialValue);
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setValue(newValue);
    const floatValue = parseFloat(newValue.replace(',', '.'));
    if (!isNaN(floatValue)) {
      onChange(floatValue);
    } else {
        setValue(0)
        onChange(0);
    }
  }

  return (
    <Box sx={{marginY: 1}}>
     <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >{labelText}</Typography>
      <input
        id={labelId}
        step="0.01"
        inputMode="decimal"
        className="input"
        value={value?.toLocaleString('es-ES') ?? ''}
        onChange={handleChange}
      />
    </Box>
  );
}