import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ITramsT2 } from '../../Api/types';
import { AppDispatch, RootState } from '../../store/store';
import { fetchTramsT2Detail, insertNewTramT2, updateTramsT2Detail } from '../../Api/T2/TramsT2';
import { AnyAction } from 'redux'; 
import { useDispatch } from 'react-redux';


interface TramState {
    detailInfo: ITramsT2 | null;
    isLoading: boolean;
    isFulfilled: boolean;
    isRejected: boolean;
}

const initialState: TramState = {
    detailInfo: null,
    isLoading: false,
    isFulfilled: false,
    isRejected: false
}

export const fetchTramT2DetailThunk = createAsyncThunk(
    'tram/fetchTramT2Detail',
    async(tramObj: {suport1Id: number, contractType: string}, thunkApi) => {
        try {
            const data = await fetchTramsT2Detail(tramObj.suport1Id, tramObj.contractType);
            thunkApi.dispatch(setTramT2DetailInfo(data ?? null));
        } catch (error) {
            throw error;
        }
    }
);

export const updateTramT2DetailThunk = createAsyncThunk(
    'tram/updateTramT2Detail',
    async(tramObj: {updatedTram: ITramsT2, contractType: string}, thunkApi ) => {
        try {
            await updateTramsT2Detail(tramObj.updatedTram, tramObj.contractType);            
        } catch (error) {
            throw error;
        }
    }
);

export const insertNewTramT2Thunk = createAsyncThunk(
    'tram/insertNewTramT2',
    async(tramObj: {newTram: ITramsT2, contractType: string}, thunkApi) => {
      try {
        const isSuccess = await insertNewTramT2(tramObj.newTram, tramObj.contractType);        
      } catch (error) {        
        return thunkApi.rejectWithValue('Error updating tram.');
      }
    }
  )


const tramT2Slice = createSlice({
    name: 'tramT2',
    initialState,
    reducers: {
        setTramT2DetailInfo: (state, action: PayloadAction<ITramsT2 | null>) => {
            state.detailInfo = action.payload;
        },
        setIsFulfilled: (state, action: PayloadAction<boolean>) => {
            state.isFulfilled = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(insertNewTramT2Thunk.pending, (state) => {
                state.isLoading = true;
                state.isFulfilled = false;
                state.isRejected = false;
            })
            .addCase(insertNewTramT2Thunk.fulfilled, (state) => {
                state.isLoading = false;
                state.isFulfilled = true;
                state.isRejected = false;
            })
            .addCase(insertNewTramT2Thunk.rejected, (state) => {
                state.isLoading = false;
                state.isFulfilled = false;
                state.isRejected = true;
            })
            .addCase(updateTramT2DetailThunk.pending, (state) => {
                state.isLoading = true;
                state.isFulfilled = false;
                state.isRejected = false;
            })
            .addCase(updateTramT2DetailThunk.fulfilled, (state) => {
                state.isLoading = false;
                state.isFulfilled = true;
                state.isRejected = false;
            })
            .addCase(updateTramT2DetailThunk.rejected, (state) => {
                state.isLoading = false;
                state.isFulfilled = false;
                state.isRejected = true;
            })
    }
})

export const { setTramT2DetailInfo, setIsLoading, setIsFulfilled} = tramT2Slice.actions;
export default tramT2Slice.reducer;