import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../../store/store';
import { ISuportT2Detail } from '../../Api/types';
import { updateSuportDetail } from '../../Api/T2/SuportT2';


interface SuportState {
    detailInfo: ISuportT2Detail | null;
    isLoading: boolean,
    isFulfilled: boolean,
    isRejected: boolean,
}

const initialState: SuportState = {
    detailInfo: null,
    isLoading: false,
    isFulfilled: false,
    isRejected: false,
};

export const updateSupportT2DetailThunk = createAsyncThunk(
    'suportT2/updateSuportDetail',
    async(suport: {updatedSuport : ISuportT2Detail, contractType: string}, thunkAPI) => {
        try {
            await updateSuportDetail(suport.updatedSuport, suport.contractType)
        } catch (error) {
            return thunkAPI.rejectWithValue('Error updating suport');
        }
    }
);

const suportT2Slice = createSlice({
    name: 'suportT2',
    initialState,
    reducers: {
        setSuportT2DetailInfo: (state, action: PayloadAction<ISuportT2Detail>) => {
            state.detailInfo = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsFulfilled: (state, action: PayloadAction<boolean>) => {
            state.isFulfilled = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateSupportT2DetailThunk.pending, (state) => {
                state.isLoading = true;
                state.isFulfilled = false;
                state.isRejected = false;
            })
            .addCase(updateSupportT2DetailThunk.fulfilled, (state) => {
                state.isLoading = false;
                state.isFulfilled = true;
                state.isRejected = false;
            })
            .addCase(updateSupportT2DetailThunk.rejected, (state, action) => {
                state.isLoading = false;
                state.isFulfilled = false;
                state.isRejected = true;
            });
    }
})


export const { setSuportT2DetailInfo, setLoading, setIsFulfilled } = suportT2Slice.actions;


export default suportT2Slice.reducer;