import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

type IProps = {
    isLoading: boolean;
    isFulfilled: boolean;
    isRejected: boolean;
    isOpen: boolean;
}

function LoadingBackdrop({ isLoading, isFulfilled, isRejected, isOpen }: IProps) {
  return (
    <Backdrop
      open={isOpen}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '300px',
          height: '200px',
          backgroundColor: 'transparent',
          borderRadius: '8px',
        }}
      >
        {isLoading ? (
          <>
            <CircularProgress />
            <Typography variant='h6'>Carregant...</Typography>
          </>
        ) : isFulfilled ? (
          <Alert severity="success">S'ha actualitzat correctament!</Alert>
        ) : isRejected ? (
          <Alert severity="error">S'ha produït un error. Torneu a intentar-ho.</Alert>
        ) : null}
      </Box>
    </Backdrop>
  );
}

export default LoadingBackdrop;
