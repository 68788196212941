import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Alert from '@mui/material/Alert';

type IProps = {
    error: string;
    isOpen: boolean;
}

function ErrorBackdropComponent({ error, isOpen }: IProps) {
  return (
    <Backdrop
      open={isOpen}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      }}
    >
      <Alert severity="error">{error}</Alert>
    </Backdrop>
  );
}

export default ErrorBackdropComponent;
