import React, { useRef } from 'react';
import IconButton from '@mui/material/IconButton';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { Button } from '@mui/material';
import { deleteTramImage } from '../../Api/T2/TramsT2';
import imageCompression from 'browser-image-compression';

export interface IPicture {
  id?: number;
  base64: string;
  isNew?: boolean;
  deleted?: boolean;
}

interface IProps {
  picture: IPicture | null;
  setPicture: React.Dispatch<React.SetStateAction<IPicture | null>>;
  tramId: number | null;
  contractType: string | null;
}

export default function TramCameraComponent(props: IProps) {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleTakePicture = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const options = {
        maxSizeMB: 1,
        //maxWidthOrHeight: 1920,
      }
      try {
        const compressedFile = await imageCompression(file, options);
        const base64Img = await convertToBase64(file);
        console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
        console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
    
        props.setPicture({ base64: base64Img as string, isNew: true });   
      } catch (error) {
        console.log(error);
      }
    }
  };

  const convertToBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleCameraClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDeletePicture = async () => {
    if (props.picture && props.tramId && props.contractType) {
        const success = await deleteTramImage(props.tramId, props.contractType);
        if(success) {
            props.setPicture(null);
        }
    } else { 
        props.setPicture(null);
    }
  };

  return (
    <>
      <input
        accept="image/*"
        id="icon-button-file"
        type="file"
        capture="environment"
        onChange={handleTakePicture}
        ref={fileInputRef}
        style={{ display: 'none' }}
      />
      <Button sx={{ backgroundColor: 'primary.main', color: 'white', marginY: 2 }} onClick={handleCameraClick} disabled={!!props.picture}>
        Fer fotografia <CameraAltIcon sx={{ marginLeft: 'calc(1vw + 1px)' }} />
      </Button>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {props.picture && (
                  <div style={{ position: 'relative' }}>
                      <img
                          src={props.picture.base64}
                          alt={`Single Picture`}
                          style={{ width: '150px', height: '150px', objectFit: 'cover', margin: '5px' }}
                      />
                      <IconButton
                          onClick={handleDeletePicture}
                          style={{ position: 'absolute', top: '5px', right: '5px', zIndex: 1, color: 'white' }}
                      >
                          <RemoveCircleIcon fontSize="large" sx={{ color: '#1565c0' }} />
                      </IconButton>
                  </div>
              )}
          </div>
    </>
  );
}
