import { IQcT2Detail } from '../../Api/types';
import { updateQcDetail } from '../../Api/T2/QcT2';
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../../store/store';

interface QcState {
    detailInfo: IQcT2Detail | null;
    isSubmitLoading: boolean;
    isSubmitFulfilled: boolean;
    isSubmitRejected: boolean;
    isSavedLoading: boolean;
    isSavedFulfilled: boolean;
    isSavedRejected: boolean;
}

const initialState : QcState = {
    detailInfo: null,
    isSubmitLoading: false,
    isSubmitFulfilled: false,
    isSubmitRejected: false,
    isSavedLoading: false,
    isSavedFulfilled: false,
    isSavedRejected: false,
}

export const updateQcT2DetailThunk = createAsyncThunk(
    'qc/updateQcT2Detail',
    async(obj: {updatedQc : IQcT2Detail, contractType: string}, thunkApi) => {
        try {
            await updateQcDetail(obj.updatedQc, obj.contractType);
        } catch (error) {
            throw error;
        }
    }
);

export const saveQcT2DetailThunk = createAsyncThunk(
    'qc/saveQcT2Detail',
    async(obj: {savedQc : IQcT2Detail, contractType: string}, thunkApi) => {
        try {
            await updateQcDetail(obj.savedQc, obj.contractType);
        } catch (error) {
            throw error;
        }
    }
)

const qcT2Slice = createSlice({
    name: 'qcT2',
    initialState,
    reducers: {
        setQcT2DetailInfo: (state, action: PayloadAction<IQcT2Detail>) => {
            state.detailInfo = action.payload;
        },
        setIsSubmitFulfilled: (state, action: PayloadAction<boolean>) => {
            state.isSubmitFulfilled = action.payload;
        },
        setIsSubmitLoading: (state, action: PayloadAction<boolean>) => {
            state.isSubmitLoading = action.payload;
        },
        setIsSavedFulfilled: (state, action: PayloadAction<boolean>) => {
            state.isSavedFulfilled = action.payload;
        },
        setIsSavedLoading: (state, action: PayloadAction<boolean>) => {
            state.isSavedLoading = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateQcT2DetailThunk.pending, (state) => {
                state.isSubmitLoading = true;
                state.isSubmitFulfilled = false;
                state.isSubmitRejected = false;
            })
            .addCase(updateQcT2DetailThunk.fulfilled, (state) => {
                state.isSubmitLoading = false;
                state.isSubmitFulfilled = true;
                state.isSubmitRejected = false;
            })
            .addCase(updateQcT2DetailThunk.rejected, (state) => {
                state.isSubmitLoading = false;
                state.isSubmitFulfilled = false;
                state.isSubmitRejected = true;
            })   
            .addCase(saveQcT2DetailThunk.pending, (state) => {
                state.isSavedLoading = true;
                state.isSavedFulfilled = false;
                state.isSavedRejected = false;
            })
            .addCase(saveQcT2DetailThunk.fulfilled, (state) => {
                state.isSavedLoading = false;
                state.isSavedFulfilled = true;
                state.isSavedRejected = false;
            })
            .addCase(saveQcT2DetailThunk.rejected, (state) => {
                state.isSavedLoading = false;
                state.isSavedFulfilled = false;
                state.isSavedRejected = true;
            })
        
    }
})

export const { setQcT2DetailInfo, setIsSubmitLoading, setIsSubmitFulfilled, setIsSavedFulfilled, setIsSavedLoading } = qcT2Slice.actions;

  export const qcDetailInfo = (state: RootState) => state.qcT2.detailInfo;

  export default qcT2Slice.reducer;